import axios from "axios";

export default {
  state: {},
  mutations: {},
  actions: {
    GetCall({rootState, dispatch}, data_obj) {
      let param_str;
      if (data_obj.param === undefined) {
        param_str = ''
      } else {
        param_str = data_obj.param;
      }
      let path = rootState.Main.api_server + data_obj.endpoint + param_str;

      let l_storage = window.localStorage;
      let token = l_storage.getItem('token');

      console.log('Get', path);

      axios
        .get(path, {headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => {
          dispatch('CallbackWorker', {response: response, data_obj: data_obj});
        })
        .catch((error) => {
          console.log('error', error);
          dispatch('ErrorWorker', {error: error, data_obj: data_obj});
        });
    },
    PostCall({rootState, dispatch}, data_obj) {
      if (data_obj.param === undefined) {
        data_obj.param = {}
      }

      let path = rootState.Main.api_server + data_obj.endpoint;

      let l_storage = window.localStorage;
      let token = l_storage.getItem('token');

      console.log('Post', path);

      axios
        .post(path, data_obj.param, {headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => {
          dispatch('CallbackWorker', {response: response, data_obj: data_obj});
        })
        .catch((error) => {
          console.log('error', error);
          dispatch('ErrorWorker', {error: error, data_obj: data_obj});
        });
    },
    PutCall({rootState, dispatch}, data_obj) {
      if (data_obj.param === undefined) {
        data_obj.param = {}
      }

      let path = rootState.Main.api_server + data_obj.endpoint;

      let l_storage = window.localStorage;
      let token = l_storage.getItem('token');

      console.log('Put', path);

      axios
        .put(path, data_obj.param,
          {
            headers: {'Authorization': `Bearer ${token}`},
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        )
        .then((response) => {
          dispatch('CallbackWorker', {response: response, data_obj: data_obj});
        })
        .catch((error) => {
          console.log('error', error);
          dispatch('ErrorWorker', {error: error, data_obj: data_obj});
        });
    },
    DeleteCall({rootState, dispatch}, data_obj) {
      let param_str;
      if (data_obj.param === undefined) {
        param_str = '';
      } else {
        param_str = data_obj.param;
      }
      let path = rootState.Main.api_server + data_obj.endpoint + param_str;

      let l_storage = window.localStorage;
      let token = l_storage.getItem('token');

      console.log('Delete', path);

      axios
        .delete(path, {headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => {
          dispatch('CallbackWorker', {response: response, data_obj: data_obj});
        })
        .catch((error) => {
          console.log('error', error);
          dispatch('ErrorWorker', {error: error, data_obj: data_obj});
        });
    },
    CallbackWorker({dispatch}, data) {
      console.log(data.response.data);

      switch (typeof data.data_obj.callback) {
        case "string":
          dispatch(data.data_obj.callback, data.response.data, {root: true});
          break;
        case "function":
          data.data_obj.callback(data.response.data);
          break;
      }
    },
    ErrorWorker({dispatch}, data) {
      console.log(data.error);
      if (data.error) {
        console.log('error code: ', data.error.status);

        if (data.error.status === 401) {
          dispatch('DeleteCredentials', {root: true});
        } else {
          switch (typeof data.data_obj.on_error) {
            case "string":
              dispatch(data.data_obj.on_error, data.error, {root: true})
              break;
            case "function":
              data.data_obj.on_error(data.error);
              break;
            default:
              break;
          }
        }
      } else {
        console.log(data.error);
      }
    }
  },
  getters: {}
}