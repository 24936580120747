<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''"
      data-popup="upload-price">
    <div class="sliding-door-header">
      <span>Экспорт склада</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <p class="center">Выберите формат экспортируемого файл</p>
        <ul class="upload-file-type">
          <li>
<!--            <input id="excel_input" type="file" accept=".xls,.xlsx" @change="FileAddedXls">-->
            <label>Excel</label>
          </li>
          <li>
<!--            <input id="csv_input" type="file" accept=".csv" @change="FileAddedCsv">-->
            <label>CSV</label>
          </li>
        </ul>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SPExportPrice",
  data: function () {
    return {
      is_open: false,
      xls: null,
      csv: null,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus','SetPopupStatus','SetPopupName', 'SetLoginStatusTriggerEnd']),
    ...mapActions(['PostCall']),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },
    FileAddedXls(){

    },
    FileAddedCsv(){

    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>