<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''">
    <div class="sliding-door-header">
      <span>Подключить склад</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <p class="center">{{ uuid }}</p>
        <form class="form">
          <div class="section">
            <p class="center">Подключите склад для того, чтобы. Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает
              сосредоточиться.</p>
            <ul class="list">
              <template v-for="(value, index) in form" v-bind:key="index">
                <li>
                  <input type="checkbox" class="checkbox" :id="value.uuid" v-model="value.shop">
                  <label :for="value.uuid">{{ value.title }}</label>
                </li>
              </template>
            </ul>
            <div class="button-center">
              <button type="submit" class="button button-middle" @click="Send">Сохранить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SPShopAddStorehouse",
  data: function () {
    return {
      is_open: false,
      uuid: '',
      form: [],
      errored: false,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      warehouse_uuid: state => state.Popups.warehouse_uuid,
    }),
  },
  mounted() {
    this.GetStorehousesData();
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus', 'SetSlidePopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd', 'SetReloadFlag']),
    ...mapActions(['PutCall', 'GetCall']),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },

    GetStorehousesData() {
      this.GetCall({
        endpoint: '/api/shops/' + this.$route.query.uuid + '/storehouses',
        callback: (data) => {
          this.form = Array.from(data.data);
        },
      })
    },

    Send(e) {
      e.preventDefault();

      let send_form = { storehouses: [] };
      this.form.forEach(e => {
        send_form.storehouses.push({ uuid: e.uuid, shop: e.shop });
      })

      this.PutCall({
        endpoint: '/api/shops/' + this.$route.query.uuid + '/storehouses',
        param: send_form,
        callback: (data) => {
          this.SetReloadFlag(true);
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if (error_status === 422) {
            let errors = Object.keys(error.response.data.errors);
            console.log('errors', errors);
            for (let i = 0; i < errors.length; i++) {
              if (this.errored[errors[i]] !== undefined) {
                this.errored[errors[i]] = true;
              }
            }
          }
        },
      })
    }
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>