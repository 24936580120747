<template>
  <tbody :class="open ? 'is-opened':''">
  <tr>
    <td>
      <div class="orders-table-number orders-table-sell">
        <a :href="'/delivery-buy?uuid='+detail.uuid+'&type='+type">{{ value.number }}</a>
        <span>Продажа</span>
      </div>
    </td>
    <td align="center">05.09.2020<br>в 09:52</td>
    <td align="center" class="orders-table-contact">{{ value.buyer.name }}<span>{{ value.buyer.phone }}</span></td>
    <td align="center">{{ value.total }} UAH</td>
    <td align="center">
      <div class="orders-table-status orders-table-in-progress"
           v-if="value.status === 'cart'"
      >Корзина</div>
      <div class="orders-table-status orders-table-in-progress"
           v-if="value.status === 'buyer_accept' || value.status === 'seller_accept'"
      >Обработка</div>
      <div class="orders-table-status orders-table-in-progress"
           v-if="value.status === 'sended'"
      >Выполняется</div>
      <div class="orders-table-status orders-table-completed"
           v-if="value.status === 'received'"
      >Завершен</div>
      <div class="orders-table-status orders-table-rejected"
           v-if="value.status === 'rejected'"
      >Отменен</div>
    </td>
    <td align="right">
      <button
          type="button"
          class="table-button table-button-open table-group-open-button"
          @click="ToggleOpen"
      ></button>
    </td>
  </tr>
  <tr class="table-hidden-row table-no-padding">
    <td colspan="6">
      <div class="order-info">
        <div class="order-info-col">
          <div class="order-info-block">
            <div class="order-info-title">Инфо о заказе</div>
            <ul>
              <li v-if="detail.info.ttn">ТТН {{ detail.info.ttn }}</li>
              <li v-if="detail.info.address">{{ detail.info.address }}</li>
              <li v-if="detail.info.pay_method">{{ detail.info.pay_method }}</li>
              <li v-if="detail.info.buyer">{{ detail.info.buyer }}</li>
              <li v-if="detail.info.phone">{{ detail.info.phone }}</li>
              <li v-if="detail.info.email">{{ detail.info.email }}</li>
            </ul>
          </div>
          <div class="order-info-block">
            <div class="order-info-title">Комментаий к заказу</div>
            <p v-if="detail.comments.length>0">{{ detail.comments[0].text }}</p>
            <a :href="'/delivery-sell?uuid='+detail.uuid+'&type='+type" class="button button-block button-transparent">Полная информация</a>
          </div>
        </div>
        <div class="order-info-col">
          <div class="order-info-title">Товары</div>
          <div class="order-info-table-wrapper">
            <table class="order-info-table">
              <template v-if="type==='sell'" v-for="(value2, index2) in detail.products" v-bind:key="index2">
                <tbody>
                <tr>
                  <td rowspan="2" width="104">
                    <div class="order-info-table-image">
                      <img src="pic/order-info-table-1.png" srcset="pic/order-info-table-1.png 1x, pic/order-info-table-1@2x.png 2x" alt="">
                    </div>
                  </td>
                  <td colspan="3">
                    <strong>{{ value2.code }} {{ value2.description }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><span>Кол-во</span>{{ value2.num }}</td>
                  <td><span>Цена</span>{{ value2.price }} UAH</td>
                  <td><span>Сумма</span>{{ value2.sum }} UAH</td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                  <td colspan="4">
                    <div class="order-info-table-divider"></div>
                  </td>
                </tr>
                </tbody>
              </template>
            </table>
          </div>
          <div class="order-info-total">
            <div class="order-info-total-box">Итоговая сумма<span>{{ detail.total }} UAH</span></div>
          </div>
        </div>
        <div class="order-info-col">
          <div class="order-info-block">
            <div class="order-info-title">История</div>
            <ul class="order-info-history">
              <li>
                <strong>Оформление заказа</strong>
                <span>05.09.2020 в 09:52</span>
              </li>
              <li>
                <strong>Предоплата</strong>
                <span>1000 UAH</span>
              </li>
              <li>
                <strong>Комплектация</strong>
                <span>06.09.2020</span>
              </li>
              <li>
                <strong>Рассчетная дата получения</strong>
                <span>~ 18.09.20 - 19.09.20</span>
              </li>
              <li class="on-hold">
                <strong>Получение</strong>
                <span>Ожидается...</span>
              </li>
              <li class="on-hold">
                <strong>Оплата</strong>
                <span>Ожидается...</span>
              </li>
            </ul>
          </div>
          <div class="order-info-block">
            <div class="toolbox">
              <button
                  type="button" class="toolbox-remove"
                  @click="DeclineOrder"
                  v-if="value.status !== 'rejected' && value.status !== 'sended' && value.status !== 'received'"
              >Отменить заказ</button>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "OrderSellLine",
  props: {
    value: Object,
    type: String,
  },
  data: function () {
    return {
      open: false,
      detail:{
        uuid:'',
        num:'', //номер заказа
        status:'cart', // 'cart'/'buyer_accept'/'seller_accept'/'sended'/'received'/'rejected'
        total: 100500, //сумма заказа
        info:{ //выбранные параметры доставки
          buyer: 'Панькив Олег Олегович',
          ttn: 'ТТН 20400119649654',
          address: 'Новая Почта, Винница, Отделение №13 (до 30 кг): ул. Гонты, 19, пом. 3',
          pay_method: 'Оплата при получении',
          phone: '380638469602',
          email: 'andrey.s.pikul@yandex.ru',
          vin: '1244235dfghdhg5',
          vin_check: true,
        },
        products:[
          {
            uuid: '...',
            pic: '123.jpg',
            manufacturer: 'VAG',
            code: '3c0807837a',
            num: 5,
            description: 'колодки тормозные',
            post_time: 'сегодня', //срок поставки,
            post_city: 'г. Харьков',
            price: '1050', //цена
            sum: '100500', //стоимость с учетом количества
          },
        ],
        seller:{
          name: 'ООО Продаван',
          uuid: '...',
          phone: '380936666666',
          rate: '4.5',
          fav: false,
          reviews_num: 23,
          orders_num: 100500,
        },
        buyer:{
          name: 'Вася Вася Вася',
          uuid: '...',
          address: 'Новая Почта, Винница, Отделение №13 (до 30 кг): ул. Гонты, 19, пом. 3',
          pay_method: 'Оплата при получении',
          phone: '380638469602',
          email: 'andrey.s.pikul@yandex.ru',
        },
        comments:[
          {
            author:'seller',
            date: '12323',// дата коммента
            text: 'sdffsdfg  gsgs'
          },
        ]
      }
    }
  },
  computed: {
    ...mapState({
      //order_type: state => state.Order.order_type,
    }),
  },
  methods: {
    ...mapMutations(['SetReloadFlag']),
    ...mapActions(['GetCall','PutCall']),
    ToggleOpen() {
      if (!this.open) {
        this.GetOrderDetail();
      } else {
        this.open = !this.open;
      }
    },
    GetOrderDetail() {
      this.GetCall({
        endpoint: '/api/sales/' + this.value.uuid,
        callback: (data) => {
          this.detail = data.data;
          this.open = !this.open;
        },
      })
    },
    DeclineOrder(){
      this.PutCall({
        endpoint: '/api/sales/' + this.value.uuid,
        param: {
          status:'rejected', // 'seller_accept'/'sended'/'rejected'
          // ttn: '1245dfdf', // ттн
          // response: 'dsafsfdf sfd' //отзыв на заказ, только после received/rejected
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
  }
}
</script>

<style scoped>

</style>