<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''"
      data-popup="add-rule"
  >
    <div class="sliding-door-header">
      <span>Добавление правила</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-main-bg"></div>
      <div class="sliding-door-content">
        <p class="center">Правило подключатется для того чтобы и короткий текст об этом. Давно выяснено, что при оценке дизайна и
          композиции</p>
        <form class="form" autocomplete="off">
          <div class="block-small">
            <fieldset>
              <div class="form-field"
                   :class="[form.manufacturer_uuid.length > 0 ? 'is-filled':'', errored.manufacturer_uuid ? 'errored':'']"
                   @focusin="ManufacturerFocus(true)"
                   @focusout="ManufacturerFocus(false)"
              >
                <input
                    class="select"
                    id="add-rule-manufacturer"
                    v-model="manufacturer"
                >
                <label>Производитель</label>
                <div class="dd_holder" v-if="manufacturer_dd_open">
                  <!--                <div class="dd_holder">-->
                  <template v-for="(value, index) in manufacturer_list_filtered" v-bind:key="index">
                    <div class="dd_list_elem" @click="ManufacturerSelected(value)">{{ value.name }}</div>
                  </template>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-field"
                   :class="[form.category_uuid.length > 0 ? 'is-filled':'', errored.category_uuid ? 'errored':'']">
                <input class="select" id="add-rule-category" v-model="form.category_uuid">
                <label>Категория</label>
              </div>
            </fieldset>
          </div>
          <div class="block-small">
            <fieldset>
              <label class="form-title">Цена, EUR</label>
              <div class="range">
                <div class="range-values">
                  <div class="range-values-input">
                    <span>от</span>
                    <input type="text" class="textfield numbers-only range-min" v-model="price_min">
                  </div>
                  <div class="range-values-input">
                    <span>до</span>
                    <input type="text" class="textfield numbers-only range-max" v-model="price_max">
                  </div>
                </div>
                <!--                <div class="range-box" data-min="500" data-max="100000" data-start-min="500" data-start-max="72356"></div>-->
                <Slider
                    class="rules-range"
                    v-model="price"
                    :min="price_range_min"
                    :max="price_range_max"
                    :tooltips="false"
                ></Slider>
                <div class="range-minmax"><span>{{ price_range_min }}</span><span>{{ price_range_max }}</span></div>
              </div>
            </fieldset>
          </div>
          <div class="block-small">
            <div class="form-row">
              <fieldset>
                <div class="form-field is-filled"
                     :class="[errored.charge ? 'errored':'']">
                  <input type="number" min="0" class="textfield" v-model="form.charge">
                  <label>Наценка, %</label>
                </div>
              </fieldset>
              <fieldset>
                <div class="form-field is-filled"
                     :class="[errored.pro_discount ? 'errored':'']">
                  <input type="number" min="0" max="99" class="textfield" v-model="form.pro_discount">
                  <label>Скидка PRO, %</label>
                </div>
              </fieldset>
            </div>
            <div class="form-bottom">
              <div class="form-bottom-box">
                <ul>
                  <li>
                    <input type="checkbox" class="checkbox" id="add-rule-ch-1" v-model="form.enabled">
                    <label for="add-rule-ch-1">Применить фильтр в выдаче</label>
                  </li>

                  <!--                  <li>
                                      <input type="checkbox" class="checkbox" id="add-rule-ch-2" checked>
                                      <label for="add-rule-ch-2">Показывать в выдаче</label>
                                    </li>
                                    -->
                </ul>
              </div>
              <div class="form-bottom-box">
                <button type="submit" class="button" @click="Send">Сохранить</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Slider from '@vueform/slider'

export default {
  name: "SPEditRule",
  components: {
    Slider
  },
  data: function () {
    return {
      manufacturer_list:[],
      manufacturer_list_filtered:[],
      manufacturer: '',
      manufacturer_dd_open: false,

      price_range_min: 500,
      price_range_max: 100000,
      price_min: 510,
      price_max: 90000,
      price: [510, 90000],

      is_open: false,
      form: {
        manufacturer_uuid: "",
        position:1,
        category_uuid: "",
        price_low: 510,
        price_high: 90000,
        charge: 0,
        pro_discount: 0,
        enabled: false,
      },
      errored: {
        manufacturer_uuid: false,
        category_uuid: false,
        price_low: false,
        price_high: false,
        charge: false,
        pro_discount: false,
        enabled: false,
      }
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      warehouse_uuid: state => state.Popups.warehouse_uuid,
      edited_rule_info: state => state.Products.edited_rule_info,
    }),
  },
  mounted() {
    this.GetManufacturerList();
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus', 'SetPopupStatus', 'SetPopupName', 'SetLoginStatusTriggerEnd','RefreshRulesSet']),
    ...mapActions(['PutCall','GetCall']),
    hide() {
      this.is_open = false;
      this.ManufacturerFocus(false);
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },

    Send(e) {
      e.preventDefault();

      this.PutCall({
        endpoint: '/api/storehouse/'+this.warehouse_uuid+'/rules/'+this.edited_rule_info.uuid,
        param: this.form,
        callback: (data) => {
          this.RefreshRulesSet(true);
          this.ManufacturerFocus(false);
          this.hide();
          // console.log(data);
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if (error_status === 422) {
            let errors = Object.keys(error.response.data.errors);
            console.log('errors', errors);
            for (let i = 0; i < errors.length; i++) {
              if (this.errored[errors[i]] !== undefined) {
                this.errored[errors[i]] = true;
              }
            }
          }
        },
      })
    },

    SetValuses(){
      /*{
          uuid: ' ',
          position: 1,
          category: ' ',
          manufacturer: ' ',
          price_low: "...",
          price_high: "...",
          charge: ' ',
          pro_discount: ' ',
          enabled: true,
      },*/
      this.form.position = this.edited_rule_info.position;
      this.form.category = this.edited_rule_info.category;
      this.form.manufacturer_uuid = this.edited_rule_info.manufacturer.uuid;
      this.manufacturer = this.edited_rule_info.manufacturer.name;
      this.form.price_low = this.edited_rule_info.price_low;
      this.form.price_high = this.edited_rule_info.price_high;
      this.price_min = this.edited_rule_info.price_low;
      this.price_max = this.edited_rule_info.price_high;
      this.price[0] = this.edited_rule_info.price_low;
      this.price[1] = this.edited_rule_info.price_high;
      this.form.charge = this.edited_rule_info.charge;
      this.form.pro_discount = this.edited_rule_info.pro_discount;
      this.form.enabled = this.edited_rule_info.enabled;
    },

    GetManufacturerList(){
      this.GetCall({
        endpoint: '/api/product-manufacturers',
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          //console.log(data);
          this.manufacturer_list = Array.from(data.data);
          this.manufacturer_list_filtered = Array.from(data.data);
        },
      })
    },
    ManufacturerSelected(value){
      console.log(value);
      this.manufacturer = value.name;
      this.form.manufacturer_uuid = value.uuid;
      // this.manufacturer_dd_open = false;
    },
    ManufacturerFocus(focus){
      if(focus) {
        // console.log(focus);
        this.manufacturer_dd_open = focus;
      }else{
        setTimeout(()=>{this.manufacturer_dd_open = focus},100);
      }
    }
  },
  watch: {
    manufacturer(newVal){
      if(newVal != '') {
        this.manufacturer_list_filtered = this.manufacturer_list.filter(elem => {
          return elem.name.toLowerCase().includes(newVal.toLowerCase());
        });
      }else{
        this.form.manufacturer_uuid = '';
        this.manufacturer_list_filtered = Array.from(this.manufacturer_list);
      }
    },
    price: {
      handler(newVal) {
        console.log(newVal);
        this.price_min = newVal[0];
        this.price_max = newVal[1];
      },
      deep: true
    },
    price_min(newVal) {
      this.price[0] = newVal;
      this.form.price_low = newVal;
    },
    price_max(newVal) {
      this.price[0] = newVal;
      this.form.price_high = newVal;
    },
    form:{
      handler(newVal){
        if(newVal.charge<0){
          this.form.charge = 0;
        }else{
          this.form.charge = parseFloat(newVal.charge);
        }
        if(newVal.pro_discount<0){
          this.form.pro_discount = 0;
        }
        if(newVal.pro_discount>99){
          this.form.pro_discount = 99;
        }
        if(newVal.pro_discount>0 && newVal.pro_discount<99){
          this.form.pro_discount = parseFloat(newVal.pro_discount);
        }
      },
      deep:true
    },

    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.rules-range {
  margin                        : 25px 0 15px;

  --slider-bg                   : #D1D5DB;
  --slider-connect-bg           : #FEC400;
  --slider-connect-bg-disabled  : #9CA3AF;
  --slider-height               : 6px;
  --slider-vertical-height      : 300px;
  --slider-radius               : 9999px;

  --slider-handle-bg            : #FEC400;
  --slider-handle-border        : 0;
  --slider-handle-width         : 20px;
  --slider-handle-height        : 20px;
  --slider-handle-radius        : 9999px;
  --slider-handle-shadow        : 0.5px 0.5px 2px 1px rgba(0, 0, 0, .32);
  --slider-handle-shadow-active : 0.5px 0.5px 2px 1px rgba(0, 0, 0, .42);
  --slider-handle-ring-width    : 3px;
  --slider-handle-ring-color    : #FEC40030;

  --slider-tooltip-bg           : #10B981;
  --slider-tooltip-color        : #FFFFFF;
  --slider-tooltip-radius       : 5px;
  --slider-tooltip-min-width    : 20px;
  --slider-tooltip-font-size    : 0.875rem;
  --slider-tooltip-line-height  : 1.25rem;
  --slider-tooltip-font-weight  : 600;
  --slider-tooltip-py           : 2px;
  --slider-tooltip-px           : 6px;
  --slider-tooltip-arrow-size   : 5px;
  --slider-tooltip-distance     : 3px;
}
</style>