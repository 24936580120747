<template>
  <section class="content">
    <div class="breadcrumbs"><a href="/" class="breadcrumbs-home"></a><i></i><a href="#">Корзина</a></div>
    <section class="section">
      <div class="page-header">
        <div class="page-header-box">
          <h1>Корзина – 8</h1>
        </div>
        <div class="page-header-box">
          <a class="cart-reset" @click="ClearCart">Очистить<span> корзину</span></a>
        </div>
      </div>
      <template v-for="(value, index) in orders" v-bind:key="index">
        <CartOrder :order="value"></CartOrder>
      </template>
    </section>
    <section class="section">
      <div class="insurance">
        <div class="insurance-text">
          <h2>Страховой полис</h2>
          <p>Avtoprice, как официальный партнер, предлагает лучший выбор среди ведущих страховых компаний Украины.</p>
        </div>
        <a href="#" class="button button-middle button-transparent">Узнать больше</a>
      </div>
    </section>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CartOrder from "@/components/common/Cart/CartOrder";

export default {
  name: "Cart",
  components:{
    CartOrder,
  },
  computed: {
    ...mapState({
      reload_flag: state => state.Order.cart_reload_flag,
    }),
  },
  data: function () {
    return {
      orders: [
        // {
        //   uuid: '...', //uuid заказа
        //   total: '100500', //общая стоимость заказа
        //   seller: {
        //     name: 'ООО Продаван',
        //     uuid: '...',
        //     rate: '4.5',
        //     fav: false,
        //     reviews_num: 23,
        //     orders_num: 100500,
        //   },
        //   products: [
        //     {
        //       uuid: '...',
        //       pic: '123.jpg',
        //       manufacturer: 'VAG',
        //       code: '3c0807837a',
        //       num: 5,
        //       description: 'колодки тормозные',
        //       post_time: 'сегодня', //срок поставки,
        //       post_city: 'г. Харьков',
        //       sum: '100500', //стоимость с учетом количества
        //     }
        //   ]
        // },
      ],
    }
  },
  mounted() {
    this.GetCartData();
  },
  methods: {
    ...mapMutations(['SetSlidePopupName', 'SetSlidePopupStatus', 'SetPopupName',
                     'SetPopupStatus', 'SetWarehouseUUID', 'SetWarehouseName',
                     'SetOrderUuid','SetCartReloadFlag']),
    ...mapActions(['GetCall',]),
    GetCartData() {
      this.GetCall({
        endpoint: '/api/cart',
        callback: (data) => {
          this.orders = Array.from(data.data);
        },
      })
    },
    ClearCart(){

    },
  },
  watch:{
    reload_flag(){
      this.SetCartReloadFlag(false);
      //window.location.reload(true);
      this.GetCartData();
    },
  },
}
</script>

<style scoped>

</style>