<template>
  <div class="sliding-door"
       :class="is_open ? 'is-opened' : ''">
    <div class="sliding-door-header">
      <span>Курс валют</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <p class="center">ID склада: 166881</p>
        <h3 class="center">Выберите источник курса валют или установите свой</h3>
        <table class="table">
          <tr>
            <th>Показатель</th>
            <th>Курс 1 Eur</th>
          </tr>
          <tr class="table-divider">
            <td colspan="2"></td>
          </tr>
          <tr>
            <td class="table-currency">
              <input type="radio" class="radio" name="set-currency" id="set-currency-1" checked>
              <label for="set-currency-1">Коммерческий курс</label>
              <span>
							<a href="#">Применить курс ко всем складам</a>
						</span>
            </td>
            <td>33.45 UAH</td>
          </tr>
          <tr class="table-divider">
            <td colspan="2"></td>
          </tr>
          <tr>
            <td class="table-currency">
              <input type="radio" class="radio" name="set-currency" id="set-currency-2">
              <label for="set-currency-2">Собственный курс</label>
            </td>
            <td>
              <div class="form">
                <div class="form-field">
                  <input type="text" class="textfield" value="30">
                  <label>UAH</label>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <div class="button-center">
          <button type="submit" class="button button-middle">Сохранить</button>
        </div>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SPSetCurrency",
  data: function () {
    return {
      is_open: false,
      form: {
        code: '',
        manufacturer: '',
        description: '',
        price: 0,
        count: 0,
        file: null,
        used: false,
      },
      errored: {
        code: false,
        manufacturer: false,
        description: false,
        price: false,
        count: false,
        file: false,
        used: false,
      }
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus','SetPopupStatus','SetPopupName', 'SetLoginStatusTriggerEnd']),
    ...mapActions(['PostCall']),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },
    FileAdded(){

    },
    Send(e) {
      e.preventDefault();

      this.PostCall({
        endpoint: '/',
        param: this.form,
        callback: (data) => {
          this.SetSlidePopupStatus(false);
          this.SetPopupName('confirm_mail');
          this.SetPopupStatus(true);
          console.log(data);
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if(error_status === 422){
            let errors = Object.keys(error.response.data.errors);
            console.log('errors',errors);
            for (let i = 0; i < errors.length; i++) {
              if(this.errored[errors[i]] !== undefined){
                this.errored[errors[i]] = true;
              }
            }
          }
        },
      })
    }
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>