export default {
  state: {
    search_line:'',
    stage: 0,
    search_data:{}
  },
  mutations: {
    SetSearch(state,search) {
      state.search_line = search;
    },
    SetStage(state,stage) {
      state.stage = stage;
    },
    SetSearchData(state,data) {
      state.search_data = data;
    },
  },
  actions: {},
  getters: {}
}
