<template>
  <section class="content">
    <div class="breadcrumbs"><a href="/" class="breadcrumbs-home"></a><i></i>Магазины</div>
    <h1>Магазины</h1>
    <div class="block-small">
      <form class="search">
        <div class="search-main">
          <input type="text" class="textfield" placeholder="Поиск по складам...">
          <button type="submit" class="search-go"></button>
        </div>
        <div class="search-fixed">
          <ul class="toolbox">
            <li>
              <button type="button" class="toolbox-show" @click="ShowAll">Показать</button>
            </li>
            <li>
              <button type="button" class="toolbox-hide" @click="HideAll">Скрыть</button>
            </li>
            <li>
              <button type="button" class="toolbox-remove" @click="DeleteAll">Удалить</button>
            </li>
          </ul>
        </div>
        <div class="search-fixed">
          <button type="button" class="button popup-open" @click="AddSellerShops">Добавить магазин</button>
        </div>
      </form>
    </div>
    <div class="block-small">
      <div class="table-wrapper">
        <table class="table warehouse-table check-group">
          <!--          header       -->
          <thead>
          <tr>
            <th class="table-sortable">Название</th>
            <th class="table-sortable">Адрес</th>
            <th class="table-sortable">Склады</th>
            <th class="table-sortable">Профили</th>
            <th class="table-sortable">Видимость</th>
            <th align="right">
              <input
                  type="checkbox"
                  class="checkbox checkbox-empty check-all"
                  @click="SelectAll"
                  ref="select_all"
                  id="item-all">
              <label for="item-all"></label>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="table-divider">
            <td colspan="8"></td>
          </tr>
          </tbody>
          <!--          header       -->
          <template v-if="shops.length > 0">
            <template v-for="(value, index) in shops" v-bind:key="index">
              <SellerShopsLine :line_info="value"/>
            </template>

          </template>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SellerShopsLine from "@/components/common/SellerShops/SellerShopsLine";
// import router from "@/router/router";

export default {
  name: "SellerShop",
  components: { SellerShopsLine },
  data: function () {
    return {
      select_block: false,
      shopsz: [
        {
          uuid: "14a6b990-0e53-44c9-a4eb-beafc47b4852",
          title: "Basteon nikolaev",
          address: "Украина, Николаев, улица Троицкая (бывш. Кирова) 244 (авторынок), магазин 240",
          warehouses: 1,
          profiles: 2,
          view: true,
        },
      ],
      shops: [],
      /*
         "[{
         'uuid'
          'title'
          'address'
          'storehouses_count'
          'managers_count'
          'hide'
          },]"
      */
    }
  },
  computed: {
    ...mapState({
      reload_flag: state => state.Main.reload_flag,
      select_top: state => state.SellerShops.select_top,
      select_all_flag: state => state.SellerShops.select_all_flag,
      selected: state => state.SellerShops.selected,
    }),
  },
  mounted() {
    this.SellerShopsSelectTopToggle(false);
    this.SellerShopsSelectAllToggleFlag(false);
    this.SellerShopsUnselectLineFlag(false);
    this.SellerShopsClearSelected();

    this.GetSellerShopsListData();
  },
  methods: {
    ...mapMutations([
      'SetSlidePopupName', 'SetSlidePopupStatus', 'SetPopupName', 'SetPopupStatus',
      'SellerShopsSetUUID', 'SetWarehouseName', 'SellerShopsSelectTopToggle', 'SellerShopsSelectAllToggleFlag',
      'SellerShopsUnselectLineFlag', 'SellerShopsClearSelected',
      'SetReloadFlag', 'SellerShopsSetPopupEditMode'
    ]),
    ...mapActions(['GetCall', 'PutCall']),
    AddSellerShops() {
      let editObj = {
        edit: false,
        data: null,
      }
      this.SellerShopsSetPopupEditMode(editObj);
      this.SetSlidePopupName('add_shop');
      this.SetSlidePopupStatus(true);
    },
    GetSellerShopsListData() {
      this.GetCall({
        endpoint: '/api/shops',
        callback: (data) => {
          this.shops = Array.from(data.data);
        },
      })
    },
    SelectAll() {
      this.select_block = true;
      this.SellerShopsSelectTopToggle(!this.select_top);
      this.SellerShopsSelectAllToggleFlag(this.select_top);
      this.SellerShopsUnselectLineFlag(false);
      this.select_block = false;
    },
    DeleteAll() {
      this.PutCall({
        endpoint: '/api/shops',
        param: {
          delete: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
    HideAll() {
      this.PutCall({
        endpoint: '/api/shops',
        param: {
          hide: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
    ShowAll() {
      this.PutCall({
        endpoint: '/api/shops',
        param: {
          show: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
  },
  watch: {
    select_top(newVal) {
      if (!this.select_block) {
        this.$refs.select_all.checked = newVal;
      }
    },
    reload_flag() {
      this.SetReloadFlag(false);
      window.location.reload(true);
    },
  },
}
</script>

<style scoped>

</style>