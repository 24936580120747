<template>
  <aside class="sidebar">
    <div class="flying" ref="col">
      <div
          class="flying-box"
          :class="[flying_sb_fix ? 'flying-fixed' : '',flying_sb_abs ? 'flying-absolute' : '']"
          ref="box">
        <div class="sidebar-content" v-if="!is_auth">
          <div class="sidebar-box">
            <div class="sidebar-login is-visible">
              <div class="sidebar-form">
                <div class="sidebar-form-header">Войти в аккаунт</div>
                <form class="form">
                  <fieldset>
                    <input type="text" v-model="form.email" class="textfield" placeholder="Телефон или E-mail">
                  </fieldset>
                  <fieldset class="show-pass">
                    <input type="password" v-model="form.password" class="textfield" placeholder="Пароль">
                    <button type="button" class="show-button">
                      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                            stroke-width="1.5"/>
                        <path
                            d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                            stroke-width="1.5"/>
                        <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
                      </svg>
                    </button>
                  </fieldset>
                  <fieldset>
                    <a href="javascript:void(0);" class="sidebar-form-forget-pass">напомнить пароль</a>
                  </fieldset>
                  <button
                      type="submit"
                      class="button button-block"
                      @click="AuthValidate"
                  >Войти
                  </button>
                </form>
                <ul class="social">
                  <li>
                    <a href="#" class="social-fb"></a>
                  </li>
                  <li>
                    <a href="#" class="social-google"></a>
                  </li>
                </ul>
                <div
                    class="sidebar-form-reg-link"
                    data-popup-open="reg"
                    @click="OpenRegPoup"
                >Зарегистрироваться
                </div>
              </div>
            </div>
            <div class="sidebar-pass">
              <div class="sidebar-form">
                <a href="javascript:void(0);" class="sidebar-form-back"><span>Пароль выслан</span>на Ваш телефон</a>
                <form class="form">
                  <fieldset class="show-pass">
                    <input type="password" class="textfield" placeholder="Пароль">
                    <button type="button" class="show-button">
                      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                            stroke-width="1.5"/>
                        <path
                            d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                            stroke-width="1.5"/>
                        <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
                      </svg>
                    </button>
                  </fieldset>
                  <fieldset>
                    <a href="#" class="sidebar-form-forget-pass">отправить еще раз (26 сек)</a>
                  </fieldset>
                  <button type="submit" class="button button-block">Войти</button>
                </form>
              </div>
            </div>
          </div>
          <nav class="sidebar-menu">
            <ul>
              <li>
                <a href="#" class="sidebar-company">
                  <span>О компании</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-buyer">
                  <span>Покупателю</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-seller">
                  <span>Продавцу</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="sidebar-content" v-if="is_auth && user_type === 'buyer'">
          <a href="#" class="sidebar-user">
            <img src="pic/user.jpg" srcset="pic/user.jpg 1x, pic/user@2x.jpg 2x" class="sidebar-user-avatar" alt="">
            <div class="sidebar-user-info">
              <div class="sidebar-user-name">Супер-Запчасть-Украина</div>
              <div class="sidebar-user-rating">4,8 <span>(6298 отзывов)</span></div>
            </div>
          </a>
          <nav class="sidebar-menu">
            <ul>
              <li>
                <a href="/orders?type=buy" class="sidebar-order">
                  <span>Покупки</span>
                  <span>236</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-settings">
                  <span>Настройки аккаунта</span>
                </a>
              </li>
            </ul>
            <hr>
            <ul>
              <li>
                <a href="#" class="sidebar-garage">
                  <span>Мой транспорт</span>
                  <span>5</span>
                </a>
              </li>
              <li>
                <a class="sidebar-logout" @click="Logout">
                  <span>Выйти</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="sidebar-content" v-if="is_auth && user_type === 'seller'">
          <a href="#" class="sidebar-user">
            <img src="pic/user.jpg" srcset="pic/user.jpg 1x, pic/user@2x.jpg 2x" class="sidebar-user-avatar" alt="">
            <div class="sidebar-user-info">
              <div class="sidebar-user-name"><a href="/seller-settings">{{ company }}</a></div>
              <div class="sidebar-user-rating">4,8 <span>(6298 отзывов)</span></div>
            </div>
          </a>
          <div class="sidebar-box">
            <div class="sidebar-stats sidebar-stats-balance">
              <span>Баланс</span>
              <span>200.01$</span>
            </div>
            <div class="sidebar-stats-button">
              <a href="#" class="button button-block">Пополнить баланс</a>
            </div>
            <div class="sidebar-stats sidebar-stats-expenses">
              <span>Расходы</span>
              <span>19.83$</span>
            </div>
            <div class="sidebar-stats sidebar-stats-visitors">
              <span>Посещения</span>
              <span>32</span>
            </div>
          </div>
          <nav class="sidebar-menu">
            <ul>
              <li>
                <a href="/orders?type=sell" class="sidebar-order">
                  <span>Продажи</span>
                  <span>236</span>
                </a>
              </li>
              <li>
                <a href="/orders?type=buy" class="sidebar-order">
                  <span>Покупки</span>
                  <span>236</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-settings">
                  <span>Настройки аккаунта</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-contact">
                  <span>Контактные профили</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-confirm">
                  <span>Подтверждение</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-waybill">
                  <span>Создать накладную</span>
                </a>
              </li>
            </ul>
            <hr>
            <ul>
              <li>
                <router-link class="sidebar-warehouse" to="/warehouses">
                  <span>Склады</span>
                  <span>3</span>
                </router-link>
              </li>
              <li>
                <router-link class="sidebar-store" to="/shops">
                  <span>Магазины</span>
                  <span>2</span>
                </router-link>
              </li>
              <li>
                <a href="#" class="sidebar-webstore">
                  <span>Интернет-магазин</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-garage">
                  <span>Мой транспорт</span>
                  <span>5</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-disassembly">
                  <span>На разборке</span>
                  <span>0</span>
                </a>
              </li>
              <li>
                <a href="#" class="sidebar-service-station">
                  <span>Мои СТО</span>
                  <span>2</span>
                </a>
              </li>
              <li>
                <a class="sidebar-logout" @click="Logout">
                  <span>Выйти</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import router from "@/router/router";

export default {
  name: "SideBar",
  data: function () {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapState({
      flying_sb_fix: state => state.Main.flying_sb_fix,
      flying_sb_abs: state => state.Main.flying_sb_abs,
      is_auth: state => state.User.is_auth,
      user_type: state => state.User.usertype,
      company: state => state.User.company,
    }),
  },
  mounted() {
    this.fResize();
  },
  created() {
    window.addEventListener("resize", this.fResize);
    window.addEventListener("scroll", this.fResize)
  },
  destroyed() {
    window.removeEventListener("resize", this.fResize);
    window.removeEventListener("scroll", this.fResize)
  },
  methods: {
    ...mapMutations([
      'FlyingSbFix', 'FlyingSbAbs', 'FlyingSbRelease', 'SetPopupName', 'SetPopupStatus', 'SetLoginStatus',
      'SetSlidePopupName', 'SetSlidePopupStatus','SetOrderType',
    ]),
    ...mapActions(['Auth', 'Logout']),
    AuthValidate(e) {
      e.preventDefault();
      if (this.form.email !== '' && this.form.password !== '') {
        this.Auth(this.form);
      }
    },
    OpenRegPoup() {
      this.SetSlidePopupName('reg');
      this.SetSlidePopupStatus(true);
    },
    fResize() {
      if (window.matchMedia('(min-width: 768px)')) {

        let col = this.$refs.col.clientHeight;
        let box = this.$refs.box.clientHeight;

        if (col > box) {

          let scroll = window.top.scrollY;
          let delta = col - box;

          if (scroll > 0) {
            if (scroll < delta) {
              this.FlyingSbFix();
            } else {
              this.FlyingSbAbs();
            }
          } else {
            this.FlyingSbRelease();
          }
        } else {
          this.FlyingSbRelease();
        }
      }
    },
  }
}
</script>

<style scoped>
.sidebar-form-reg-link {
  cursor : pointer;
}
</style>