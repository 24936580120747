<template>
  <div class="popup" :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 470px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-remove">
        <div class="popup-header">Удалить склад?</div>
        <p>{{ warehouse_name }}</p>
        <ul class="popup-button-row">
          <li>
            <button type="button" class="button button-middle" @click="Remove">Удалить навсегда</button>
          </li>
          <li>
            <div @click="hide">Отмена</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PWarehouseRemove",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      warehouse_name: state => state.Popups.warehouse_name,
      warehouse_uuid: state => state.Popups.warehouse_uuid,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd','SetReloadFlag']),
    ...mapActions(['DeleteCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
    Remove() {
      this.DeleteCall({
        endpoint: '/api/storehouse/' + this.warehouse_uuid,
        param: '',
        callback: (data) => {
          this.SetReloadFlag(true);
          this.SetPopupName('warehouse_remove_success');
          this.SetPopupStatus(true);
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if(error_status === 422){
            let errors = Object.keys(error.response.data.errors);
            console.log('errors',errors);
          }
        },
      })
    }

  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },

}
</script>

<style scoped>

</style>