<template>
  <section class="content">
    <div class="breadcrumbs"><a href="#" class="breadcrumbs-home"></a><i></i><a href="#">Оформление заказа</a></div>
    <h1>Оформление заказа</h1>
    <section class="block">
      <ul class="order-steps">
        <li class="is-active">
          <span>Корзина</span>
        </li>
        <li class="is-active">
          <span>Оформление заказа</span>
        </li>
        <li>
          <span>Обработка заказа</span>
        </li>
        <li>
          <span>Доставка</span>
        </li>
        <li>
          <span>Оставить отзыв</span>
        </li>
      </ul>
    </section>
    <section class="block" v-if="order_data">
      <div class="cart">
        <div class="cart-store">
          <div class="cart-store-side">
            <div class="cart-store-name">{{ order_data.seller.name }}</div>
            <div class="cart-store-rating">{{ order_data.seller.rate }} <span>({{ order_data.seller.reviews_num }} отзыва)</span></div>
            <div class="cart-store-orders">{{ order_data.seller.orders_num }} заказа(ов)</div>
          </div>
        </div>
        <template v-for="(value, index) in order_data.products" v-bind:key="index">
          <OrderProduct :product="value" :order="order_data"></OrderProduct>
        </template>
        <div class="cart-go">
          <div class="cart-go-info">
            <div class="cart-item-row">
              <div class="cart-item-box">
                <span>Общая сумма</span>
                <p>{{ order_data.total }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="block" v-if="order_data">
      <form class="form order-form">
        <div class="form-row">
          <fieldset>
            <div class="form-field"
                :class="[form.buyer.length > 0 ? 'is-filled':'', errored.buyer ? 'errored':'']">
              <input type="text" class="textfield" v-model="form.buyer">
              <label>Ваши ФИО</label>
            </div>
          </fieldset>
          <fieldset>
            <div class="form-field"
                :class="[form.phone.length > 0 ? 'is-filled':'', errored.phone ? 'errored':'']">
              <input type="text" class="textfield" v-model="form.phone">
              <label>Телефон</label>
            </div>
          </fieldset>
          <fieldset>
            <div class="form-field"
                :class="[form.pay_method.length > 0 ? 'is-filled':'', errored.pay_method ? 'errored':'']">
              <input type="text" class="textfield" v-model="form.pay_method">
              <label>Способ оплаты</label>
            </div>
          </fieldset>
        </div>
        <fieldset>
          <div class="form-field"
              :class="[form.address.length > 0 ? 'is-filled':'', errored.address ? 'errored':'']">
            <textarea class="textfield autosize" rows="1" v-model="form.address"></textarea>
            <label>Адрес доставки</label>
          </div>
        </fieldset>
        <div class="form-row">
          <fieldset>
            <div class="form-field"
                 :class="[form.vin.length > 0 ? 'is-filled':'', errored.vin ? 'errored':'']">
              <input type="text" class="textfield" v-model="form.vin">
              <label>VIN код автомобиля</label>
            </div>
          </fieldset>
          <div class="form-row-double"
               :class="[errored.vin_check ? 'errored':'']">
            <input type="checkbox" class="checkbox" id="check-vin-code" v-model="form.vin_check">
            <label for="check-vin-code">Проверить по VIN-коду</label>
          </div>
        </div>
        <fieldset>
          <div class="form-field"
               :class="[form.comment.length > 0 ? 'is-filled':'', errored.comment ? 'errored':'']">
            <textarea class="textfield" v-model="form.comment"></textarea>
            <label>Комментарий к заказу...</label>
          </div>
        </fieldset>
        <div class="form-button">
          <button type="submit" class="button" @click="AcceptOrder">Оформить заказ</button>
        </div>
      </form>
    </section>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import router from "@/router/router";
import OrderProduct from "@/components/common/Order/OrderProduct";

export default {
  name: "Order",
  components:{OrderProduct},
  data: function () {
    return {
      show: false,
      form: {
        accept: true,
        status: 'cart', // 'buyer_accept'/'received'/'rejected'

        buyer: 'Панькив Олег Олегович',
        address: 'Новая Почта, Винница, Отделение №13 (до 30 кг): ул. Гонты, 19, пом. 3',
        pay_method: 'Оплата при получении',
        phone: '380638469602',
        vin: '1244235dfghdhg5',
        vin_check: true,
        comment:'1244235dfghdhg5',

        info:{ //выбранные параметры доставки
          buyer: 'Панькив Олег Олегович',
          address: 'Новая Почта, Винница, Отделение №13 (до 30 кг): ул. Гонты, 19, пом. 3',
          pay_method: 'Оплата при получении',
          phone: '380638469602',
          email: 'andrey.s.pikul@yandex.ru',
          vin: '1244235dfghdhg5',
          vin_check: true,
        },
      },
      errored: {
        buyer: false,
        ttn: false,
        address: false,
        pay_method: false,
        phone: false,
        vin: false,
        vin_check: false,
        comment: false,
        info:{ //выбранные параметры доставки
          buyer: false,
          ttn: false,
          address: false,
          pay_method: false,
          phone: false,
          email: false,
          vin: false,
          vin_check: false,
        },
      },
      order:{
        // uuid: '...', //uuid заказа
        // total: '100500', //общая стоимость заказа
        // seller: {
        //   name: 'ООО Продаван',
        //   uuid: '...',
        //   rate: '4.5',
        //   fav: false,
        //   reviews_num: 23,
        //   orders_num: 100500,
        // },
        // products: [
        //   {
        //     uuid: '...',
        //     pic: '123.jpg',
        //     manufacturer: 'VAG',
        //     code: '3c0807837a',
        //     num: 5,
        //     description: 'колодки тормозные',
        //     post_time: 'сегодня', //срок поставки,
        //     post_city: 'г. Харьков',
        //     sum: '100500', //стоимость с учетом количества
        //   }
        // ]
      },
      order_data: undefined,
    }
  },
  computed: {
    ...mapState({
      // order_data: state => state.Order.order_data,
    }),
  },
  mounted() {
    //this.$route.query.uuid
    if(this.order_data === undefined){
      this.GetCartData();
    }else{
      this.form.buyer = this.order_data.buyer.name;
    }
  },
  methods: {
    ...mapMutations(['SetStage']),
    ...mapActions(['GetCall','PostCall',]),
    hide() {
      console.log('hide');
      this.SetStage(0);
    },

    GetCartData() {
      this.GetCall({
        endpoint: '/api/cart',
        callback: (data) => {
          this.order_data = Array.from(data.data).find((element, index, array)=>{return element.uuid === this.$route.query.uuid});
          this.form.buyer = this.order_data.buyer.name;
        },
      })
    },

    AcceptOrder(e) {
      e.preventDefault();

      this.PostCall({
        endpoint: '/api/cart/'+this.order_data.uuid,
        param: this.form,
        callback: (data) => {
          router.push({ path: '/delivery-buy', query:{uuid:this.order_data.uuid}});
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if(error_status === 422){
            let errors = Object.keys(error.response.data.errors);
            console.log('errors',errors);
            for (let i = 0; i < errors.length; i++) {
              if(this.errored[errors[i]] !== undefined){
                this.errored[errors[i]] = true;
              }
            }
          }
        },
      })

    },
  },

}
</script>

<style scoped>

</style>