<template>
  <aside class="extra">
    <div class="flying" ref="col">
      <div
          class="flying-box"
          :class="[flying_e_fix ? 'flying-fixed' : '',flying_e_abs ? 'flying-absolute' : '']"
          ref="box">
        <div class="extra-content">
          <nav class="extra-nav">
            <ul>
              <li class="extra-nav-hidden">
                <a href="javascript:void(0);" class="extra-user"></a>
              </li>
              <li>
                <a href="#" class="extra-menu"></a>
              </li>
              <li>
                <router-link class="extra-cart" to="/cart">
                  <span>8</span>
                </router-link>
              </li>
              <li>
                <a href="#" class="extra-mail">
                  <span>39</span>
                </a>
              </li>
              <li>
                <a href="#" class="extra-fav">
                  <span>22</span>
                </a>
              </li>
              <li>
                <a href="#" class="extra-black-list">
                  <span>22</span>
                </a>
              </li>
            </ul>
          </nav>
          <ul class="extra-lang">
            <li>Рус</li>
            <li>
              <a href="#">Укр</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "Extra",
  computed: {
    ...mapState({
      flying_e_fix: state => state.Main.flying_e_fix,
      flying_e_abs: state => state.Main.flying_e_abs,
    }),
  },
  mounted() {
    this.fResize();
  },
  created() {
    window.addEventListener("resize", this.fResize);
    window.addEventListener("scroll", this.fResize)
  },
  destroyed() {
    window.removeEventListener("resize", this.fResize);
    window.removeEventListener("scroll", this.fResize)
  },
  methods: {
    ...mapMutations(['FlyingEFix', 'FlyingEAbs', 'FlyingERelease',]),
    fResize() {
      if (window.matchMedia('(min-width: 768px)')) {

        let col = this.$refs.col.clientHeight;
        let box = this.$refs.box.clientHeight;

        if (col > box) {

          let scroll = window.top.scrollY;
          let delta = col - box;

          if (scroll > 0) {
            if (scroll < delta) {
              this.FlyingEFix();
            } else {
              this.FlyingEAbs();
            }
          } else {
            this.FlyingERelease();
          }
        } else {
          this.FlyingERelease();
        }
      }
    },
  }
}
</script>

<style scoped>

</style>