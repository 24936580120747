<template>
  <section class="content">
    <div class="breadcrumbs"><a href="#" class="breadcrumbs-home"></a><i></i><a href="#">Оформление заказа</a></div>
    <h1>Оформление заказа</h1>
    <section class="section">
      <section class="block">
        <ul class="order-steps">
          <li :class="[(
              detail.status_cart === 'cart' ||
              detail.status_cart === 'seller_accept'||
              detail.status_cart === 'buyer_accept'||
              detail.status_cart === 'sended'||
              detail.status_cart === 'received'||
              detail.status_cart === 'rejected'
              ) ? 'is-active':'']">
            <span>Корзина</span>
          </li>
          <li :class="[(
              detail.status_cart === 'buyer_accept'||
              detail.status_cart === 'seller_accept'||
              detail.status_cart === 'sended'||
              detail.status_cart === 'received'||
              detail.status_cart === 'rejected'
              ) ? 'is-active':'']">
            <span>Оформление заказа</span>
          </li>
          <li :class="[(
              detail.status_cart === 'seller_accept'||
              detail.status_cart === 'sended'||
              detail.status_cart === 'received'||
              detail.status_cart === 'rejected'
              ) ? 'is-active':'']">
            <span>Обработка заказа</span>
          </li>
          <li :class="[(
              detail.status_cart === 'sended'||
              detail.status_cart === 'received'||
              detail.status_cart === 'rejected'
              ) ? 'is-active':'']">
            <span>Доставка</span>
          </li>
          <li :class="[(
              detail.status_cart === 'received'||
              detail.status_cart === 'rejected'
              ) ? 'is-active':'']">
            <span>Оставить отзыв</span>
          </li>
        </ul>
      </section>
      <section class="block">
        <div class="cart">
          <div class="cart-store">
            <div class="cart-store-side">
              <div class="cart-store-name">{{ detail.seller.name }}</div>
              <div class="cart-store-rating">{{ detail.seller.rate }} <span>({{ detail.seller.reviews_num }} отзыва(ов))</span></div>
              <div class="cart-store-orders">{{ detail.seller.orders_num }} заказа(ов)</div>
            </div>
            <div class="cart-store-side">
              <div class="cart-store-contact">
                <div class="cart-store-phone">{{ detail.seller.phone }}</div>
                <ul class="cart-store-social">
                  <li>
                    <a href="#" class="telegram"></a>
                  </li>
                  <li>
                    <a href="#" class="viber"></a>
                  </li>
                  <li>
                    <a href="#" class="whatsapp"></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="cart-item">
            <img src="pic/cart-item.jpg" srcset="pic/cart-item.jpg 1x, pic/cart-item@2x.jpg 2x" class="cart-image" alt="">
            <div class="cart-item-info">
              <template v-for="(value, index) in detail.products" v-bind:key="index">
                <div class="cart-item-row">
                  <div class="cart-item-box">
                    <span>Товар</span>
                    <p>{{ value.code }}</p>
                  </div>
                  <div class="cart-item-box">
                    <span>Бренд</span>
                    <p>{{ value.manufacturer }}</p>
                  </div>
                  <div class="cart-item-box">
                    <span>Описание</span>
                    <p>{{ value.description }}</p>
                  </div>
                  <div class="cart-item-box">
                    <span>Отправка</span>
                    <p>{{ value.post_time }} дней в {{ value.post_city }}</p>
                  </div>
                  <div class="cart-item-box">
                    <span>Количество</span>
                    <div class="cart-item-count">
                      <div class="count count-small">
                        <input disabled type="text" class="textfield numbers-only" :value="value.num">
                      </div>
                    </div>
                  </div>
                  <div class="cart-item-box">
                    <span>Сумма</span>
                    <p>{{ value.sum }}</p>
                  </div>
                </div>
              </template>
            </div>
<!--            <button type="button" class="cart-item-remove"></button>-->
          </div>
          <div class="cart-go">
            <div class="cart-go-info">
              <div class="cart-item-row">
                <div class="cart-item-box">
                  <span>Общая сумма</span>
                  <p>{{ detail.total }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section class="section">
      <h2>Данные доставки и оплаты</h2>
      <div class="delivery">
        <div class="delivery-col">
          <ul class="delivery-info">
            <li class="delivery-info-important">
              <div class="delivery-info-title">
                <span>ФИО покупателя</span>
              </div>
              <div class="delivery-info-text">{{ detail.buyer.name }}</div>
            </li>
            <li class="delivery-info-important">
              <div class="delivery-info-title">
                <span>Телефон</span>
              </div>
              <div class="delivery-info-text">{{ detail.buyer.phone }}</div>
            </li>
            <li>
              <div class="delivery-info-title">
                <span>E-mail</span>
              </div>
              <div class="delivery-info-text">{{ detail.buyer.email }}</div>
            </li>
            <li>
              <div class="delivery-info-title">
                <span>Условия доставки</span>
              </div>
              <div class="delivery-info-text">{{ detail.info.address }}</div>
            </li>
            <li>
              <div class="delivery-info-title">
                <span>Способ оплаты</span>
              </div>
              <div class="delivery-info-text">{{ detail.info.pay_method }}</div>
            </li>
          </ul>
        </div>
        <div class="delivery-col">
          <ul class="delivery-info">
            <li>
              <div class="delivery-info-title">
                <span>Выполнение заказа</span>
              </div>
              <div class="delivery-info-text">сегодня</div>
            </li>
            <li>
              <div class="delivery-info-title">
                <span>Количество товаров</span>
              </div>
              <div class="delivery-info-text">{{ detail.products.length }} шт</div>
            </li>
            <li class="delivery-info-important">
              <div class="delivery-info-title">
                <span>Итого к оплате</span>
              </div>
              <div class="delivery-info-text">{{ detail.total }} UAH</div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="section" v-if="detail.comments">
      <h2>Комментарий к заказу</h2>
      <p>Временный текст комментария</p>
<!--      <p>{{ detail.comments[0].text }}</p>-->
    </section>
    <section class="section">
      <h2>Процесс сделки</h2>
      <div class="block" v-if="detail.status_cart === 'seller_accept' || detail.status_cart === 'sended'">
        <form class="form">
          <div class="form-button">
            <div class="button button-middle" @click="RecieveAccept">Подтвердить получение</div>
          </div>
        </form>
      </div>
      <div class="block" v-if="detail.status_cart === 'received'">
        <form class="form">
          <fieldset>
            <textarea class="textfield autosize" rows="1" placeholder="Комментарий о сделке..."></textarea>
          </fieldset>
          <div class="form-button">
            <button class="button button-middle">Оставить комментарий</button>
          </div>
        </form>
      </div>
      <div class="block">
        <div class="comment">
          <div class="comment-aside">
            <img src="pic/comment-avatar-1.jpg" srcset="pic/comment-avatar-1.jpg 1x, pic/comment-avatar-1@2x.jpg 2x" class="comment-avatar" alt="">
          </div>
          <div class="comment-main">
            <div class="comment-info">
              <div class="comment-author">Название магазина</div>
              <div class="comment-date">12 октября 2020 в 19:19</div>
            </div>
            <ul class="rating">
              <li>
                <img src="img/rating-star-full.svg" alt="">
              </li>
              <li>
                <img src="img/rating-star-full.svg" alt="">
              </li>
              <li>
                <img src="img/rating-star-full.svg" alt="">
              </li>
              <li>
                <img src="img/rating-star-half.svg" alt="">
              </li>
              <li>
                <img src="img/rating-star.svg" alt="">
              </li>
            </ul>
            <div class="comment-text">
              <p>Есть много вариантов  Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.</p>
            </div>
            <button type="button" class="comment-reply">Ответить</button>
          </div>
        </div>
        <div class="comment">
          <div class="comment-aside">
            <img src="pic/comment-avatar-2.jpg" srcset="pic/comment-avatar-2.jpg 1x, pic/comment-avatar-2@2x.jpg 2x" class="comment-avatar" alt="">
            <ul class="comment-options">
              <li>
                <a href="#" class="comment-personal">ЛС</a>
              </li>
              <li>
                <a href="#" class="comment-complaint">Жалоба</a>
              </li>
            </ul>
          </div>
          <div class="comment-main">
            <div class="comment-info">
              <div class="comment-author">Имя пользователя</div>
              <div class="comment-date">12 октября 2020 в 19:19</div>
            </div>
            <div class="comment-text">
              <p>Есть много вариантов  Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.</p>
            </div>
            <button type="button" class="comment-reply">Ответить</button>
          </div>
        </div>
        <div class="comment">
          <div class="comment-aside">
            <img src="pic/comment-admin.jpg" srcset="pic/comment-admin.jpg 1x, pic/comment-admin@2x.jpg 2x" class="comment-avatar" alt="">
          </div>
          <div class="comment-main">
            <div class="comment-info">
              <div class="comment-author">Avtoprice</div>
              <div class="comment-date">12 октября 2020 в 19:19</div>
            </div>
            <div class="comment-text">
              <p>Заказ отправлен. Обновление в заказе:<br>ТТН 20450292706242</p>
            </div>
          </div>
        </div>
        <div class="comment">
          <div class="comment-aside">
            <img src="pic/comment-admin.jpg" srcset="pic/comment-admin.jpg 1x, pic/comment-admin@2x.jpg 2x" class="comment-avatar" alt="">
          </div>
          <div class="comment-main">
            <div class="comment-info">
              <div class="comment-author">Avtoprice</div>
              <div class="comment-date">12 октября 2020 в 19:19</div>
            </div>
            <div class="comment-text">
              <p>Заказ ожидает отправки</p>
            </div>
          </div>
        </div>
        <div class="comment">
          <div class="comment-aside">
            <img src="pic/comment-admin.jpg" srcset="pic/comment-admin.jpg 1x, pic/comment-admin@2x.jpg 2x" class="comment-avatar" alt="">
          </div>
          <div class="comment-main">
            <div class="comment-info">
              <div class="comment-author">Avtoprice</div>
              <div class="comment-date">12 октября 2020 в 19:19</div>
            </div>
            <div class="comment-text">
              <p>Ожидает подтверждения продавцом</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DeliveryBuy",
  data: function () {
    return {
      detail: {
        uuid: '',
        num: '', //номер заказа
        status_cart: 'cart', // 'cart'/'buyer_accept'/'seller_accept'/'sended'/'received'/'rejected'
        total: 100500, //сумма заказа
        info: { //выбранные параметры доставки
          buyer: 'Панькив Олег Олегович',
          ttn: 'ТТН 20400119649654',
          address: 'Новая Почта, Винница, Отделение №13 (до 30 кг): ул. Гонты, 19, пом. 3',
          pay_method: 'Оплата при получении',
          phone: '380638469602',
          email: 'andrey.s.pikul@yandex.ru',
          vin: '1244235dfghdhg5',
          vin_check: true,
        },
        products: [
          {
            uuid: '...',
            pic: '123.jpg',
            manufacturer: 'VAG',
            code: '3c0807837a',
            num: 5,
            description: 'колодки тормозные',
            post_time: 'сегодня', //срок поставки,
            post_city: 'г. Харьков',
            price: '1050', //цена
            sum: '100500', //стоимость с учетом количества
          },
        ],
        seller: {
          name: 'ООО Продаван',
          uuid: '...',
          phone: '380936666666',
          rate: '4.5',
          fav: false,
          reviews_num: 23,
          orders_num: 100500,
        },
        buyer: {
          name: 'Вася Вася Вася',
          uuid: '...',
          address: 'Новая Почта, Винница, Отделение №13 (до 30 кг): ул. Гонты, 19, пом. 3',
          pay_method: 'Оплата при получении',
          phone: '380638469602',
          email: 'andrey.s.pikul@yandex.ru',
        },
        comments: [
          {
            author: 'seller',
            date: '12323',// дата коммента
            text: 'sdffsdfg  gsgs'
          },
        ]
      }
    }
  },
  mounted() {
    this.GetOrderDetail();
  },
  methods: {
    ...mapActions(['GetCall','PutCall']),
    GetOrderDetail() {
      this.GetCall({
        endpoint: '/api/buyes/' + this.$route.query.uuid,
        callback: (data) => {
          this.detail = data.data;
        },
      })
    },
    RecieveAccept(){
      this.PutCall({
        endpoint: '/api/buyes/' + this.$route.query.uuid,///api/buyes/{uuid}
        param: {
          status:'received', // 'buyer_accept'/'received'/'rejected'
        },
        callback: (data) => {
          //this.SetCartReloadFlag(true);
          this.detail.status_cart = 'received';
        },
      })
    },
  }
}
</script>

<style scoped>

</style>