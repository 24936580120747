export default {
	state: {
		selected: [],
		seller_shop_uuid: null,
		seller_shop_name: null,

		select_top: false,
		select_all_flag: false,
		unselect_line: false,

		popup_edit_mode: false,
		popup_edit_data: null,
	},
	mutations: {
		SellerShopSetUUID(state, uuid) {
			state.seller_shop_uuid = uuid;
		},
		SellerShopName(state, name) {
			state.seller_shop_name = name;
		},
		SellerShopsSetUUID(state, uuid) {
			let index_of_uuid = state.selected.indexOf(uuid);
			if (index_of_uuid === -1) {
				state.selected.push(uuid);
			} else {
				state.selected.splice(index_of_uuid, 1);
			}
		},
		SellerShopsClearSelected(state) {
			state.selected = [];
		},

		SellerShopsSelectTopToggle(state, is_select) {
			state.select_top = is_select;
		},
		SellerShopsSelectAllToggleFlag(state, status) {
			state.select_all_flag = status;
		},
		SellerShopsUnselectLineFlag(state, status) {
			state.unselect_line = status;
		},

		SellerShopsSetPopupEditMode(state,obj){
			state.popup_edit_mode = obj.edit;
			if(obj.edit){
				state.popup_edit_data = {...obj.data};
			}else{
				state.popup_edit_data = null;
			}
		}
	},
	actions: {},
	getters: {}
}
