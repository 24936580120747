import { createWebHistory, createRouter } from 'vue-router';
import store from '@/store';
import Main from '@/components/pages/Main.vue';
import Warehouses from '@/components/pages/Warehouses.vue';
import Warehouse from '@/components/pages/Warehouse.vue';
import Order from '@/components/pages/Order.vue';
import Orders from '@/components/pages/Orders.vue';
import Cart from '@/components/pages/Cart.vue';
import SearchResult from '@/components/pages/SearchResult.vue';
import UploadPrice from '@/components/pages/UploadPrice.vue';
import DeliveryBuy from "@/components/pages/DeliveryBuy";
import DeliverySell from "@/components/pages/DeliverySell";
import ProfileSeller from "@/components/pages/ProfileSeller";
import SellerShops from "@/components/pages/SellerShops";
import SellerShop from "@/components/pages/SellerShop";
import SellerSettings from "@/components/pages/SellerSettings";

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
  },
  {
    path: "/warehouses",
    name: 'Warehouses',
    component: Warehouses,
    meta: { requiresLogin: true , requiresSeller: true},
  },
  {
    path: "/warehouse",
    name: 'Warehouse',
    component: Warehouse,
    meta: { requiresLogin: true , requiresSeller: true},
  },
  {
    path: "/order",
    name: 'Order',
    component: Order,
    meta: { requiresLogin: true },
  },
  {
    path: "/orders",
    name: 'Orders',
    component: Orders,
    meta: { requiresLogin: true },
  },
  {
    path: "/cart",
    name: 'Cart',
    component: Cart,
    meta: { requiresLogin: true },
  },
  {
    path: "/search-result",
    name: 'SearchResult',
    component: SearchResult,
    meta: { requiresLogin: false },
  },
  {
    path: "/upload-price",
    name: 'UploadPrice',
    component: UploadPrice,
    meta: { requiresLogin: true , requiresSeller: true},
  },
  {
    path: "/delivery-buy",
    name: 'DeliveryBuy',
    component: DeliveryBuy,
    meta: { requiresLogin: true },
  },
  {
    path: "/delivery-sell",
    name: 'DeliverySell',
    component: DeliverySell,
    meta: { requiresLogin: true , requiresSeller: true},
  },
  {
    path: "/profile-seller",
    name: 'ProfileSeller',
    component: ProfileSeller,
    meta: { requiresLogin: true , requiresSeller: false},
  },
  {
    path: "/shops",
    name: 'SellerShops',
    component: SellerShops,
    meta: { requiresLogin: true , requiresSeller: true},
  },
  {
    path: "/shop",
    name: 'SellerShop',
    component: SellerShop,
    meta: { requiresLogin: true , requiresSeller: true},
  },
  {
    path: "/seller-settings",
    name: 'SellerSettings',
    component: SellerSettings,
    meta: { requiresLogin: true , requiresSeller: true},
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    console.log(store.state.User.is_auth, store.state.User.usertype);

    if (!store.state.User.is_auth) {
      console.log('go to /');
      next({
        path: '/',
        //query: { redirect: to.fullPath }
      })
    } else {
      if (to.matched.some(record => record.meta.requiresSeller)){
          if(store.state.User.usertype !== 'seller'){
            console.log('go to / 2');

            next({
              path: '/',
              //query: { redirect: to.fullPath }
            })
          }else{
            next()
          }
      }else{
        next()
      }
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router;

