<template>
  <tr>
    <td align="center" data-label="код" @click="EditItem(line_info.uuid)">{{ line_info.code }}</td>
    <td align="center" data-label="бренд">
      <template v-if="line_info.manufacturer !== null">{{ line_info.manufacturer.name }}</template>
    </td>
    <td align="center" data-label="категория">
      <!--                <template v-if="line_info.manufacturer !== null">{{ line_info.manufacturer }}</template>-->
    </td>
    <td align="center" data-label="Описание">
      <template v-if="line_info.description !== null">{{ line_info.description }}</template>
    </td>
    <td align="center" data-label="цена для pro, uah">
      <!--                <template v-if="line_info.manufacturer !== null">{{ line_info.manufacturer }}</template>-->
    </td>
    <td align="center" class="warehouse-table-price" data-label="цена для покупателей, uah">
      {{ line_info.price }}
      <!--                <span>+168.88</span>-->
    </td>
    <td align="center" data-label="кол-во">
      <template v-if="line_info.number !== null">{{ line_info.number }}</template>
    </td>
    <td align="center" nowrap data-label="б/у">
      <template v-if="line_info.used === true">б/у</template>
      <template v-if="line_info.used === false">новая</template>
    </td>
    <td>
      <ul class="table-buttons-list align-right">
        <li>
          <button
              type="button" class="table-button table-button-visibility"
              :class="visible ? 'is-visible':''"
              @click="ProductSetVisible"
          >
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                  stroke-width="1.5"/>
              <path
                  d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                  stroke-width="1.5"/>
              <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
            </svg>
          </button>
        </li>
        <li>
          <button type="button" class="table-button table-button-remove" @click="ProductDelete"></button>
        </li>
        <li>
          <input
              type="checkbox"
              class="checkbox checkbox-empty"
              :id="'item-'+line_info.uuid"
              @click="ProductSelect"
              ref="select_btn"
          >
          <label :for="'item-'+line_info.uuid"></label>
        </li>
      </ul>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
// import router from "@/router/router";

export default {
  name: "Warehouse_line",
  data: function () {
    return {
      visible: true,
      selected: false,
    }
  },
  props: {
    line_info: Object,
  },
  computed: {
    ...mapState({
      select_top: state => state.Products.select_top,
      select_all_flag: state => state.Products.select_all_flag,
      unselect_line: state => state.Products.unselect_line,
    }),
  },
  mounted() {
    this.visible = !this.line_info.hide;
  },
  methods: {
    ...mapMutations([
      'SetPopupName', 'SetPopupStatus', 'SetWarehouseUUID', 'SetProductUUID', 'SetProductName',
      'ProductUUID', 'SelProdAllToggleFlag', 'SelProdTopToggle', 'UnSelProdLineFlag','SetSlidePopupName',
      'SetSlidePopupStatus'
    ]),
    ...mapActions(['PutCall']),
    EditItem(uuid) {
      this.SetWarehouseUUID(this.$route.query.uuid);
      this.SetProductUUID(uuid);
      this.SetSlidePopupName('edit_item');
      this.SetSlidePopupStatus(true);
    },
    ProductDelete() {
      this.SetWarehouseUUID(this.$route.query.uuid);
      this.SetProductUUID(this.line_info.uuid);
      this.SetProductName(name);
      this.SetPopupName('product_remove');
      this.SetPopupStatus(true);
    },
    ProductSetVisible() {
      this.visible = !this.visible;

      let form = {
        hide: !this.visible,
        bargain: false,
        code: this.line_info.code,
        manufacturer: this.line_info.manufacturer.name,
        original_code: this.line_info.original_code,
        description: this.line_info.description,
        used: this.line_info.used,
        number: this.line_info.number,
        price: this.line_info.price,
      }

      this.PutCall({
        endpoint: '/api/storehouse/' + this.$route.query.uuid + '/products/' + this.line_info.uuid,
        param: form,
        callback: (data) => {

        },
        on_error: (error) => {
          let error_status = error.response.status;

          if (error_status === 422) {
            let errors = Object.keys(error.response.data.errors);
            console.log('errors', errors);
          }
        },
      })
    },
    ProductSelect() {
      this.UnSelProdLineFlag(true);
      this.selected = !this.selected;
      this.SelProdTopToggle(false);
      this.SelProdAllToggleFlag(false);
      this.ProductUUID(this.line_info.uuid);
      setTimeout(()=>{this.UnSelProdLineFlag(false);},20)
    },
  },
  watch: {
    select_all_flag(newVal) {
      console.log(this.unselect_line);
      if(!this.unselect_line) {
        if ((newVal && !this.selected) || (!newVal && this.selected)) {
          this.$refs.select_btn.checked = this.selected = newVal;
          this.ProductUUID(this.line_info.uuid);
        }
      }
    }
  },
}
</script>

<style scoped>

</style>