<template>
  <header>
    <div class="logo">
      <a href="/">
        <span>Avtoprice</span>
        <p>поиск автозапчастей</p>
      </a>
    </div>
    <button type="button" class="button header-catalog-toggle">
      <span>Каталог</span>
    </button>
    <div class="header-search">
      <input type="text" class="header-search-input" placeholder="Поиск..." v-on:keyup.enter="Search" v-model="form.search_string">
      <button type="submit" class="button" @click="Search"></button>
    </div>
    <button type="button" class="header-search-button"></button>
  </header>
</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Header",
  data: function () {
    return {
      is_open: false,
      form:{
        search_string: '',
      }
    }
  },
  computed: {
    /*...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      warehouse_uuid: state => state.Popups.warehouse_uuid
    }),*/
  },
  mounted() {},///api/search/{code}
  methods: {
    ...mapMutations(['SetSlidePopupStatus', 'SetPopupStatus', 'SetPopupName', 'SetLoginStatusTriggerEnd',
    'SetStage','SetSearchData','SetSearch']),
    ...mapActions(['GetCall']),
    Search(e) {
      e.preventDefault();

      this.GetCall({
        endpoint: '/api/search/' + this.form.search_string,
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          this.SetSearchData(data.data);
          this.SetStage(6);
          this.SetSearch(this.form.search_string);
        },
      })
    }
  },
  watch: {
  },
}
</script>

<style scoped>

</style>