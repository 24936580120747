<template>
  <div class="header-catalog">
    <div class="header-catalog-box">
      <ul class="header-catalog-col">
        <li>
          <a href="#">
            <span><img src="img/header-catalog-car.svg" alt="">Автозапчасти</span>
          </a>
        </li>
        <li>
          <a href="#">
            <span><img src="img/header-catalog-bike.svg" alt="">Мотозапчасти</span>
          </a>
        </li>
        <li>
          <a href="#">
            <span><img src="img/header-catalog-boat.svg" alt="">Запчасти для водной техники</span>
          </a>
        </li>
        <li>
          <a href="#">
            <span><img src="img/header-catalog-tractor.svg" alt="">Запчасти для <em>с/х</em> техники</span>
          </a>
        </li>
        <li>
          <a href="#" class="header-catalog-color-1"><img src="img/header-catalog-service.svg" alt="">ТО</a>
        </li>
        <li>
          <a href="#" class="header-catalog-color-2"><img src="img/header-catalog-more.svg" alt="">Еще</a>
        </li>
        <li>
          <a href="#" class="header-catalog-color-3"><img src="img/header-catalog-service-station.svg" alt="">СТО</a>
        </li>
        <li>
          <a href="#" class="header-catalog-color-4"><img src="img/header-catalog-user.svg" alt="">Продавцы</a>
        </li>
      </ul>
      <div class="header-catalog-main">
        <ul>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
          <li>
            <a href="#">Шины и диски</a>
          </li>
          <li>
            <a href="#">Аксесуары</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderCatalog"
}
</script>

<style scoped>

</style>