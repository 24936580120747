<template>
  <div class="popup" data-popup="confirm-phone">
    <div class="popup-box" style="max-width: 514px;">
      <button type="button" class="popup-close" data-popup-close></button>
      <div class="popup-header">Мы выслали Вам<br>SMS с кодом подтвержденя</div>
      <form class="form">
        <fieldset>
          <ul class="one-symbol-input">
            <li>
              <input type="text" class="textfield">
            </li>
            <li>
              <input type="text" class="textfield">
            </li>
            <li>
              <input type="text" class="textfield">
            </li>
            <li>
              <input type="text" class="textfield">
            </li>
          </ul>
        </fieldset>
        <div class="button-row">
          <div class="button-row-box">
            <button type="button" class="button button-middle" data-popup-open="phone-change-success">Подтвердить код</button>
          </div>
          <div class="button-row-box">
            <a href="#">Выслать еще раз<br>(осталось 56 сек)</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PopupConfirmPhone",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd',]),
    ...mapActions(['PostCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },

}
</script>

<style scoped>

</style>