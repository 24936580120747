<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''"
  >
    <div class="sliding-door-header">
      <span>Добавление магазина</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <p class="center">Это нужно для того чтобы, Это нужно для того чтобы, Это нужно для того чтобы, Это нужно для того чтобы, Это нужно
          для того чтобы,</p>
        <form class="form">
          <fieldset>
            <div class="form-field"
                 :class="[form.title.length > 0 ? 'is-filled':'', errored.title ? 'errored':'']">
              <input type="text" class="textfield" v-model="form.title">
              <label>Название магазина</label>
            </div>
          </fieldset>
          <div class="form-row">
            <fieldset>
              <div class="form-field"
                   :class="[form.country.length > 0 ? 'is-filled':'', errored.country ? 'errored':'']">
                <select class="select" id="add-shop-country" v-model="form.country">
                  <option value="UA" selected>Украина</option>
                  <option value="PL">Польша</option>
                  <option value="OAE">ОАЭ</option>
                </select>
                <label>Страна</label>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-field"
                   :class="[form.city.length > 0 ? 'is-filled':'', errored.city ? 'errored':'']">
                <input type="text" class="textfield" v-model="form.city">
                <label>Город</label>
              </div>
            </fieldset>
            <!--            <fieldset>
                          <div class="form-field"
                               :class="[form.days_delivery > 0 ? 'is-filled':'', errored.days_delivery ? 'errored':'']">
                            <input type="number" class="textfield" id="add-warehouse-days" v-model="form.days_delivery">
                            <label>Дней</label>
                          </div>
                        </fieldset>-->
          </div>
          <fieldset>
            <div class="form-field" :class="[form.address.length > 0 ? 'is-filled':'', errored.address ? 'errored':'']">
              <textarea class="textfield textarea-low" v-model="form.address"></textarea>
              <label>Адрес</label>
            </div>
          </fieldset>
          <fieldset>
            <div class="form-field"
                 :class="[form.path.length > 0 ? 'is-filled':'', errored.path ? 'errored':'']">
              <input type="text" class="textfield" v-model="form.path">
              <label>Карта проезда (ссылка на GoogleMaps)</label>
            </div>
          </fieldset>
          <fieldset>
            <div class="upload-file low">
              <span v-if="pic.length === 0">Загрузите фото товара (gif, jpg, png, bmp)</span>
              <span v-else class="file-selected">
                <template v-for="(value, index) in pic" v-bind:key="index">
                  <template v-if="index < 10">{{ value.name }}</template>
                  <template v-if="index < pic.length-1">, </template>
                </template>
              </span>
              <input type="file" class="file-input" @change="FileAdded" accept="image/gif, image/jpeg, image/png, image/bmp" multiple>
            </div>
          </fieldset>
          <div class="section">
            <h3 class="center">Время работы</h3>
            <template v-for="(value, index) in form.workdays" v-bind:key="index">
              <div class="work-time-line form-row">
                <fieldset>
                  <div class="form-field"
                       :class="[form.workdays.length > 0 ? 'is-filled':'', errored.workdays ? 'errored':'']">
                    <select class="select" v-model="form.workdays[index].day1">
                      <template v-for="(value2, index2) in day" v-bind:key="index">
                        <option :value="value2.id" v-if="index2===0" selected>{{ value2.t }}</option>
                        <option :value="value2.id" v-else>{{ value2.t }}</option>
                      </template>
                    </select>
                  </div>
                </fieldset>
                <div class="work-time-delimiter">
                  <div class="work-time-delimiter-inner"></div>
                </div>
                <fieldset>
                  <div class="form-field"
                       :class="[form.workdays.length > 0 ? 'is-filled':'', errored.workdays ? 'errored':'']">
                    <select class="select" v-model="form.workdays[index].day2">
                      <template v-for="(value2, index2) in day" v-bind:key="index">
                        <option :value="value2.id" v-if="index2===0" selected>{{ value2.t }}</option>
                        <option :value="value2.id" v-else>{{ value2.t }}</option>
                      </template>
                    </select>
                  </div>
                </fieldset>
                <fieldset>
                  <div class="form-field"
                       :class="[form.workdays[index].time1 > 0 ? 'is-filled':'', errored.workdays ? 'errored':'']">
                    <input type="text" class="textfield" v-model="form.workdays[index].time1">
                  </div>
                </fieldset>
                <div class="work-time-delimiter">
                  <div class="work-time-delimiter-inner"></div>
                </div>
                <fieldset>
                  <div class="form-field"
                       :class="[form.workdays[index].time2 > 0 ? 'is-filled':'', errored.workdays ? 'errored':'']">
                    <input type="text" class="textfield" v-model="form.workdays[index].time2">
                  </div>
                </fieldset>

                <button type="button" class="work-time-line-clear" @click="RemoveWorkTime(index)"></button>
              </div>
            </template>
          </div>
          <div class="form-bottom">
            <div class="form-bottom-box">
              <button
                  type="button"
                  class="button button-underlined"
                  @click="AddWorkTime"
                  v-if="form.workdays.length<3"
              >Добавить время работы
              </button>
            </div>
            <div class="form-bottom-box">
              <button type="submit" class="button" @click="AddWarehouse">Сохранить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SPAddShop",
  data: function () {
    return {
      is_open: false,
      day: [
        { t: 'Пн', id: 0 },
        { t: 'Вт', id: 1 },
        { t: 'Ср', id: 2 },
        { t: 'Чт', id: 3 },
        { t: 'Пт', id: 4 },
        { t: 'Сб', id: 5 },
        { t: 'Вс', id: 6 },
      ],
      pic: [],
      form: {
        title: '',
        country: '',
        city: '',
        address: '',
        path: '',
        active: true,
        file: null,
        workdays: [
          // { day0: 0, day1: 4, time0: '10:00', time1: '18:00' },
          // { day0: 0, day1: 4, time0: '10:00', time1: '18:00' },
        ],
      },
      errored: {
        title: false,
        country: false,
        city: false,
        address: false,
        path: false,
        active: false,
        file: false,
        workdays: false,
      },
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      popup_edit_mode: state => state.SellerShops.popup_edit_mode,
      popup_edit_data: state => state.SellerShops.popup_edit_data,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
      if (this.popup_edit_mode) {
        let data = this.popup_edit_data;
        this.form.title = (data.name !== undefined && data.name !== null) ? data.name : '';
        this.form.country = (data.country !== undefined && data.country !== null) ? data.country : '';
        this.form.city = (data.city !== undefined && data.city !== null) ? data.city : '';
        this.form.address = (data.address !== undefined && data.address !== null) ? data.address : '';
        this.form.path = (data.map_url !== undefined && data.map_url !== null) ? data.map_url : '';
        this.form.active = (data.hide !== undefined && data.hide !== null) ? !data.hide : false;
        this.form.workdays = (data.worktime !== undefined && data.worktime !== null) ? data.worktime : [];
      }
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus', 'SetSlidePopupName', 'SetLoginStatusTriggerEnd', 'SetReloadFlag']),
    ...mapActions(['PostCall', 'PutCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },
    FileAdded(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        this.pic = files;
        console.log(e);
      }
    },

    AddWorkTime() {
      this.form.workdays.push({ day1: 0, day2: 4, time1: '10:00', time2: '18:00' });
    },

    RemoveWorkTime(index) {
      this.form.workdays.splice(index, 1);
    },

    AddWarehouse(e) {
      e.preventDefault();

      let _file = this.pic;

      const formData = new FormData();
      formData.append("file", _file);
      formData.append("title", this.form.title);
      formData.append("country", this.form.country);
      formData.append("city", this.form.city);
      formData.append("address", this.form.address);
      formData.append("hide", (!this.form.active).toString());
      formData.append("map_url", this.form.path);

      if (this.form.workdays.length > 0) {
        formData.append("day00", this.form.workdays[0].day1);
        formData.append("day01", this.form.workdays[0].day2);
        formData.append("time00", this.form.workdays[0].time1);
        formData.append("time01", this.form.workdays[0].time2);
      }
      if (this.form.workdays.length > 1) {
        formData.append("day10", this.form.workdays[1].day1);
        formData.append("day11", this.form.workdays[1].day2);
        formData.append("time10", this.form.workdays[1].time1);
        formData.append("time11", this.form.workdays[1].time2);
      }
      if (this.form.workdays.length > 2) {
        formData.append("day20", this.form.workdays[2].day1);
        formData.append("day21", this.form.workdays[2].day2);
        formData.append("time20", this.form.workdays[2].time1);
        formData.append("time21", this.form.workdays[2].time2);
      }

      if (!this.popup_edit_data) {
        this.PostCall({
          endpoint: '/api/shops',
          param: formData,
          callback: (data) => {
            this.SetReloadFlag(true);
            this.hide();
          },
          on_error: (error) => {
            let error_status = error.response.status;

            if (error_status === 422) {
              let errors = Object.keys(error.response.data.errors);
              console.log('errors', errors);
              for (let i = 0; i < errors.length; i++) {
                if (this.errored[errors[i]] !== undefined) {
                  this.errored[errors[i]] = true;
                }
              }
            }
          },
        })
      } else {
        this.PostCall({
          endpoint: '/api/shops/' + this.popup_edit_data.uuid,
          param: formData,
          callback: (data) => {
            this.SetReloadFlag(true);
            this.hide();
          },
          on_error: (error) => {
            let error_status = error.response.status;

            if (error_status === 422) {
              let errors = Object.keys(error.response.data.errors);
              console.log('errors', errors);
              for (let i = 0; i < errors.length; i++) {
                if (this.errored[errors[i]] !== undefined) {
                  this.errored[errors[i]] = true;
                }
              }
            }
          },
        })
      }
    }
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>