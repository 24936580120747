import router from '@/router/router.js'

export default {
  state: {
    logout_endpoint: '/auth/logout',
    auth_endpoint: '/auth/login',
    refresh_endpoint: '/api/refresh',
    me_endpoint: '/auth/status',

    token_expires: 3600,
    token_expires_delta: 5,
    token_date: 0,


    is_auth: false,
    trigger_is_auth: false,

    uuid: null,
    usertype: '',
    company: '',
    name: '',
    first_name: '',
    last_name: '',
    mid_name: '',
    email: '',
  },
  mutations: {
    SetUserType(state, type) {
      state.usertype = type;
    },
    SetLoginStatus(state, action) {
      state.is_auth = action;
    },
    SetLoginStatusTrigger(state) {
      state.trigger_is_auth = true;
    },
    SetLoginStatusTriggerEnd(state) {
      state.trigger_is_auth = false;
    },
    SetTokenDate(state, date) {
      state.token_date = date;
    },
    SetTokenExpires(state, seconds) {
      state.token_expires = seconds;
    },
    SetUserData(state, data) {
      state.uuid = data.uuid;
      state.usertype = data.role;
      state.company = data.company;
      state.name = data.name;
      state.first_name = data.first_name;
      state.last_name = data.last_name;
      state.mid_name = data.mid_name;
      state.email = data.email;
    }
  },
  actions: {
    Auth({ dispatch, state }, data) {
      dispatch('PostCall', {
        endpoint: state.auth_endpoint,
        param: data,
        callback: 'Auth_callback',
      }, { root: true })
    },
    Auth_callback({ dispatch, commit, state }, data) {
      // console.log(data);
      /*let l_storage = window.localStorage;
      let now = Date.now();
      l_storage.setItem('token', data.access_token);
      l_storage.setItem('tokendate', now.toString());
      l_storage.setItem('tokenexp', data.expires_in);

      commit('SetTokenDate', now);
      commit('SetTokenExpires', data.expires_in);*/
      console.log(data.auth);
      if(data.auth === true) {
        commit('SetLoginStatus', true);
        // dispatch('GetUserData');
      }


      /*setTimeout(() => {
        dispatch('RefreshAuth');
      }, (state.token_expires - state.token_expires_delta) * 1000)*/
      //dispatch('GetLM', {}, { root: true })
    },
    Logout({ dispatch, state }) {
      dispatch('PostCall', {
        endpoint: state.logout_endpoint,
        callback: 'Logout_callback',
      }, { root: true })
    },
    Logout_callback({ commit }, data) {
      console.log(data);

      let l_storage = window.localStorage;
      l_storage.removeItem('token');

      commit('SetLoginStatus', false);
    },
    /*RefreshAuth({ dispatch, commit, state }) {
      dispatch('PostCall', {
        endpoint: state.refresh_endpoint,
        param: {},
        callback: (data) => {

          let l_storage = window.localStorage;
          let now = Date.now();
          l_storage.setItem('token', data.access_token);
          l_storage.setItem('tokendate', now.toString());
          l_storage.setItem('tokenexp', data.expires_in);

          commit('SetTokenDate', now);
          commit('SetTokenExpires', data.expires_in);
          commit('SetLoginStatus', true);

          setTimeout(() => {
            dispatch('RefreshAuth');
          }, (state.token_expires - state.token_expires_delta) * 1000)
        },
      }, { root: true })
    },*/
    GetUserData({ dispatch, commit, state }) {
      dispatch('GetCall', {
        endpoint: state.me_endpoint,
        callback: (data) => {

          if(data.auth === true){
            commit('SetUserData', data);

            let l_storage = window.localStorage;
            l_storage.setItem('user_type', state.usertype);
          }
        },
      }, { root: true })
    },
    DeleteCredentials({ commit }) {
      console.log('DeleteCredentials');
      router.push({path:'/'});

      commit('SetLoginStatus', false);

      /*let l_storage = window.localStorage;

      l_storage.removeItem('token');
      l_storage.removeItem('tokendate');
      l_storage.removeItem('tokenexp');
      l_storage.removeItem('user_type');*/
    },
  },
  getters: {}
}
