<template>
  <PChangePhone v-if="popup_name==='change_phone'"/>
  <PConfirmPhone v-if="popup_name==='confirm_phone'"/>
  <PPhoneChangeSuccess v-if="popup_name==='phone_change_success'"/>
  <PChangeMail v-if="popup_name==='change_mail'"/>
  <PConfirmMail v-if="popup_name==='confirm_mail'"/>
  <PAddDelivery v-if="popup_name==='add_delivery'"/>
  <PWarehouseRemove v-if="popup_name==='warehouse_remove'"/>
  <PWarehouseRemoveSuccess v-if="popup_name==='warehouse_remove_success'"/>
  <PProductRemove v-if="popup_name==='product_remove'"/>
  <PProductRemoveSuccess v-if="popup_name==='product_remove_success'"/>
  <PAddCommentSuccess v-if="popup_name==='add_comment_success'"/>
  <PListing v-if="popup_name==='listing'"/>
  <POrderCancel v-if="popup_name==='order_cancel'"/>
  <POrderCancelSuccess v-if="popup_name==='order_cancel_success'"/>
  <PSellerShopRemove v-if="popup_name==='sellershop_remove'"/>
</template>

<script>
import PChangePhone from '@/components/common/Popups/PChangePhone.vue'
import PConfirmPhone from '@/components/common/Popups/PConfirmPhone.vue'
import PPhoneChangeSuccess from '@/components/common/Popups/PPhoneChangeSuccess.vue'
import PChangeMail from '@/components/common/Popups/PChangeMail.vue'
import PConfirmMail from '@/components/common/Popups/PConfirmMail.vue'
import PAddDelivery from '@/components/common/Popups/PAddDelivery.vue'
import PWarehouseRemove from '@/components/common/Popups/PWarehouseRemove.vue'
import PWarehouseRemoveSuccess from '@/components/common/Popups/PWarehouseRemoveSuccess.vue'
import PProductRemove from '@/components/common/Popups/PProductRemove.vue'
import PProductRemoveSuccess from '@/components/common/Popups/PProductRemoveSuccess.vue'
import PAddCommentSuccess from '@/components/common/Popups/PAddCommentSuccess.vue'
import PListing from '@/components/common/Popups/PListing.vue'
import POrderCancel from '@/components/common/Popups/POrderCancel.vue'
import POrderCancelSuccess from '@/components/common/Popups/POrderCancelSuccess.vue'
import PSellerShopRemove from "@/components/common/Popups/PSellerShopRemove";

import { mapMutations, mapState } from "vuex";

export default {
  name: "Ps",
  components: {
    PChangePhone,
    PConfirmPhone,
    PPhoneChangeSuccess,
    PChangeMail,
    PConfirmMail,
    PAddDelivery,
    PWarehouseRemove,
    PWarehouseRemoveSuccess,
    PProductRemove,
    PProductRemoveSuccess,
    PAddCommentSuccess,
    PListing,
    POrderCancel,
    POrderCancelSuccess,
    PSellerShopRemove,
  },
  computed: {
    ...mapState({
      popup_name: state => state.Popups.popup_name,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetLoginStatusTrigger',]),
    hide() {
      this.SetLoginStatusTrigger();
    },
  }
}
</script>

<style scoped>

</style>