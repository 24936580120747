<template>
  <section class="content">
    <div class="breadcrumbs"><a href="/" class="breadcrumbs-home"></a><i></i><a href="#">Название выбранного склада</a></div>
    <div class="page-header">
      <div class="page-header-box">
        <h1>{{ status.title }}</h1>
      </div>
      <div class="page-header-box">
        <div class="warehouse-status">
          <div class="warehouse-status-current">Склад активен</div>
          <div class="warehouse-status-update"><span>Обновлен: </span>Вчера в 10:32</div>
        </div>
      </div>
    </div>
    <div class="block-small">
      <ul class="button-line">
        <li>
          <button type="button" class="button button-middle" @click="AddItem">Добавить товары поштучно</button>
        </li>
        <li>
          <button type="button" class="button button-middle" @click="UploadPrice">Загрузить прайс-лист (XLS, CSV)</button>
        </li>
        <li>
          <button type="button" class="button button-middle" @click="AddCrosses">Добавить кроссы</button>
        </li>
        <li>
          <div
              class="dropdown"
              :class="dd_edit_warehouse ? 'is-opened': ''">
            <button type="button" class="button button-middle button-open" @click="OpenEditWarehouse">Настроить склад</button>
            <div class="dropdown-box">
              <ul class="toolbox">
                <li>
                  <button type="button" class="toolbox-edit" @click="EditWarehouse">Редактировать склад</button>
                </li>
                <li>
                  <button type="button" class="toolbox-download">Экспортировать склад</button>
                </li>
                <li>
                  <button type="button" class="toolbox-currency" @click="SetCurrency">Курс валют</button>
                </li>
<!--                <li>-->
<!--                  <button type="button" class="toolbox-clean">Очистка</button>-->
<!--                </li>-->
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="block-small">
      <ul class="warehouse-info">
        <li>
          <div class="warehouse-info-box warehouse-info-products">
            <p>Товаров</p>
            <span>{{ status.total_products }}</span>
          </div>
        </li>
        <li>
          <div class="warehouse-info-box warehouse-info-photo">
            <p>С фото</p>
            <span>{{ status.total_products_photos }}</span>
          </div>
        </li>
        <li>
          <div class="warehouse-info-box warehouse-info-description">
            <p>С описанием</p>
            <span>{{ status.total_products_description }}</span>
          </div>
        </li>
        <li>
          <div class="warehouse-info-box warehouse-info-bill">
            <p>Сумма, {{ status.currency }}</p>
            <span>{{ status.total_summ }}</span>
          </div>
        </li>
        <li>
          <div class="warehouse-info-box warehouse-info-brand">
            <p>Брендов</p>
            <span>{{ status.total_brands }}</span>
          </div>
        </li>
        <li>
          <div class="warehouse-info-box warehouse-info-cross">
            <p>В кроссах</p>
            <span>{{ status.total_products_cross }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="block-small">
      <div class="open-box open-box-special" :class="edit_rules ? 'is-opened':''">
        <div class="open-box-header" @click="OpenEditRules">
          <span>Настройка показа ассортимента и цен</span>
          <div class="button button-small">Изменить</div>
        </div>
        <div class="open-box-content">
          <div class="warehouse-options-header">
            <div class="warehouse-options-header-box">
              <button type="button" class="button button-middle" @click="AddRule">Добавить правило</button>
            </div>
            <div class="warehouse-options-header-box">
              <ul class="toolbox">
                <li>
                  <button type="button" class="toolbox-show">Показать</button>
                </li>
                <li>
                  <button type="button" class="toolbox-hide">Скрыть</button>
                </li>
                <li>
                  <button type="button" class="toolbox-remove">Удалить</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="table-wrapper">
            <table class="table check-group">
              <tr>
                <th>категория</th>
                <th>Производитель</th>
                <th>Диапазон цен</th>
                <th>Скидка PRO</th>
                <th>Наценка</th>
                <th>Применить<br>правило</th>
                <th align="right">
                  <input
                      type="checkbox"
                      class="checkbox checkbox-empty check-all"
                      id="options-all"
                      ref="options_all"
                      @click="SelectRulesAll"
                  >
                  <label for="options-all"></label>
                </th>
              </tr>
              <tr class="table-divider" >
                <td colspan="9"></td>
              </tr>

              <template v-for="(value, index) in rules" v-bind:key="index">
                <warehouse-rules-line :line_info="value"></warehouse-rules-line>
                <tr class="table-divider" v-if="index < rules.length-1">
                  <td colspan="9"></td>
                </tr>
              </template>
            </table>
          </div>
          <div class="warehouse-options-hide">
            <button type="button" @click="OpenEditRules">Свернуть настройки</button>
          </div>
        </div>
      </div>
    </div>
    <div class="block-small">
      <form class="search">
        <div class="search-main">
          <input type="text" class="textfield" placeholder="Поиск...">
          <button type="submit" class="search-go"></button>
        </div>
        <div class="search-fixed">
          <ul class="toolbox">
            <li>
              <button type="button" class="toolbox-show" @click="ShowAll">Показать</button>
            </li>
            <li>
              <button type="button" class="toolbox-hide" @click="HideAll">Скрыть</button>
            </li>
            <li>
              <button type="button" class="toolbox-remove" @click="DeleteAll">Удалить</button>
            </li>
          </ul>
        </div>
      </form>
    </div>
    <div class="block-small">
      <div class="table-wrapper">
        <table class="table warehouse-table check-group">
          <tr class="table-sortable-row">
            <th class="table-sortable">код</th>
            <th class="table-sortable">бренд</th>
            <th class="table-sortable">категория</th>
            <th class="table-sortable">Описание</th>
            <th class="table-sortable">цена для pro,<br>uah</th>
            <th class="table-sortable">цена для покупателей,<br>uah</th>
            <th class="table-sortable">кол-во</th>
            <th class="table-sortable mobile-hidde">б/у</th>
            <th align="right" class="mobile-hidden">
              <input
                  type="checkbox" class="checkbox checkbox-empty check-all" id="item-all"
                  ref="select_all"
                  @click="SelectAll"
              >
              <label for="item-all"></label>
            </th>
          </tr>
          <tr class="table-divider">
            <td colspan="9"></td>
          </tr>

          <template v-for="(value, index) in product_list" v-bind:key="index">
            <WarehouseLine :line_info="value" :index="index"></WarehouseLine>
            <tr class="table-divider" v-if="index < product_list.length-1">
              <td colspan="9"></td>
            </tr>
          </template>
        </table>
      </div>
    </div>
    <div class="page-nav" v-if="meta && meta.current_page < meta.last_page">
      <div class="page-nav-box">
        <a class="button button-middle" @click="LoadNext">Загрузить еще 20</a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import router from "@/router/router";
import WarehouseLine from "@/components/common/Warehouse/WarehouseLine";
import WarehouseRulesLine from "@/components/common/Warehouse/WarehouseRulesLine";

export default {
  name: "Warehouse",
  components: { WarehouseLine, WarehouseRulesLine },
  data: function () {
    return {
      select_block: false,
      dd_edit_warehouse: false,
      edit_rules: false,
      status:{
        total_products: 0,
        total_products_photos: 0,
        total_products_description: 0,
        total_products_cross: 0,
        total_summ: 325658,
        total_brands: 241,

        uuid: "14a6b990-0e53-44c9-a4eb-beafc47b4852",
        type: null,
        title: "склад 1",
        currency: "USD",
        days_delivery: 1,
        active: true,
        text: "фыаыва ываы а",
        seller: {
          id: 1,
          user_id: 13,
          first_name: "вася",
          last_name: "вася",
          mid_name: "вася",
          company: "Васяко",
          created_at: "2021-08-17T09:47:48.000000Z",
          updated_at: "2021-08-17T09:47:48.000000Z"
        },
        part_num: 0,
        created_at: "2021-08-20T11:42:31.000000Z",
        updated_at: "2021-08-20T11:42:31.000000Z"
      },
      product_list:[],
      product_page:0,
      rules:[
        {
          uuid: '',
          position: 1,
          category: 'Накладки на подушки',
          manufacturer: 'Ford',
          price_low: '6000',
          price_high: '68000',
          charge: '10%',
          pro_discount: '5%',
          enabled: true,
        },
      ],
      meta: undefined,
    }
  },
  computed: {
    ...mapState({
      reload_flag: state => state.Main.reload_flag,
      select_top: state => state.Products.select_top,
      select_all_flag: state => state.Products.select_all_flag,
      select_top_rules: state => state.Products.select_top_rules,
      select_all_flag_rules: state => state.Products.select_all_flag_rules,
      refresh_rules: state => state.Products.refresh_rules,
      selected: state => state.Products.selected,
    }),
  },
  mounted() {
    let wuuid = this.$route.query.uuid;
    if(wuuid !== undefined && wuuid.length>2) {
      this.SetWarehouseUUID(wuuid);

      this.SelProdTopToggle(false);
      this.SelProdAllToggleFlag(false);
      this.UnSelProdLineFlag(false);
      this.ClearSelectedProducts();

      this.SelProdRulesTopToggle(false);
      this.SelProdRulesAllToggleFlag(false);
      this.UnSelProdRulesLineFlag(false);
      this.ClearSelectedProductsRules();

      this.GetWarehouseData();
      this.GetProductsData();
      this.GetWarehouseRules();
      // this.GetWarehouseRules();
      window.scrollTo(0, 0);
    }else{
      router.push({ path: '/' });
    }
  },
  methods: {
    ...mapMutations([
        'SetSlidePopupName', 'SetSlidePopupStatus', 'SetWHUuid','SetWarehouseUUID', 'SetProductUUID',
        'SelProdTopToggle','SelProdAllToggleFlag', 'SetReloadFlag','UnSelProdLineFlag', 'ClearSelectedProducts',
        'RefreshRulesSet', 'ProductRulesUUID', 'SelProdRulesTopToggle','SelProdRulesAllToggleFlag',
        'UnSelProdRulesLineFlag', 'ClearSelectedProductsRules',
    ]),
    ...mapActions(['GetCall','PutCall',]),
    GetWarehouseData() {
      this.GetCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid,
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          //console.log(data);
          this.status = data.data;
        },
      })
    },
    GetProductsData() {
      this.GetCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid+'/products',
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          console.log(data);
          this.product_list = data.data;
          this.meta = data.meta;
        },
      })
    },
    GetWarehouseRules() {
      this.GetCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid+'/rules',
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          //console.log(data);
          this.rules = data.data;
        },
      })
    },

    OpenEditWarehouse() {
      this.dd_edit_warehouse = !this.dd_edit_warehouse;
    },
    OpenEditRules() {
      this.edit_rules = !this.edit_rules;
    },
    AddRule() {
      this.SetWHUuid(this.$route.query.uuid);
      this.SetSlidePopupName('add_rule');
      this.SetSlidePopupStatus(true);
    },
    AddItem() {
      this.SetWarehouseUUID(this.$route.query.uuid);
      this.SetSlidePopupName('add_item');
      this.SetSlidePopupStatus(true);
    },
    UploadPrice() {
      this.SetWarehouseUUID(this.$route.query.uuid);
      router.push({ path: '/upload-price' , query:{uuid:this.$route.query.uuid}});
    },
    AddCrosses() {
      this.SetSlidePopupName('add_crosses');
      this.SetSlidePopupStatus(true);
    },
    EditWarehouse() {
      this.SetWarehouseUUID(this.$route.query.uuid);
      this.SetSlidePopupName('edit_warehouse');
      this.SetSlidePopupStatus(true);
    },
    SetCurrency() {
      this.SetWarehouseUUID(this.$route.query.uuid);
      this.SetSlidePopupName('set_currency');
      this.SetSlidePopupStatus(true);
    },

    SelectAll(){
      this.select_block = true;
      this.SelProdTopToggle(!this.select_top);
      this.SelProdAllToggleFlag(this.select_top);
      this.UnSelProdLineFlag(false);
      this.select_block = false;
    },
    SelectRulesAll(){
      this.select_block = true;
      this.SelProdRulesTopToggle(!this.select_top_rules);
      this.SelProdRulesAllToggleFlag(this.select_top_rules);
      this.UnSelProdRulesLineFlag(false);
      this.select_block = false;
    },

    LoadNext(){
      this.GetCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid+'/products?page=' + (this.meta.current_page+1),
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          console.log(data);
          this.product_list = this.product_list.concat(data.data);
          this.meta = data.meta;
        },
      })
    },
    DeleteAll(){
      this.PutCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid+'/products',
        param: {
          delete: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
    HideAll(){
      this.PutCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid+'/products',
        param: {
          hide: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
    ShowAll(){
      this.PutCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid+'/products',
        param: {
          show: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },

    DeleteRulesAll(){
      this.PutCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid+'/rules',
        param: {
          delete: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
    HideRulesAll(){
      this.PutCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid+'/rules',
        param: {
          hide: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
    ShowRulesAll(){
      this.PutCall({
        endpoint: '/api/storehouse/'+this.$route.query.uuid+'/rules',
        param: {
          show: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },

  },
  watch:{
    select_top(newVal) {
      if(!this.select_block) {
        this.$refs.select_all.checked = newVal;
      }
    },
    select_top_rules(newVal) {
      if(!this.select_block) {
        this.$refs.options_all.checked = newVal;
      }
    },
    refresh_rules(newVal){
      if(newVal){
        this.GetWarehouseRules();
        this.RefreshRulesSet(false);
      }
    },
    reload_flag(){
      this.SetReloadFlag(false);
      window.location.reload(true);
    },
  },
}
</script>

<style scoped>

</style>