<template>
  <div class="cart-item">
    <img src="pic/cart-item.jpg" srcset="pic/cart-item.jpg 1x, pic/cart-item@2x.jpg 2x" class="cart-image" alt="">
    <div class="cart-item-info">
      <div class="cart-item-row">
        <div class="cart-item-box">
          <span>Товар</span>
          <p>{{ product.code }}</p>
        </div>
        <div class="cart-item-box">
          <span>Бренд</span>
          <p>{{ product.manufacturer }}</p>
        </div>
        <div class="cart-item-box">
          <span>Описание</span>
          <p>{{ product.description }}</p>
        </div>
        <div class="cart-item-box">
          <span>Отправка</span>
          <p>{{ product.post_time }} дней в {{ product.post_city }}</p>
        </div>
        <div class="cart-item-box">
          <span>Количество</span>
          <div class="cart-item-count">
            <div class="count count-small">
              <input type="text" class="textfield numbers-only" v-model="num">
              <button type="button" class="count-minus" @click="Decrease"></button>
              <button type="button" class="count-plus" @click="Increase"></button>
            </div>
          </div>
        </div>
        <div class="cart-item-box">
          <span>Сумма</span>
          <p>{{ product.sum }}</p>
        </div>
      </div>
    </div>
    <button type="button" class="cart-item-remove"></button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import router from "@/router/router";

export default {
  name: "OrderProduct",
  props: {
    order: Object,
    product: Object,
  },
  data:function (){
    return {
      num:0,
    }
  },
  mounted() {
    this.num = this.product.num;
  },
  methods: {
    ...mapMutations(['SetStage']),
    ...mapActions(['PutCall',]),
    Increase(){
      this.num++;
      this.SendNumber();
    },
    Decrease(){
      this.num--;
      if(this.num < 0){
        this.num = 0;
      }
      this.SendNumber();
    },
    SendNumber(){
      this.PutCall({
        endpoint: '/api/cart/' + this.order.uuid + '/' + this.product.uuid,
        param: {
          // order_uuid: this.order.uuid,
          // product_uuid: this.line.uuid,
          num: this.num,
        },
        callback: (data) => {
          //this.SetCartReloadFlag(true);
        },
      })
    }

  },

}
</script>

<style scoped>

</style>