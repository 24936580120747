<template>
  <tr>
    <td align="center">{{ line_info.category }}</td>
    <td align="center" @click="EditItem">{{ line_info.manufacturer.name }}</td>
    <td align="center">{{ line_info.price_low }} — {{ line_info.price_high }}</td>
    <td align="center">- {{ line_info.pro_discount }} %</td>
    <td align="center">{{ line_info.charge }} %</td>
    <td align="center">
      <button type="button"
              class="table-button table-button-visibility"
              :class="visible ? 'is-visible':''"
              @click="RuleSetVisible">
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
              stroke-width="1.5"/>
          <path
              d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
              stroke-width="1.5"/>
          <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
        </svg>
      </button>
    </td>
    <td>
      <ul class="table-buttons-list align-right">
        <li>
          <button type="button" class="table-button table-button-move"></button>
        </li>
        <li>
          <button type="button" class="table-button table-button-remove" @click="RuleDelete"></button>
        </li>
        <li>
          <input type="checkbox"
                 class="checkbox checkbox-empty"
                 :id="'options-'+line_info.uuid"
                 @click="RuleSelect"
                 ref="select_btn"
          >
          <label :for="'options-'+line_info.uuid"></label>
        </li>
      </ul>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
// import router from "@/router/router";

export default {
  name: "WarehouseRulesLine",
  data: function () {
    return {
      visible: false,
      selected: false,
    }
  },
  props: {
    line_info: Object,
  },
  computed: {
    ...mapState({
      select_top_rules: state => state.Products.select_top_rules,
      select_all_flag_rules: state => state.Products.select_all_flag_rules,
      unselect_line_rules: state => state.Products.unselect_line_rules,
    }),
  },
  mounted() {
    this.visible = this.line_info.enabled;
  },
  methods: {
    ...mapMutations([
      'SetPopupName', 'SetPopupStatus', 'SetWarehouseUUID', 'SetProductUUID', 'SetProductName',
      'ProductRulesUUID', 'SelProdRulesAllToggleFlag', 'SelProdRulesTopToggle', 'UnSelProdRulesLineFlag',
      'SetProductRuleObj'
    ]),
    ...mapActions(['PutCall']),
    EditItem(uuid) {
      this.SetWarehouseUUID(this.$route.query.uuid);
      this.SetProductUUID(uuid);
      this.SetProductRuleObj(this.line_info);
      this.SetSlidePopupName('edit_rule');
      this.SetSlidePopupStatus(true);
    },
    RuleDelete() {
      this.SetWarehouseUUID(this.$route.query.uuid);
      this.SetProductUUID(this.line_info.uuid);
      this.SetProductName(name);
      this.SetPopupName('rule_remove');
      this.SetPopupStatus(true);
    },
    RuleSetVisible() {
      this.visible = !this.visible;

      let form = {
        enabled: !this.visible,
        manufacturer_uuid: this.line_info.manufacturer.uuid,
        price_low: this.line_info.price_low,
        price_high: this.line_info.price_high,
        pro_discount: this.line_info.pro_discount,
        charge: this.line_info.charge,
        // price: this.line_info.price,
      }

      this.PutCall({
        endpoint: '/api/storehouse/' + this.$route.query.uuid + '/rules/' + this.line_info.uuid,
        param: form,
        callback: (data) => {

        },
        on_error: (error) => {
          let error_status = error.response.status;

          if (error_status === 422) {
            let errors = Object.keys(error.response.data.errors);
            console.log('errors', errors);
          }
        },
      })
    },
    RuleSelect() {
      this.UnSelProdRulesLineFlag(true);
      this.selected = !this.selected;
      this.SelProdRulesTopToggle(false);
      this.SelProdRulesAllToggleFlag(false);
      this.ProductRulesUUID(this.line_info.uuid);
      setTimeout(()=>{this.UnSelProdRulesLineFlag(false);},20)
    },
  },
  watch: {
    select_all_flag_rules(newVal) {
      console.log(this.unselect_line_rules);
      if(!this.unselect_line_rules) {
        if ((newVal && !this.selected) || (!newVal && this.selected)) {
          this.$refs.select_btn.checked = this.selected = newVal;
          this.ProductRulesUUID(this.line_info.uuid);
        }
      }
    }
  },
}

</script>

<style scoped>

</style>