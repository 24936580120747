<template>
  <SPReg v-if="slide_popup_name==='reg'"/>
  <SPAddWarehouse v-if="slide_popup_name==='add_warehouse'"/>
  <SPEditWarehouse v-if="slide_popup_name==='edit_warehouse'"/>
  <SPAddItem v-if="slide_popup_name==='add_item'"/>
  <SPEditItem v-if="slide_popup_name==='edit_item'"/>
  <SPExportPrice v-if="slide_popup_name==='export_price'"/>
  <SPAddCrosses v-if="slide_popup_name==='add_crosses'"/>
  <SPSetCurrency v-if="slide_popup_name==='set_currency'"/>
  <SPAddRule v-if="slide_popup_name==='add_rule'"/>
  <SPEditRule v-if="slide_popup_name==='edit_rule'"/>
  <SPAddShop v-if="slide_popup_name==='add_shop'"/>
  <SPShopAddStorehouse v-if="slide_popup_name==='add_shop_storehouse'"/>
  <SPShopAddManager v-if="slide_popup_name==='add_shop_manager'"/>
</template>

<script>
import SPReg from '@/components/common/SlidePopups/SPReg.vue'
import SPAddWarehouse from '@/components/common/SlidePopups/SPAddWarehouse.vue'
import SPEditWarehouse from '@/components/common/SlidePopups/SPEditWarehouse.vue'
import SPAddItem from '@/components/common/SlidePopups/SPAddItem.vue'
import SPEditItem from '@/components/common/SlidePopups/SPEditItem.vue'
import SPExportPrice from '@/components/common/SlidePopups/SPExportPrice.vue'
import SPAddCrosses from '@/components/common/SlidePopups/SPAddCrosses.vue'
import SPSetCurrency from '@/components/common/SlidePopups/SPSetCurrency.vue'
import SPAddRule from '@/components/common/SlidePopups/SPAddRule.vue'
import SPEditRule from '@/components/common/SlidePopups/SPEditRule.vue'
import SPAddShop from "@/components/common/SlidePopups/SPAddShop";
import SPShopAddStorehouse from "@/components/common/SlidePopups/SPShopAddStorehouse";
import SPShopAddManager from "@/components/common/SlidePopups/SPShopAddManager";

import { mapActions, mapMutations, mapState } from "vuex";


export default {
  name: "SlidePopups",
  components:{
    SPReg,
    SPAddWarehouse,
    SPEditWarehouse,
    SPAddItem,
    SPEditItem,
    SPExportPrice,
    SPAddCrosses,
    SPSetCurrency,
    SPAddRule,
    SPEditRule,
    SPAddShop,
    SPShopAddStorehouse,
    SPShopAddManager,
  },
  computed: {
    ...mapState({
      slide_popup_name: state => state.Popups.slide_popup_name,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetLoginStatusTrigger',]),
    hide() {
      this.SetLoginStatusTrigger();
    },
  }
}
</script>

<style scoped>

</style>