<template>
  <div class="slider">
    <carousel :wrapAround="true" :autoplay="2000">
      <slide class="slide" v-for="(slide, index) in slider_data" :key="index">
        <div class="slide-text">
          <span>{{ slide.title }}</span>
          <p>{{ slide.text }}</p>
          <a :href="slide.link" class="button button-middle button-transparent">{{ slide.link_text }}</a>
        </div>
        <div class="slide-image">
          <img :src="slide.img" :srcset="slide.img_set" alt="">
        </div>
      </slide>
      <template #addons>
        <navigation/>
        <!--        <pagination />-->
      </template>
    </carousel>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: "Slider",
  data: function () {
    return {
      slider_data: [
        {
          title: 'Автомобиль на разборку! 0',
          text: 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают',
          link: '#',
          link_text: 'Узнать больше',
          img: 'pic/slide.png',
          img_set: 'pic/slide.png 1x, pic/slide@2x.png 2x'
        },
        {
          title: 'Автомобиль на разборку! 1',
          text: 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают',
          link: '#',
          link_text: 'Узнать больше',
          img: 'pic/slide.png',
          img_set: 'pic/slide.png 1x, pic/slide@2x.png 2x'
        },
      ],
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
}
</script>

<style lang="less">
.slider .slide {
  text-align : left;
}
.carousel__prev, .carousel__next {
  top        : auto;
  left       : auto;
  bottom     : 20px;
  transform  : none;
  background : none;

  width      : 10px;
  height     : 19px;
  flex       : 0 0 auto;
  cursor     : pointer;
  transition : background 0.2s ease;
}
.carousel__prev {
  right             : 45px;
  svg {
    display : none
  }

  background-image  : url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2017%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M8.22%2C17a.76.76%2C0%2C0%2C1-.56-.24L.24%2C9.1a.86.86%2C0%2C0%2C1%2C0-1.2L7.66.24a.78.78%2C0%2C0%2C1%2C1.1%2C0%2C.76.76%2C0%2C0%2C1%2C0%2C1.08l-7%2C7.21%2C7%2C7.21a.76.76%2C0%2C0%2C1%2C0%2C1.08A.8.8%2C0%2C0%2C1%2C8.22%2C17ZM1.38%2C9h0Zm0-.91h0Z%22%2F%3E%3C%2Fsvg%3E);
  background-repeat : no-repeat;
  background-size   : 100% 100%;
  background-color  : transparent;

  &:hover {
    background-image  : url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2017%22%3E%3Cpath%20fill%3D%22%23FEC400%22%20d%3D%22M8.22%2C17a.76.76%2C0%2C0%2C1-.56-.24L.24%2C9.1a.86.86%2C0%2C0%2C1%2C0-1.2L7.66.24a.78.78%2C0%2C0%2C1%2C1.1%2C0%2C.76.76%2C0%2C0%2C1%2C0%2C1.08l-7%2C7.21%2C7%2C7.21a.76.76%2C0%2C0%2C1%2C0%2C1.08A.8.8%2C0%2C0%2C1%2C8.22%2C17ZM1.38%2C9h0Zm0-.91h0Z%22%2F%3E%3C%2Fsvg%3E);
    background-repeat : no-repeat;
    background-size   : 100% 100%;
    background-color  : transparent;
  }
}
.carousel__next {
  right             : 20px;
  svg {
    display : none
  }

  background-image  : url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2017%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M.24%2C16.79a.76.76%2C0%2C0%2C1%2C0-1.08l7-7.21-7-7.21A.76.76%2C0%2C0%2C1%2C.24.21a.78.78%2C0%2C0%2C1%2C1.1%2C0L8.75%2C7.9a.86.86%2C0%2C0%2C1%2C0%2C1.2L1.34%2C16.76A.76.76%2C0%2C0%2C1%2C.78%2C17%2C.8.8%2C0%2C0%2C1%2C.24%2C16.79ZM7.63%2C9h0Zm0-.91h0Z%22%2F%3E%3C%2Fsvg%3E);
  background-repeat : no-repeat;
  background-size   : 100% 100%;
  background-color  : transparent;

  &:hover {
    background-image  : url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%2017%22%3E%3Cpath%20fill%3D%22%23FEC400%22%20d%3D%22M.24%2C16.79a.76.76%2C0%2C0%2C1%2C0-1.08l7-7.21-7-7.21A.76.76%2C0%2C0%2C1%2C.24.21a.78.78%2C0%2C0%2C1%2C1.1%2C0L8.75%2C7.9a.86.86%2C0%2C0%2C1%2C0%2C1.2L1.34%2C16.76A.76.76%2C0%2C0%2C1%2C.78%2C17%2C.8.8%2C0%2C0%2C1%2C.24%2C16.79ZM7.63%2C9h0Zm0-.91h0Z%22%2F%3E%3C%2Fsvg%3E);
    background-repeat : no-repeat;
    background-size   : 100% 100%;
    background-color  : transparent;
  }
}
</style>