<template>
  <div class="popup" data-popup="order-cancel">
    <div class="popup-box" style="max-width: 749px;">
      <button type="button" class="popup-close" data-popup-close></button>
      <div class="popup-reason">
        <div class="popup-header">Пожалуйста, укажите причину</div>
        <form class="form">
          <fieldset>
            <select class="select" id="add-warehouse-type" data-placeholder="Выбрать из списка">
              <option></option>
              <option>Розница</option>
              <option>Опт</option>
            </select>
          </fieldset>
          <fieldset>
            <textarea class="textfield textarea-middle" placeholder="Напишите причину, если её нет в списке..."></textarea>
          </fieldset>
          <div class="form-button">
            <button type="button" class="button button-middle" data-popup-open="order-cancel-succes">Отправить</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PopupOrderCancel",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd',]),
    ...mapActions(['PostCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },

}
</script>

<style scoped>

</style>