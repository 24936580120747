export default {
  state: {
    selected: [],

    select_top: false,
    select_all_flag: false,
    unselect_line: false,

    refresh_rules: false,
    selected_rules: [],
    select_top_rules: false,
    select_all_flag_rules: false,
    unselect_line_rules: false,

    edited_rule_info: {},
  },
  mutations: {
    ProductUUID(state, uuid) {
      let index_of_uuid = state.selected.indexOf(uuid);
      if (index_of_uuid === -1) {
        state.selected.push(uuid);
      } else {
        state.selected.splice(index_of_uuid, 1);
      }
    },
    ClearSelectedProducts(state){
      state.selected = [];
    },

    SelProdTopToggle(state, is_select) {
      state.select_top = is_select;
    },
    SelProdAllToggleFlag(state, status) {
      state.select_all_flag = status;
    },
    UnSelProdLineFlag(state, status) {
      state.unselect_line = status;
    },

    RefreshRulesSet(state, status){
      state.refresh_rules = status;
    },
    ProductRulesUUID(state, uuid) {
      let index_of_uuid = state.selected_rules.indexOf(uuid);
      if (index_of_uuid === -1) {
        state.selected_rules.push(uuid);
      } else {
        state.selected_rules.splice(index_of_uuid, 1);
      }
    },
    ClearSelectedProductsRules(state){
      state.selected_rules = [];
    },
    SelProdRulesTopToggle(state, is_select) {
      state.select_top_rules = is_select;
    },
    SelProdRulesAllToggleFlag(state, status) {
      state.select_all_flag_rules = status;
    },
    UnSelProdRulesLineFlag(state, status) {
      state.unselect_line_rules = status;
    },

    SetProductRuleObj(state, obj){
      state.edited_rule_info = obj;
    }

  },
  actions: {},
  getters: {}
}
