<template>
  <div class="insurance">
    <div class="insurance-text">
      <h2>{{ h2 }}</h2>
      <p>{{ text }}</p>
    </div>
    <a href="link" class="button button-middle button-transparent">Узнать больше</a>
  </div>
</template>

<script>
export default {
  name: "KnowMore",
  props:{
    h2:String,
    text:String,
    link:String,
  }
}
</script>

<style scoped>

</style>