import { createStore } from 'vuex'
import Networking from './modules/Networking'
import Main from './modules/Main'
import User from './modules/User'
import Popups from './modules/Popups'
import PriceUpload from './modules/PriceUpload'
import Search from './modules/Search'
import Order from './modules/Order'
import Warehouses from './modules/Warehouses'
import Products from './modules/Products'
import SellerShops from "./modules/SellerShops";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Networking,
    Main,
    User,
    Popups,
    PriceUpload,
    Search,
    Order,
    Warehouses,
    Products,
    SellerShops,
  },
})
