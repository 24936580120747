<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''"
      data-popup="add-warehouse"
  >
    <div class="sliding-door-header">
      <span>Редактирование менеджера</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <form class="form">
          <fieldset>
            <div class="form-field"
                 :class="[form.name.length > 0 ? 'is-filled':'', errored.title ? 'errored':'']">
              <input type="text" class="textfield" v-model="form.name">
              <label>Имя*</label>
            </div>
          </fieldset>
          <fieldset>
            <template v-for="(value, index) in form.phones" v-bind:key="index">
              <div class="form-row form-row-shop">
                <div class="form-field grow"
                     :class="[value.phone.length > 0 ? 'is-filled':'', errored.phones ? 'errored':'']">
                  <input type="text" class="textfield" v-model="value.phone">
                  <label>Телефон</label>
                  <div class="shop-contact-icon telegram" :class="value.telegram ? 'active':''" @click="ToggleTelegram(index)"></div>
                  <div class="shop-contact-icon viber" :class="value.viber ? 'active':''" @click="ToggleViber(index)"></div>
                  <div class="shop-contact-icon whatsapp" :class="value.whatsapp ? 'active':''" @click="ToggleWhatsapp(index)"></div>
                </div>
                <button type="button" class="shop-manager-line-clear" @click="RemovePhone(index)"></button>
              </div>
            </template>
            <button
                type="button"
                class="button button-underlined"
                @click="AddPhone"
            >Добавить телефон
            </button>
          </fieldset>
          <fieldset>
            <div class="form-field"
                 :class="[form.email.length > 0 ? 'is-filled':'', errored.email ? 'errored':'']">
              <input type="text" class="textfield" v-model="form.email">
              <label>E-mail</label>
            </div>
          </fieldset>
          <fieldset>
            <div class="form-field" :class="[form.text.length > 0 ? 'is-filled':'', errored.text ? 'errored':'']">
              <textarea class="textfield textarea-low" v-model="form.text"></textarea>
              <label>Комментарий</label>
            </div>
          </fieldset>
          <div class="form-bottom">
            <div class="form-bottom-box">
              <button type="submit" class="button" @click="AddManager">Сохранить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SPShopAddManager",
  data: function () {
    return {
      is_open: false,
      form: {
        name: '',
        phones: [],
        email: '',
        text: '',
      },
      errored: {
        name: false,
        phones: false,
        email: false,
        text: false,
      },
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      popup_edit_mode: state => state.SellerShops.popup_edit_mode,
      popup_edit_data: state => state.SellerShops.popup_edit_data,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
      if (this.popup_edit_mode) {
        let data = this.popup_edit_data;
        this.form.name = (data.name !== undefined && data.name !== null) ? data.name : '';
        this.form.phones = (data.phones !== undefined && data.phones !== null) ? Array.from(data.phones) : [];
        this.form.email = (data.email !== undefined && data.email !== null) ? data.email : '';
        this.form.text = (data.text !== undefined && data.text !== null) ? data.text : '';
      }
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus', 'SetSlidePopupName', 'SetLoginStatusTriggerEnd', 'SetReloadFlag']),
    ...mapActions(['PostCall', 'PutCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },
    AddPhone() {
      this.form.phones.push({ phone: '', viber: false, telegram: false, whatsapp: false });
    },
    RemovePhone(index) {
      this.form.phones.splice(index, 1);
    },
    ToggleTelegram(index) {
      this.form.phones[index].telegram = !this.form.phones[index].telegram;
    },
    ToggleViber(index) {
      this.form.phones[index].viber = !this.form.phones[index].viber;
    },
    ToggleWhatsapp(index) {
      this.form.phones[index].whatsapp = !this.form.phones[index].whatsapp;
    },
    AddManager(e) {
      e.preventDefault();

      if (!this.popup_edit_data) {

        this.PostCall({
          endpoint: '/api/shops/' + this.$route.query.uuid + '/managers',
          param: this.form,
          callback: (data) => {
            this.SetReloadFlag(true);
            this.hide();
          },
          on_error: (error) => {
            let error_status = error.response.status;

            if (error_status === 422) {
              let errors = Object.keys(error.response.data.errors);
              console.log('errors', errors);
              for (let i = 0; i < errors.length; i++) {
                if (this.errored[errors[i]] !== undefined) {
                  this.errored[errors[i]] = true;
                }
              }
            }
          },
        })
      } else {
        this.PutCall({
          endpoint: '/api/shops/' + this.$route.query.uuid + '/managers/' + this.popup_edit_data.uuid,
          param: this.form,
          callback: (data) => {
            this.SetReloadFlag(true);
            this.hide();
          },
          on_error: (error) => {
            let error_status = error.response.status;

            if (error_status === 422) {
              let errors = Object.keys(error.response.data.errors);
              console.log('errors', errors);
              for (let i = 0; i < errors.length; i++) {
                if (this.errored[errors[i]] !== undefined) {
                  this.errored[errors[i]] = true;
                }
              }
            }
          },
        })
      }
    }
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>