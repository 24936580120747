<template>
  <section class="content">
    <div class="breadcrumbs"><a href="#" class="breadcrumbs-home"></a><i></i><a href="#">{{ shopData.name }}</a></div>
    <h1>{{ shopData.name }}</h1>
    <section class="section">
      <div class="block">
        <div class="profile">
          <div class="profile-image">
            <img :src="shopData.img" :srcset="shopData.imgSet" class="news-catalog-image" alt="">
            <button type="button" class="button button-small" data-popup-open="edit-profile">Редактировать</button>
            <a href="#" class="profile-create-store">Создать интернет-магазин</a>
          </div>
          <div class="profile-main">
            <div class="profile-box">
              <div class="profile-rating">
                <span>{{ shopData.rate }}</span>
                <p>{{ shopData.feedbacksNum }} отзывов</p>
              </div>
              <a href="#" class="profile-share"><span>Поделиться </span>профилем</a>
            </div>
            <div class="profile-box">
              <table class="profile-stats">
                <tr>
                  <td>5 звезд</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div :style="'width: '+shopData.rateBars.five+'%;'"></div>
                    </div>
                  </td>
                  <td>{{ shopData.rateBars.five }}%</td>
                </tr>
                <tr>
                  <td>4 звезды</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div :style="'width: '+shopData.rateBars.four+'%;'"></div>
                    </div>
                  </td>
                  <td>{{ shopData.rateBars.four }}%</td>
                </tr>
                <tr>
                  <td>3 звезды</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div :style="'width: '+shopData.rateBars.three+'%;'"></div>
                    </div>
                  </td>
                  <td>{{ shopData.rateBars.three }}%</td>
                </tr>
                <tr>
                  <td>2 звезды</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div :style="'width: '+shopData.rateBars.two+'%;'"></div>
                    </div>
                  </td>
                  <td>{{ shopData.rateBars.two }}%</td>
                </tr>
                <tr>
                  <td>1 звезда</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div :style="'width: '+shopData.rateBars.one+'%;'"></div>
                    </div>
                  </td>
                  <td>{{ shopData.rateBars.one }}%</td>
                </tr>
              </table>
            </div>
            <div class="profile-box">
              <ul class="profile-info">
                <li class="profile-period"><span>{{ shopData.useTime }} </span>на сервисе</li>
                <li class="profile-online is-offline"><strong>Был онлайн </strong>{{ shopData.lastViewTime }}</li>
              </ul>
            </div>
            <div class="profile-box">
              <ul class="profile-info">
                <li class="profile-search" v-if="shopData.isFind"><i class="contact-info-success"></i><span>Занимаюсь </span>подбором</li>
                <li class="profile-fav"><i class="profile-button-fav"></i><span>В избранное</span></li>
              </ul>
            </div>
            <div class="profile-box centered">
              <button type="button" class="button button-middle" data-popup-open="edit-profile">Написать продавцу</button>
              <ul class="profile-info">
                <li class="profile-complaint"><i></i><span>Пожаловаться</span></li>
                <li class="profile-block"><i></i><span>Заблокировать</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="tabs">
          <ul class="tabs-nav">
            <li>
              <div :class="activeTab === 0 ? 'is-active':''" @click="activeTab = 0">О магазине</div>
            </li>
            <li>
              <div :class="activeTab === 1 ? 'is-active':''" @click="activeTab = 1">Варианты доставки</div>
            </li>
            <li>
              <div :class="activeTab === 2 ? 'is-active':''" @click="activeTab = 2">Варианты оплаты</div>
            </li>
          </ul>
          <div class="tabs-content">
            <div class="tab" :class="activeTab === 0 ? 'is-active':''">
              <div class="block-small">
                <div class="text">
                  <strong>Дельта Партс это:</strong>
                  <ul>
                    <li>Наличие запчастей на складе</li>
                    <li>Поставка с дистрибьюторов по оптовым ценам</li>
                    <li>оставка запчастей из Польши</li>
                    <li>Поставка запчастей из Германии</li>
                  </ul>
                  <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                    юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного
                    проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные генераторы
                    Lorem Ipsum используют один и тот же текст.</p>
                  <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                    юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного
                    проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные.</p>
                </div>
              </div>
            </div>
            <div class="tab" :class="activeTab === 1 ? 'is-active':''">
              <div class="text">
                <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические
                  вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы
                  наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные генераторы Lorem Ipsum
                  используют один и тот же текст.</p>
              </div>
            </div>
            <div class="tab" :class="activeTab === 2 ? 'is-active':''">
              <div class="block-small">
                <div class="text">
                  <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                    юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного
                    проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <h2>Магазины</h2>
    </section>
    <section class="section">
      <template v-for="(value, index) in shopData.shops" v-bind:key="index">
        <div class="profile-shop open-box" :class="value.show ? 'is-opened':''">
          <div class="open-box-header" @click="value.show = !value.show">{{ value.name }}
            <div class="profile-shop-title-location">Украина, Харьков, ул. Станционная 3</div>
            <div class="profile-shop-contact-status" :class="value.isOpen ? 'is-opened':''">Магазин {{ value.isOpen ? 'открыт':'закрыт' }}</div>
          </div>
          <div class="open-box-content">
            <div class="profile-shop-contact">
              <div class="profile-shop-contact-box">
                <ul class="profile-shop-contact-date">
                  <template v-for="(value2, index2) in value.workTime" v-bind:key="index2">
                    <li>{{ value2 }}</li>
                  </template>
                  <li>
                    <div class="profile-shop-contact-status" :class="value.isOpen ? 'is-opened':''">Магазин {{ value.isOpen ? 'открыт':'закрыт' }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="open-box-content">
            <div class="profile-shop-contact">
              <template v-for="(value2, index2) in value.sections" v-bind:key="index2">
                <div class="profile-shop-contact-box">
                  <ul>
                    <li>
                      <div class="profile-shop-contact-title">{{ value2.name }}</div>
                    </li>
                    <template v-for="(value3, index3) in value2.phone" v-bind:key="index3">
                      <li>
                        <div class="profile-shop-contact-phone">
                          +{{ value3.number }}
                          <div class="profile-shop-contact-icon telegram" v-if="value3.hasTelegram"></div>
                          <div class="profile-shop-contact-icon viber" v-if="value3.hasViber"></div>
                          <div class="profile-shop-contact-icon whatsapp" v-if="value3.hasWatsUpp"></div>
                        </div>
                      </li>
                    </template>
                    <li>
                      <div class="profile-shop-contact-mail">{{ value2.email }}</div>
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </section>
    <template v-for="(value, index) in shopData.infoBoxes" v-bind:key="index">
      <section class="section">
        <h2>{{ value.name }} {{ value.count }}</h2>
      </section>
      <section class="section">
        <div class="profile-shop open-box is-opened">
          <div class="open-box-content">
            <div class="profile-shop-contact">
              <div class="profile-shop-contact-box">
                <ul class="profile-list">
                  <li>Амортизатор задний</li>
                  <li>Амортизатор передний</li>
                  <li>Катушка зажигания</li>
                  <li>Колодки тормозные передние дисковые</li>
                </ul>
              </div>
              <div class="profile-shop-contact-box">
                <ul class="profile-list">
                  <li>Амортизатор задний</li>
                  <li>Амортизатор передний</li>
                  <li>Катушка зажигания</li>
                  <li>Колодки тормозные передние дисковые</li>
                </ul>
              </div>
              <div class="profile-shop-contact-box">
                <ul class="profile-list">
                  <li>Амортизатор задний</li>
                  <li>Амортизатор передний</li>
                  <li>Катушка зажигания</li>
                  <li>Колодки тормозные передние дисковые</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="section">
      <h2>Бренды 24</h2>
    </section>
    <section class="section">
      <div class="profile-shop open-box is-opened">
        <div class="open-box-content">
          <div class="profile-shop-contact">
            <div class="profile-shop-contact-box">
              <ul class="profile-list">
                <li>BMW</li>
                <li>BOSCH</li>
                <li>FEBEST</li>
                <li>FIAT</li>
              </ul>
            </div>
            <div class="profile-shop-contact-box">
              <ul class="profile-list">
                <li>BMW</li>
                <li>BOSCH</li>
                <li>FEBEST</li>
                <li>FIAT</li>
              </ul>
            </div>
            <div class="profile-shop-contact-box">
              <ul class="profile-list">
                <li>BMW</li>
                <li>BOSCH</li>
                <li>FEBEST</li>
                <li>FIAT</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <h2>Отзывы о продавце</h2>
      <h4>Отзывы могут написать только пользователи создавшие заказ</h4>
    </section>
    <section class="block">
      <div class="block-small">
        <div class="comments">
          <div class="comments-stats">
            <div class="flying">
              <div class="flying-box">
                <ul class="comments-stats-tabs">
                  <li class="comments-stats-all">
                    <span>{{ shopData.comments.stats.all }}</span>
                    <p>Всего</p>
                  </li>
                  <li class="comments-stats-completed">
                    <span>{{ shopData.comments.stats.positive }}</span>
                    <p>Положительные</p>
                  </li>
                  <li class="comments-stats-in-progress">
                    <span>{{ shopData.comments.stats.negative }}</span>
                    <p>Отрицательные</p>
                  </li>
                  <li class="comments-stats-rejected">
                    <span>{{ shopData.comments.stats.neutral }}</span>
                    <p>Нейтральные</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="comment positive">
              <div class="comment-aside">
                <img src="pic/comment-avatar-2.jpg" srcset="pic/comment-avatar-2.jpg 1x, pic/comment-avatar-2@2x.jpg 2x"
                     class="comment-avatar" alt="">
                <ul class="comment-options">
                  <li>
                    <a href="#" class="comment-personal">ЛС</a>
                  </li>
                  <li>
                    <a href="#" class="comment-complaint">Жалоба</a>
                  </li>
                </ul>
              </div>
              <div class="comment-main">
                <div class="comment-info">
                  <div class="comment-author">Имя пользователя</div>
                  <div class="comment-date">12 октября 2020 в 19:19</div>
                </div>
                <div class="comment-text">
                  <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                    юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного
                    проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.</p>
                </div>
                <div class="comment">
                  <div class="comment-aside">
                    <img src="pic/comment-avatar-1.jpg" srcset="pic/comment-avatar-1.jpg 1x, pic/comment-avatar-1@2x.jpg 2x"
                         class="comment-avatar" alt="">
                  </div>
                  <div class="comment-main">
                    <div class="comment-info">
                      <div class="comment-author">Название магазина</div>
                      <div class="comment-date">12 октября 2020 в 19:19</div>
                    </div>
                    <ul class="rating">
                      <li>
                        <img src="img/rating-star-full.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star-full.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star-full.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star-half.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star.svg" alt="">
                      </li>
                    </ul>
                    <div class="comment-text">
                      <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                        юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для
                        серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.</p>
                    </div>
                    <button type="button" class="comment-reply">Ответить</button>
                  </div>
                </div>
                <button type="button" class="comment-reply">Ответить</button>
              </div>
            </div>
            <div class="comment negative">
              <div class="comment-aside">
                <img src="pic/comment-avatar-2.jpg" srcset="pic/comment-avatar-2.jpg 1x, pic/comment-avatar-2@2x.jpg 2x"
                     class="comment-avatar" alt="">
                <ul class="comment-options">
                  <li>
                    <a href="#" class="comment-personal">ЛС</a>
                  </li>
                  <li>
                    <a href="#" class="comment-complaint">Жалоба</a>
                  </li>
                </ul>
              </div>
              <div class="comment-main">
                <div class="comment-info">
                  <div class="comment-author">Имя пользователя</div>
                  <div class="comment-date">12 октября 2020 в 19:19</div>
                </div>
                <div class="comment-text">
                  <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                    юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного
                    проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.</p>
                </div>
                <div class="comment">
                  <div class="comment-aside">
                    <img src="pic/comment-avatar-1.jpg" srcset="pic/comment-avatar-1.jpg 1x, pic/comment-avatar-1@2x.jpg 2x"
                         class="comment-avatar" alt="">
                  </div>
                  <div class="comment-main">
                    <div class="comment-info">
                      <div class="comment-author">Название магазина</div>
                      <div class="comment-date">12 октября 2020 в 19:19</div>
                    </div>
                    <ul class="rating">
                      <li>
                        <img src="img/rating-star-full.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star-full.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star-full.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star-half.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star.svg" alt="">
                      </li>
                    </ul>
                    <div class="comment-text">
                      <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                        юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для
                        серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.</p>
                    </div>
                    <button type="button" class="comment-reply">Ответить</button>
                  </div>
                </div>
                <button type="button" class="comment-reply">Ответить</button>
              </div>
            </div>
            <div class="comment">
              <div class="comment-aside">
                <img src="pic/comment-avatar-2.jpg" srcset="pic/comment-avatar-2.jpg 1x, pic/comment-avatar-2@2x.jpg 2x"
                     class="comment-avatar" alt="">
                <ul class="comment-options">
                  <li>
                    <a href="#" class="comment-personal">ЛС</a>
                  </li>
                  <li>
                    <a href="#" class="comment-complaint">Жалоба</a>
                  </li>
                </ul>
              </div>
              <div class="comment-main">
                <div class="comment-info">
                  <div class="comment-author">Имя пользователя</div>
                  <div class="comment-date">12 октября 2020 в 19:19</div>
                </div>
                <div class="comment-text">
                  <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                    юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного
                    проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.</p>
                </div>
                <div class="comment">
                  <div class="comment-aside">
                    <img src="pic/comment-avatar-1.jpg" srcset="pic/comment-avatar-1.jpg 1x, pic/comment-avatar-1@2x.jpg 2x"
                         class="comment-avatar" alt="">
                  </div>
                  <div class="comment-main">
                    <div class="comment-info">
                      <div class="comment-author">Название магазина</div>
                      <div class="comment-date">12 октября 2020 в 19:19</div>
                    </div>
                    <ul class="rating">
                      <li>
                        <img src="img/rating-star-full.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star-full.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star-full.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star-half.svg" alt="">
                      </li>
                      <li>
                        <img src="img/rating-star.svg" alt="">
                      </li>
                    </ul>
                    <div class="comment-text">
                      <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                        юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для
                        серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.</p>
                    </div>
                    <button type="button" class="comment-reply">Ответить</button>
                  </div>
                </div>
                <button type="button" class="comment-reply">Ответить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-nav">
        <div class="page-nav-box">
          <!--          <a href="#" class="button button-middle">Загрузить еще 20</a>-->
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "ProfileSeller",
  data: () => {
    return {
      activeTab: 0,
      shopData:{
        name:'TopService — продажа запчастей',
        img:'pic/news-image.jpg',
        imgSet:'pic/news-image.jpg 1x, pic/news-image@2x.jpg 2x',
        rate:'4,6',
        feedbacksNum:'100500',
        rateBars:{
          one: '50',
          two: '20',
          three: '15',
          four: '10',
          five:'5'
        },
        useTime: '2 года 11 месяцев',
        lastViewTime: '14.09.2020 в 12:39',
        isFind: false,
        shops:[
          {
            show: false,
            name: 'TopService 0',
            address: 'Украина, Харьков, ул. Станционная 3',
            isOpen: false,
            workTime: [
              'Пн-Пт: 10:00–18:00',
              'Сб: 09:00–14:00',
              'Вс: выходной'
            ],
            sections:[
              {
                name: 'Отдел продаж',
                phone: [
                  {
                    number:'380675710701',
                    hasTelegram: false,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: true,
                    hasWatsUpp: true,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: false,
                    hasViber: true,
                    hasWatsUpp: true,
                  },
                ],
                email: 'myemail@gmail.com'
              },
              {
                name: 'Отдел выдачи',
                phone: [
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: false,
                    hasWatsUpp: true,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: false,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                ],
                email: 'myemail@gmail.com'
              },
              {
                name: 'Жалобы и предложения',
                phone: [
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: false,
                    hasWatsUpp: true,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: false,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                ],
                email: 'myemail@gmail.com'
              },
            ]
          },
          {
            show: false,
            name: 'TopService 1',
            address: 'Украина, Харьков, ул. Станционная 13',
            isOpen: true,
            workTime: [
              'Пн-Пт: 10:00–18:00',
              'Сб: 09:00–14:00',
              'Вс: выходной'
            ],
            sections:[
              {
                name: 'Отдел продаж',
                phone: [
                  {
                    number:'380675710701',
                    hasTelegram: false,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: true,
                    hasWatsUpp: true,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: false,
                    hasViber: true,
                    hasWatsUpp: true,
                  },
                ],
                email: 'myemail@gmail.com'
              },
              {
                name: 'Отдел выдачи',
                phone: [
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: false,
                    hasWatsUpp: true,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: false,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                ],
                email: 'myemail@gmail.com'
              },
              {
                name: 'Жалобы и предложения',
                phone: [
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: true,
                    hasViber: false,
                    hasWatsUpp: true,
                  },
                  {
                    number:'380675710701',
                    hasTelegram: false,
                    hasViber: false,
                    hasWatsUpp: false,
                  },
                ],
                email: 'myemail@gmail.com'
              },
            ]
          }
        ],
        infoBoxes:[
          {
            name: 'Товарные группы',
            count: '2300',
            list: [
              {n: 'Амортизатор задний', l: ''},
              {n: 'Амортизатор передний', l: ''},
              {n: 'Катушка зажигания', l: ''},
              {n: 'Колодки тормозные передние дисковые', l: ''},
              {n: 'Амортизатор задний', l: ''},
              {n: 'Амортизатор передний', l: ''},
              {n: 'Катушка зажигания', l: ''},
              {n: 'Колодки тормозные передние дисковые', l: ''},
              {n: 'Амортизатор задний', l: ''},
              {n: 'Амортизатор передний', l: ''},
              {n: 'Катушка зажигания', l: ''},
              {n: 'Колодки тормозные передние дисковые', l: ''},
            ]
          },
          {
            name: 'Бренды',
            count: '24',
            list: [
              {n: 'BMW', l: ''},
              {n: 'BOSCH', l: ''},
              {n: 'FEBEST', l: ''},
              {n: 'FIAT', l: ''},
              {n: 'BMW', l: ''},
              {n: 'BOSCH', l: ''},
              {n: 'FEBEST', l: ''},
              {n: 'FIAT', l: ''},
              {n: 'BMW', l: ''},
              {n: 'BOSCH', l: ''},
              {n: 'FEBEST', l: ''},
              {n: 'FIAT', l: ''},
            ]
          },

        ],
        comments:{
          stats:{
            all: 50,
            positive: 20,
            negative: 20,
            neutral: 10,
          },
          list:[
            {
              type: 'positive',
              img:'pic/comment-avatar-2.jpg',
              imgSet:'pic/comment-avatar-2.jpg 1x, pic/comment-avatar-2@2x.jpg 2x',
              name: 'Имя пользователя',
              date: '12 октября 2020 в 19:19',
              text: 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
              list:[
                {
                  img:'pic/comment-avatar-1.jpg',
                  imgSet:'pic/comment-avatar-1.jpg 1x, pic/comment-avatar-1@2x.jpg 2x',
                  name: 'Имя пользователя2',
                  date: '12 октября 2020 в 19:19',
                  text: 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
                }
              ]
            },
            {
              type: 'negative',
              img:'pic/comment-avatar-2.jpg',
              imgSet:'pic/comment-avatar-2.jpg 1x, pic/comment-avatar-2@2x.jpg 2x',
              name: 'Имя пользователя',
              date: '12 октября 2020 в 19:19',
              text: 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
              list:[
                {
                  img:'pic/comment-avatar-1.jpg',
                  imgSet:'pic/comment-avatar-1.jpg 1x, pic/comment-avatar-1@2x.jpg 2x',
                  name: 'Имя пользователя2',
                  date: '12 октября 2020 в 19:19',
                  text: 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
                }
              ]
            },
            {
              type: 'neutral',
              img:'pic/comment-avatar-2.jpg',
              imgSet:'pic/comment-avatar-2.jpg 1x, pic/comment-avatar-2@2x.jpg 2x',
              name: 'Имя пользователя',
              date: '12 октября 2020 в 19:19',
              text: 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
              list:[
                {
                  img:'pic/comment-avatar-1.jpg',
                  imgSet:'pic/comment-avatar-1.jpg 1x, pic/comment-avatar-1@2x.jpg 2x',
                  name: 'Имя пользователя2',
                  date: '12 октября 2020 в 19:19',
                  text: 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца.',
                }
              ]
            },
          ]
        }
      }
    }
  }
}
</script>

<style scoped>

</style>