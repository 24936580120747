<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''"
      data-popup="add-item">
    <div class="sliding-door-header">
      <span>Редактирование товара</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <p class="center">Измените параметры товара.</p>
        <form class="form">
          <div class="form-row">
            <fieldset>
              <div class="form-field"
                   :class="[form.code.length > 0 ? 'is-filled':'', errored.code ? 'errored':'']">
                <input type="text" class="textfield" v-model="form.code">
                <label>Код</label>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-field"
                   :class="[form.manufacturer.length > 0 ? 'is-filled':'', errored.manufacturer ? 'errored':'']"
                   @focusin="ManufacturerFocus(true)"
                   @focusout="ManufacturerFocus(false)">
                <input
                    class="select"
                    autocomplete="off"
                    id="add-item-manufacturer"
                    v-model="manufacturer">
                <label>Производитель</label>
                <div class="dd_holder" v-if="manufacturer_dd_open">
                  <!--                <div class="dd_holder">-->
                  <template v-for="(value, index) in manufacturer_list_filtered" v-bind:key="index">
                    <div class="dd_list_elem" @click="ManufacturerSelected(value)">{{ value.name }}</div>
                  </template>
                </div>
              </div>
            </fieldset>
          </div>
          <fieldset>
            <div class="form-field"
                 :class="[form.description.length > 0 ? 'is-filled':'', errored.description ? 'errored':'']">
              <textarea class="textfield" v-model="form.description"></textarea>
              <label>Описание (не более 255 символов)</label>
            </div>
          </fieldset>
          <div class="form-row">
            <fieldset>
              <div class="form-field"
                   :class="[form.price > 0 ? 'is-filled':'', errored.price ? 'errored':'']">
                <input type="text" class="textfield" v-model="form.price">
                <label>Цена, USD</label>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-field"
                   :class="[form.count.toString().length > 0 ? 'is-filled':'', errored.count ? 'errored':'']">
                <input class="select" id="add-item-count" data-placeholder v-model="form.count">
                <label>Кол-во, шт</label>
              </div>
            </fieldset>
          </div>
          <fieldset>
            <div class="upload-file">
              <span v-if="pic === null || pic.length === 0">Загрузите фото товара (gif, jpg, png, bmp)</span>
              <span v-else class="file-selected">
                <template v-for="(value, index) in pic" v-bind:key="index">
                  <template v-if="index < 10">{{ value.name }}</template>
                  <template v-if="index < pic.length-1">, </template>
                </template>
              </span>
              <input type="file" class="file-input" @change="FileAdded" accept="image/gif, image/jpeg, image/png, image/bmp" multiple>
            </div>
          </fieldset>
          <div class="form-bottom">
            <div class="form-bottom-box">
              <input type="checkbox" class="checkbox" id="add-item-used" v-model="form.used">
              <label for="add-item-used">Б/у</label>
            </div>
            <div class="form-bottom-box">
              <button type="submit" class="button" @click="Send">Сохранить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SPEditItem",
  data: function () {
    return {
      manufacturer_list:[],
      manufacturer_list_filtered:[],
      manufacturer: '',
      manufacturer_dd_open: false,

      is_open: false,
      pic: [],
      form: {
        code: '',
        original_code: null,
        manufacturer: '',
        original_manufacturer: null,
        description: '',
        price: '',
        count: '',
        file: null,
        used: false,
        hide:false,
        bargain:false,
      },
      errored: {
        code: false,
        original_code: false,
        manufacturer: false,
        original_manufacturer: false,
        description: false,
        price: false,
        count: false,
        file: false,
        used: false,
      },
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      warehouse_uuid: state => state.Popups.warehouse_uuid,
      product_uuid: state => state.Popups.product_uuid
    }),
  },
  mounted() {
    this.GetProductData();
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus', 'SetPopupStatus', 'SetPopupName', 'SetLoginStatusTriggerEnd','SetReloadFlag']),
    ...mapActions(['PutCall','GetCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },

    GetProductData() {
      this.GetCall({
        endpoint: '/api/storehouse/' + this.warehouse_uuid + '/products/' + this.product_uuid,
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          console.log(data);
          //this.product_list = data.data;
          this.form.code = data.data.code ? data.data.code : '';
          this.form.manufacturer = data.data.manufacturer.name ? data.data.manufacturer.name : '';
          this.manufacturer = data.data.manufacturer.name ? data.data.manufacturer.name : '';
          this.form.description = data.data.description ? data.data.description : '';
          this.form.price = data.data.price ? data.data.price : '';
          this.form.count = data.data.number ? data.data.number : '';
          this.form.used = data.data.used ? data.data.used : false;
          this.form.hide = data.data.hide ? data.data.hide : false;
          this.form.bargain = data.data.bargain ? data.data.bargain : false;

          this.GetManufacturerList();
        },
      })
    },

    GetManufacturerList(){
      this.GetCall({
        endpoint: '/api/product-manufacturers',
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          //console.log(data);
          this.manufacturer_list = Array.from(data.data);
          this.manufacturer_list_filtered = Array.from(data.data);
        },
      })
    },
    ManufacturerSelected(value){
      console.log(value);
      this.manufacturer = value.name;
      this.form.manufacturer = value.name;
      // this.manufacturer_dd_open = false;
    },
    ManufacturerFocus(focus){
      if(focus) {
        // console.log(focus);
        this.manufacturer_dd_open = focus;
      }else{
        setTimeout(()=>{this.manufacturer_dd_open = focus},100);
      }
    },


    FileAdded(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        this.pic = files;
        console.log(e);
      }
    },
    Send(e) {
      e.preventDefault();

      let _file = this.pic;

      const formData = new URLSearchParams();
      formData.append("file", _file);
      formData.append("manufacturer", this.form.manufacturer.toLowerCase());
      formData.append("original_manufacturer ", this.form.original_manufacturer);
      formData.append("code", this.form.code);
      formData.append("original_code", this.form.original_code);
      formData.append("description", this.form.description);
      formData.append("price", this.form.price);
      formData.append("used", this.form.used);
      formData.append("number", this.form.count);
      formData.append("hide", this.form.hide);
      formData.append("bargain", this.form.bargain);

      this.PutCall({
        endpoint: '/api/storehouse/' + this.warehouse_uuid + '/products/' + this.product_uuid,
        param: formData,
        callback: (data) => {
          this.SetReloadFlag(true);
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if (error_status === 422) {
            let errors = Object.keys(error.response.data.errors);
            console.log('errors', errors);
            for (let i = 0; i < errors.length; i++) {
              if (this.errored[errors[i]] !== undefined) {
                this.errored[errors[i]] = true;
              }
            }
          }
        },
      })
    }
  },
  watch: {
    manufacturer(newVal){
      if(newVal != '') {
        this.manufacturer_list_filtered = this.manufacturer_list.filter(elem => {
          return elem.name.toLowerCase().includes(newVal.toLowerCase());
        });
      }else{
        this.form.manufacturer = '';
        this.manufacturer_list_filtered = Array.from(this.manufacturer_list);
      }
    },
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>