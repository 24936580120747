export default {
  state: {
    cart_reload_flag:false,

    order_uuid:'',
    order_type:'',
  },
  mutations: {
    SetOrderUuid(state,uuid) {
      state.order_uuid = uuid;
    },
    SetOrderType(state,type) {
      state.order_type = type;
    },
    SetCartReloadFlag(state,reload){
      state.cart_reload_flag = reload;
    }
  },
  actions: {},
  getters: {}
}
