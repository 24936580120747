<template>
  <section class="content">
    <div class="breadcrumbs"><a href="#" class="breadcrumbs-home"></a><i></i><a href="#">Настройки аккаунта</a></div>
    <h1>Настройки аккаунта</h1>
    <section class="section">
      <div class="block">
        <div class="profile">
          <div class="profile-image">
            <img src="pic/news-image.jpg" srcset="pic/news-image.jpg 1x, pic/news-image@2x.jpg 2x" class="news-catalog-image" alt="">
            <button type="button" class="button button-small" data-popup-open="edit-profile">Редактировать</button>
            <a href="#" class="profile-create-store">Создать интернет-магазин</a>
          </div>
          <div class="profile-main">
            <div class="profile-box">
              <div class="profile-rating">
                <span>4,8</span>
                <p>9323 отзывов</p>
              </div>
              <a href="#" class="profile-share"><span>Поделиться </span>профилем</a>
            </div>
            <div class="profile-box">
              <table class="profile-stats">
                <tr>
                  <td>5 звезд</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div style="width: 74%;"></div>
                    </div>
                  </td>
                  <td>74%</td>
                </tr>
                <tr>
                  <td>4 звезды</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div style="width: 10%;"></div>
                    </div>
                  </td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>3 звезды</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div style="width: 3%;"></div>
                    </div>
                  </td>
                  <td>3%</td>
                </tr>
                <tr>
                  <td>2 звезды</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div style="width: 8%;"></div>
                    </div>
                  </td>
                  <td>1%</td>
                </tr>
                <tr>
                  <td>1 звезда</td>
                  <td>
                    <div class="profile-stats-bar">
                      <div style="width: 4%;"></div>
                    </div>
                  </td>
                  <td>4%</td>
                </tr>
              </table>
            </div>
            <div class="profile-box">
              <ul class="profile-info">
                <li class="profile-period"><span>2 года 11 месяцев </span>на сервисе</li>
                <li class="profile-online is-offline"><strong>Был онлайн </strong>14.09.2020 в 12:39</li>
              </ul>
            </div>
            <div class="profile-box profile-box-hide">
              <button type="button" class="button button-middle" data-popup-open="edit-profile">Редактировать</button>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="tabs">
          <ul class="tabs-nav">
            <li>
              <a href="#" class="is-active" data-tab="#tab-1">О магазине</a>
            </li>
            <li>
              <a href="#" data-tab="#tab-2">Варианты доставки</a>
            </li>
            <li>
              <a href="#" data-tab="#tab-3">Варианты оплаты</a>
            </li>
          </ul>
          <div class="tabs-content">
            <div class="tab is-active" id="tab-1">
              <div class="block-small">
                <div class="text">
                  <strong>Дельта Партс это:</strong>
                  <ul>
                    <li>Наличие запчастей на складе</li>
                    <li>Поставка с дистрибьюторов по оптовым ценам</li>
                    <li>оставка запчастей из Польши</li>
                    <li>Поставка запчастей из Германии</li>
                  </ul>
                  <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                    юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного
                    проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные генераторы
                    Lorem Ipsum используют один и тот же текст.</p>
                  <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                    юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного
                    проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные.</p>
                </div>
              </div>
              <div class="block-small">
                <div class="button-row">
                  <div class="button-row-box">
                    <button type="button" class="button button-middle" data-popup-open="edit-profile-tab">Редактировать</button>
                  </div>
                  <div class="button-row-box">
                    <button type="button" class="button button-middle button-transparent" data-popup-open="add-profile-tab">Добавить
                      вкладку
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab" id="tab-2">
              <div class="text">
                <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические
                  вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы
                  наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные генераторы Lorem Ipsum
                  используют один и тот же текст.</p>
              </div>
            </div>
            <div class="tab" id="tab-3">
              <div class="block-small">
                <div class="text">
                  <p>Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например,
                    юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного
                    проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные.</p>
                </div>
              </div>
              <div class="block-small">
                <button type="button" class="button button-middle" data-popup-open="edit-profile-tab">Редактировать</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <h2>Контакты и связь с аккаунтами</h2>
      <div class="contact-info-box">
        <ul class="contact-info">
          <li>
            <a href="#" class="contact-info-phone">Добавить телефон</a>
          </li>
          <li>
            <span class="contact-info-mail">myavtoemail@gmail.com<i class="contact-info-success"></i></span>
          </li>
          <li>
            <a href="#" class="contact-info-fb">Привязать аккаунт Facebook</a>
          </li>
          <li>
            <span class="contact-info-google">myemail38@gmail.com<i class="contact-info-warning"></i></span>
          </li>
        </ul>
      </div>
    </section>
    <section class="section">
      <div class="open-box is-opened">
        <div class="open-box-header">Способы приема платежей</div>
        <div class="open-box-content">
          <ul class="trigger-list">
            <li class="is-checked">
              <div class="trigger-list-box">
                <input type="radio" class="trigger" name="t-1" id="t-1-1" checked>
                <label for="t-1-1"></label>
              </div>
              <div class="trigger-list-info">
                <strong>Оплата при получении</strong>
                <span>Покупатель оплачивает товар после получения: на почте (наложенный платеж) или при самовывозе.</span>
              </div>
            </li>
            <li>
              <div class="trigger-list-box">
                <input type="radio" class="trigger" name="t-1" id="t-1-2">
                <label for="t-1-2"></label>
              </div>
              <div class="trigger-list-info">
                <strong>Предоплата</strong>
                <span>Для активации предоплаты пройдите <a href="#">подтверждения данных продавца</a>.<br><a href="#">Узнайте подробнее</a> о преимуществах безопасной торговли с предоплатой.</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="open-box is-opened">
        <div class="open-box-header">Способ доставки</div>
        <div class="open-box-content">
          <div class="block-small">
            <ul class="trigger-list">
              <li class="is-checked">
                <div class="trigger-list-box">
                  <input type="radio" class="trigger" name="t-2-1" id="t-2-1-1" checked>
                  <label for="t-2-1-1"></label>
                </div>
                <div class="trigger-list-info">
                  <strong>Новая Почта</strong>
                  <span>Один из самых распространенных способов. Рекомендуем включить его, даже если у вас настроен прием предоплаты другим способом.</span>
                </div>
              </li>
              <li>
                <div class="trigger-list-box">
                  <input type="radio" class="trigger" name="t-2-1" id="t-2-1-2">
                  <label for="t-2-1-2"></label>
                </div>
                <div class="trigger-list-info">
                  <strong>AutoLux</strong>
                </div>
              </li>
              <li>
                <div class="trigger-list-box">
                  <input type="radio" class="trigger" name="t-2-1" id="t-2-1-3">
                  <label for="t-2-1-3"></label>
                </div>
                <div class="trigger-list-info">
                  <strong>Gunsel</strong>
                </div>
              </li>
              <li>
                <div class="trigger-list-box">
                  <input type="radio" class="trigger" name="t-2-1" id="t-2-1-4">
                  <label for="t-2-1-4"></label>
                </div>
                <div class="trigger-list-info">
                  <strong>MeestExpress</strong>
                </div>
              </li>
              <li>
                <div class="trigger-list-box">
                  <input type="radio" class="trigger" name="t-2-1" id="t-2-1-5">
                  <label for="t-2-1-5"></label>
                </div>
                <div class="trigger-list-info">
                  <strong>Delivery</strong>
                </div>
              </li>
              <li>
                <div class="trigger-list-box">
                  <input type="radio" class="trigger" name="t-2-1" id="t-2-1-6">
                  <label for="t-2-1-6"></label>
                </div>
                <div class="trigger-list-info">
                  <strong>Ukrposhta</strong>
                </div>
              </li>
              <li class="is-checked">
                <div class="trigger-list-box">
                  <input type="radio" class="trigger" name="t-2-2" id="t-2-2-1" checked>
                  <label for="t-2-2-1"></label>
                </div>
                <div class="trigger-list-info">
                  <strong>Самовывоз</strong>
                  <span>Самовывоз товара из магазина продавца.</span>
                </div>
              </li>
              <li>
                <div class="trigger-list-box">
                  <input type="radio" class="trigger" name="t-2-2" id="t-2-2-2">
                  <label for="t-2-2-2"></label>
                </div>
                <div class="trigger-list-info">
                  <strong>Адресная доставка</strong>
                  <span>Покупатель указывает свой полный адрес.</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="block-small">
            <button type="button" class="button button-middle" data-popup-open="add-delivery">Добавить способ</button>
          </div>
        </div>
      </div>
      <div class="open-box is-opened">
        <div class="open-box-header">Дополнительные настройки</div>
        <div class="open-box-content">
          <ul class="trigger-list">
            <li class="is-checked">
              <div class="trigger-list-box">
                <input type="checkbox" class="trigger" id="t-3-1" checked>
                <label for="t-3-1"></label>
              </div>
              <div class="trigger-list-info">
                <strong>Автоматическое подтверждение гарантированного наличия</strong>
                <span>Автоматическое подтверждение заказов, если они содержат только позиции со склада «Склад гарантированного наличия».</span>
              </div>
            </li>
            <li>
              <div class="trigger-list-box">
                <input type="checkbox" class="trigger" id="t-3-2">
                <label for="t-3-2"></label>
              </div>
              <div class="trigger-list-info">
                <strong>Доплата за оформление заказа</strong>
                <span>Для заказов меньше минимальной суммы, вы можете указать дополнительную плату к обработке заказа.</span>
                <ul class="i-dots-list">
                  <li>
                    <span>Минимальная сумма заказа</span>
                    <span>......................</span>
                    <span>0 UAH</span>
                  </li>
                  <li>
                    <span>Наценка на минимальный заказ</span>
                    <span>..............</span>
                    <span>0 UAH</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="open-box is-opened">
        <div class="open-box-header">Уведомления о событиях</div>
        <div class="open-box-content">
          <div class="block-small">
            <h3>Контакты для уведомлений</h3>
            <ul class="contact-info">
              <li>
                <div class="contact-info-line">
                  <div class="contact-info-line-box">
                    <span class="contact-info-phone">+380638461020<i class="contact-info-success"></i></span>
                  </div>
                  <div class="contact-info-line-box">
                    <a href="javascript:void(0);" data-popup-open="change-phone">изменить</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="contact-info-line">
                  <div class="contact-info-line-box">
                    <span class="contact-info-mail">myavtoemail@gmail.com<i class="contact-info-warning"></i></span>
                  </div>
                  <div class="contact-info-line-box">
                    <a href="javascript:void(0);" data-popup-open="confirm-mail">подтвердить</a>
                  </div>
                  <div class="contact-info-line-box">
                    <a href="javascript:void(0);" data-popup-open="change-mail">изменить</a>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="trigger-list">
              <li>
                <div class="trigger-list-box">
                  <input type="checkbox" class="trigger" id="t-4">
                  <label for="t-4"></label>
                </div>
                <div class="trigger-list-info">
                  <strong>SMS уведомления заблокированы</strong>
                  <span>Плата за каждое сообщение (0.01$) будет списываться с вашего счёта сервиса.<br>Если денег на счете недостаточно, SMS уведомления будут отключены.</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="block-small">
            <table class="table">
              <tr>
                <th align="left">Событие</th>
                <th width="132">кабинет</th>
                <th width="132">e-mail</th>
                <th width="132">Viber/SMS</th>
              </tr>
              <tr class="table-divider">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td colspan="4" class="table-title">Финансы</td>
              </tr>
              <tr class="table-divider">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td>Закончились деньги</td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-1-1" checked>
                  <label for="item-1-1"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-1-2" checked>
                  <label for="item-1-2"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-1-3" checked>
                  <label for="item-1-3"></label>
                </td>
              </tr>
              <tr class="table-divider">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td>Закончились деньги</td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-2-1">
                  <label for="item-2-1"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-2-2" checked>
                  <label for="item-2-2"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-2-3" checked>
                  <label for="item-2-3"></label>
                </td>
              </tr>
              <tr class="table-divider">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td>Закончились деньги</td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-3-1">
                  <label for="item-3-1"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-3-2" checked>
                  <label for="item-3-2"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-3-3" checked>
                  <label for="item-3-3"></label>
                </td>
              </tr>
              <tr class="table-divider">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td colspan="4" class="table-title">Репутация</td>
              </tr>
              <tr class="table-divider">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td>Закончились деньги</td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-4-1">
                  <label for="item-4-1"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-4-2">
                  <label for="item-4-2"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-4-3" checked>
                  <label for="item-4-3"></label>
                </td>
              </tr>
              <tr class="table-divider">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td>Закончились деньги</td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-5-1">
                  <label for="item-5-1"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-5-2" checked>
                  <label for="item-5-2"></label>
                </td>
                <td align="center">
                  <input type="checkbox" class="checkbox checkbox-empty" id="item-5-3" checked>
                  <label for="item-5-3"></label>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "SellerSettings",
}
</script>

<style scoped>

</style>