export default {
  state: {
    selected: [],

    select_top: false,
    select_all_flag: false,
    unselect_line: false,
  },
  mutations: {
    WarehouseUUID(state, uuid) {
      let index_of_uuid = state.selected.indexOf(uuid);
      if (index_of_uuid === -1) {
        state.selected.push(uuid);
      } else {
        state.selected.splice(index_of_uuid, 1);
      }
    },
    ClearSelectedWarehouses(state){
      state.selected = [];
    },

    SelectTopToggle(state, is_select) {
      state.select_top = is_select;
    },
    SelectAllToggleFlag(state, status) {
      state.select_all_flag = status;
    },
    UnselectLineFlag(state, status) {
      state.unselect_line = status;
    },
  },
  actions: {},
  getters: {}
}
