<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''"
      data-popup="reg"
  >
    <div class="sliding-door-header">
      <span>Регистрация</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <div class="reg">
          <div class="section">
            <div class="reg-type">
              <span>Тип аккаунта</span>
              <ul>
                <li>
                  <input
                      value="buyer"
                      v-model="form.type"
                      type="radio" class="radio" name="reg-type" id="reg-type-buyer" checked>
                  <label for="reg-type-buyer">Покупатель</label>
                </li>
                <li>
                  <input value="seller" v-model="form.type" type="radio" class="radio" name="reg-type" id="reg-type-seller">
                  <label for="reg-type-seller">Продавец</label>
                </li>
              </ul>
            </div>
            <p class="center">Короткий текст о том, кто такой Покупатель и в чем отличия от Продавца</p>
            <form class="form">
              <div class="form-row">
                <fieldset>
                  <div class="form-field"
                       :class="[form.last_name.length > 0 ? 'is-filled':'', errored.last_name ? 'errored':'']">
                    <input v-model="form.last_name" type="text" class="textfield" id="reg-last_name">
                    <label for="reg-last_name">Фамилия*</label>
                  </div>
                </fieldset>
                <fieldset>
                  <div class="form-field"
                       :class="[form.first_name.length > 0 ? 'is-filled':'',errored.first_name ? 'errored':'']">
                    <input v-model="form.first_name" type="text" class="textfield" id="reg-first_name">
                    <label for="reg-first_name">Имя*</label>
                  </div>
                </fieldset>
                <fieldset>
                  <div class="form-field"
                       :class="form.mid_name.length > 0 ? 'is-filled':''">
                    <input v-model="form.mid_name" type="text" class="textfield" id="reg-middle_name">
                    <label for="reg-middle_name">Отчество</label>
                  </div>
                </fieldset>
              </div>
              <fieldset v-if="form.type==='seller'">
                <div class="form-field"
                     :class="[form.company.length > 0 ? 'is-filled':'',errored.company ? 'errored':'']">
                  <input v-model="form.company" type="text" class="textfield" id="reg-company">
                  <label for="reg-company">Название компании*</label>
                </div>
              </fieldset>
              <fieldset>
                <div class="form-field"
                     :class="[form.email.length > 0 ? 'is-filled':'',errored.email ? 'errored':'']">
                  <input v-model="form.email" type="text" class="textfield" id="reg-email">
                  <label for="reg-email">E-mail*</label>
                </div>
              </fieldset>
              <fieldset>
                <div class="form-field"
                     :class="[form.password.length > 0 ? 'is-filled':'',errored.password ? 'errored':'']">
                  <input v-model="form.password" type="password" class="textfield" id="reg-password">
                  <label for="reg-password">Пароль*</label>
                </div>
              </fieldset>
              <fieldset>
                <div class="form-field"
                     :class="[form.password_confirmation.length > 0 ? 'is-filled':'',errored.password_confirmation ? 'errored':'']">
                  <input v-model="form.password_confirmation" type="password" class="textfield" id="reg-password_confirmation">
                  <label for="reg-password_confirmation">Повторите пароль*</label>
                </div>
              </fieldset>
              <div class="form-bottom">
                <div class="form-bottom-box" :class="errored.reg_agree ? 'errored':''">
                  <input v-model="reg_agree" type="checkbox" class="checkbox" id="reg-agree" checked>
                  <label for="reg-agree">Я принимаю <a href="#">соглашение пользователя</a></label>
                </div>
                <div class="form-bottom-box">
                  <button class="button" @click="Register">Зарегистрироваться</button>
                </div>
              </div>
            </form>
          </div>
          <div class="section">
            <h3>Зарегистрироваться как пользователь</h3>
            <ul class="social">
              <li>
                <a href="#" class="social-fb"></a>
              </li>
              <li>
                <a href="#" class="social-google"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SlidePopupReg",
  data: function () {
    return {
      is_open: false,
      reg_agree: false,
      form: {
        first_name: '',
        last_name: '',
        mid_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        company: '',
        type: 'buyer'
      },
      errored: {
        reg_agree: false,
        first_name: false,
        last_name: false,
        mid_name: false,
        email: false,
        password: false,
        password_confirmation: false,
        company: false,
      }
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      token_expires: state => state.User.token_expires,
      token_expires_delta: state => state.User.token_expires_delta,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations([
        'SetSlidePopupStatus','SetPopupStatus','SetPopupName', 'SetLoginStatusTriggerEnd',
        'SetTokenDate','SetTokenExpires','SetLoginStatus'
    ]),
    ...mapActions(['PostCall','GetUserData','RefreshAuth']),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },
    Register(e) {
      e.preventDefault();

      let endpoint = '/auth/signin';

      if (this.reg_agree) {
        this.PostCall({
          endpoint: endpoint,
          param: this.form,
          callback: (data) => {
            this.SetSlidePopupStatus(false);
            // this.SetPopupName('confirm_mail');
            // this.SetPopupStatus(true);


            /*let l_storage = window.localStorage;
            let now = Date.now();
            l_storage.setItem('token', data.access_token);
            l_storage.setItem('tokendate', now.toString());
            l_storage.setItem('tokenexp', data.expires_in);

            this.SetTokenDate(now);
            this.SetTokenExpires(data.expires_in);*/
            if(data.auth === true) {
              this.SetLoginStatus(true);
              // this.GetUserData();
            }


            /*setTimeout(() => {
              this.RefreshAuth();
            }, (this.token_expires - this.token_expires_delta) * 1000)*/


          },
          on_error: (error) => {
            let error_status = error.response.status;

            if(error_status === 422){
              let errors = Object.keys(error.response.data.errors);
              console.log('errors',errors);
              for (let i = 0; i < errors.length; i++) {
                if(this.errored[errors[i]] !== undefined){
                  this.errored[errors[i]] = true;
                }
              }
            }
          },
        })
      }else{
        this.errored.reg_agree = true;
      }
    }
  },
  watch: {
    form: {
      handler(newVal,oldVal) {
        if(newVal.first_name !== oldVal.first_name){
          this.errored.first_name = false;
        }
        if(newVal.last_name !== oldVal.last_name){
          this.errored.last_name = false;
        }
        if(newVal.mid_name !== oldVal.mid_name){
          this.errored.mid_name = false;
        }
        if(newVal.email !== oldVal.email){
          this.errored.email = false;
        }
        if(newVal.password !== oldVal.password){
          this.errored.password = false;
        }
        if(newVal.password !== oldVal.password){
          this.errored.password = false;
        }
        if(newVal.password_confirmation !== oldVal.password_confirmation){
          this.errored.password_confirmation = false;
        }
        if(newVal.company !== oldVal.company){
          this.errored.company = false;
        }
      },
      deep: true
    },
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>