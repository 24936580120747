<template>
  <div class="cart-item">
    <img src="pic/cart-item.jpg" srcset="pic/cart-item.jpg 1x, pic/cart-item@2x.jpg 2x" class="cart-image" alt="">
    <div class="cart-item-info">
      <div class="cart-item-row">
        <div class="cart-item-box">
          <span>Товар</span>
          <p>{{ line.code }}</p>
        </div>
        <div class="cart-item-box">
          <span>Бренд</span>
          <p>{{ line.manufacturer }}</p>
        </div>
        <div class="cart-item-box">
          <span>Описание</span>
          <p>{{ line.description }}</p>
        </div>
        <div class="cart-item-box">
          <span>Отправка</span>
          <p>{{ line.post_time }} дней в {{ line.post_city }}</p>
        </div>
        <div class="cart-item-box">
          <span>Количество</span>
          <div class="cart-item-count">
            <div class="count count-small">
              <input type="text" class="textfield numbers-only" v-model="num">
              <button type="button" class="count-minus" @click="Decrease"></button>
              <button type="button" class="count-plus" @click="Increase"></button>
            </div>
            <span>Доступно: 1</span>
          </div>
        </div>
        <div class="cart-item-box">
          <span>Сумма</span>
          <p>{{ sum }}</p>
        </div>
      </div>
    </div>
    <button type="button" class="cart-item-remove" @click="RemoveFromCart"></button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "CartLine",
  props: {
    line: Object,
    order: Object,
  },
  data: function () {
    return {
      num:0,
      sum:0,
    }
  },
  mounted() {
    this.num = this.line.num;
  },
  methods: {
    ...mapMutations(['SetCartReloadFlag',]),
    ...mapActions(['PutCall', 'DeleteCall']),
    RemoveFromCart() {
      this.DeleteCall({
        endpoint: '/api/cart/' + this.order.uuid + '/' + this.line.uuid,
        param: '',
        callback: (data) => {
          this.SetCartReloadFlag(true);
        },
      })
    },
    Increase(){
      this.num++;
      this.SendNumber();
    },
    Decrease(){
      this.num--;
      if(this.num < 0){
        this.num = 0;
      }
      this.SendNumber();
    },
    SendNumber(){
      this.PutCall({
        endpoint: '/api/cart/' + this.order.uuid + '/' + this.line.uuid,
        param: {
          // order_uuid: this.order.uuid,
          // product_uuid: this.line.uuid,
          num: this.num,
        },
        callback: (data) => {
          this.SetCartReloadFlag(true);
        },
      })
    }
  },
  watch: {
    num(newVal) {
      this.sum = Math.round(newVal*this.line.sum*100)/100;
    }
  },

}
</script>

<style scoped>

</style>