<template>
  <div class="popup"
       :class="is_open ? 'is-opened' : ''"
       data-popup="warehouse-remove-success">
    <div class="popup-box" style="max-width: 470px;">
      <button
          type="button"
          class="popup-close"
          data-popup-close
          @click="hide"
      ></button>
      <div class="popup-success">
        <div class="popup-header">Склад удалён</div>
        <button
            type="button"
            class="button button-middle"
            data-popup-close
            @click="hide"
        >Всё понятно</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PWarehouseRemoveSuccess",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd',]),
    ...mapActions(['PostCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },

}
</script>

<style scoped>

</style>