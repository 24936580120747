<template>
  <section class="content">
    <div class="breadcrumbs"><a href="/" class="breadcrumbs-home"></a><i></i>Магазины<i></i>Название магазина</div>
    <h1>{{ shopData.name }}</h1>
    <div class="block">
      <div class="shop_edit">
        <div class="shop-image">
          <img :src="shopData.img" :srcset="shopData.imgSet" class="news-catalog-image" alt="">
        </div>
        <div class="shop-main">
          <div class="shop-box-box">
            <div class="shop-location">{{ shopData.country }}, {{ shopData.city }}, {{ shopData.address }} <a :href="shopData.map_url" target="_blank">открыть карту</a></div>
            <ul class="shop-contact-date">
              <template v-for="(value, index) in shopData.worktime" v-bind:key="index">
                <li>
                  <template v-if="value.day1 !== value.day2">
                    {{ day[day.map(e=>{return e.id}).indexOf(value.day1)].t }}-{{ day[day.map(e=>{return e.id}).indexOf(value.day2)].t }}:
                    {{ value.time1 }}-{{ value.time2 }}
                  </template>
                  <template v-if="value.day1 === value.day2">
                    {{ day[day.map(e=>{return e.id}).indexOf(value.day1)].t }}:
                    <template v-if="value.time1 !== value.time2">
                      {{ value.time1 }}-{{ value.time2 }}
                    </template>
                    <template v-else>
                      Выходной
                    </template>
                  </template>
                </li>
              </template>
            </ul>
            <div class="shop-contact-status" :class="isOpen ? 'is-opened':''">Магазин {{ isOpen ? 'открыт' : 'закрыт' }}</div>
          </div>
          <div class="shop-box-box centered">
            <div
                class="dropdown"
                :class="dd_edit_shop ? 'is-opened': ''">
              <button type="button" class="button button-middle button-open" @click="OpenEditShop">Настроить магазин</button>
              <div class="dropdown-box">
                <ul class="toolbox">
                  <li>
                    <button type="button" class="toolbox-show">Магазин активен</button>
                  </li>
                  <li>
                    <button type="button" class="toolbox-main">Сделать главным</button>
                  </li>
                  <li>
                    <button type="button" class="toolbox-edit" @click="EditShop">Редактировать</button>
                  </li>
                  <li>
                    <button type="button" class="toolbox-remove" @click="DeleteShop">Удалить</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="block-small shop-block">
        <h2>Подключенные склады</h2>
        <ul class="toolbox">
          <li>
            <button type="button" class="toolbox-plus" @click="AddStorehouse">Подключить склад</button>
          </li>
        </ul>
      </div>
    </section>
    <section class="section slave">
      <div class="table-wrapper">
        <table class="table warehouse-table check-group">
          <!--          header       -->
          <thead>
          <tr>
            <th class="table-sortable">Название</th>
            <th class="table-sortable">валюта</th>
            <th class="table-sortable">Запчасти, шт</th>
            <th class="table-sortable">доставка, дн</th>
            <th class="table-sortable">изменен</th>
            <th class="table-sortable">Видимость</th>
            <th align="right"></th>
          </tr>
          </thead>
          <tbody>
          <tr class="table-divider">
            <td colspan="8"></td>
          </tr>
          </tbody>
          <!--          header       -->
          <template v-if="shopData.storehouses.length > 0">
            <template v-for="(value, index) in shopData.storehouses" v-bind:key="index">
              <SellerShopLine :line_info="value"/>
            </template>

          </template>
        </table>
      </div>
    </section>
    <section class="section">
      <div class="block-small shop-block">
        <h2>Менеджеры</h2>
        <ul class="toolbox">
          <li>
            <button type="button" class="toolbox-plus" @click="AddManager">Добавить менеджера</button>
          </li>
        </ul>
      </div>
    </section>
    <section class="section slave">
      <template v-for="(value, index) in shopData.managers" v-bind:key="index">
        <div class="profile-shop open-box is-opened static">
          <div class="open-box-header" @click="value.show = !value.show">
            {{ value.name }}
            <div class="shop-manager-buttons">
              <button class="button-edit" @click="ManagerEdit(value)"></button>
              <button class="button-delete" @click="ManagerDelete(value)"></button>
            </div>
          </div>
          <div class="open-box-content">
            <div class="profile-shop-contact">
              <div class="profile-shop-contact-box">
                <ul>
                  <template v-for="(value2, index2) in value.phones" v-bind:key="index2">
                    <li>
                      <div class="profile-shop-contact-phone">
                        {{ value2.phone }}
                        <div class="profile-shop-contact-icon telegram" v-if="value2.telegram"></div>
                        <div class="profile-shop-contact-icon viber" v-if="value2.viber"></div>
                        <div class="profile-shop-contact-icon whatsapp" v-if="value2.whatsapp"></div>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
              <div class="profile-shop-contact-box">
                <ul>
                  <li>
                    <div class="profile-shop-contact-mail">{{ value.email }}</div>
                  </li>
                </ul>
              </div>
              <div class="profile-shop-contact-box">
                <ul>
                  <li>
                    <div class="">{{ value.text }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </section>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SellerShopLine from "@/components/common/SellerShop/SellerShopLine";

export default {
  name: "SellerShop",
  components: {
    SellerShopLine,
  },
  computed: {
    ...mapState({
      reload_flag: state => state.Main.reload_flag,
    }),
  },
  data: () => {
    return {
      dd_edit_shop: false,
      isOpen: true,
      day: [
        { t: 'Пн', id: 0 },
        { t: 'Вт', id: 1 },
        { t: 'Ср', id: 2 },
        { t: 'Чт', id: 3 },
        { t: 'Пт', id: 4 },
        { t: 'Сб', id: 5 },
        { t: 'Вс', id: 6 },
      ],
      shopData: {
        uuid:'',
        address: 'улица Троицкая (бывш. Кирова) 244 (авторынок)',
        country: 'Украина',
        city:'Николаев',
        worktime: [],
        map_url: 'https://google.com',
        name: 'TopService — продажа запчастей',
        img: 'pic/news-image.jpg',
        imgSet: 'pic/news-image.jpg 1x, pic/news-image@2x.jpg 2x',
        managers: [],
        storehouses: [],
      },
      shopData_0: {},
      storehouseData: {}
    }
  },
  mounted() {
    this.GetSellerShopData();
  },
  methods: {
    ...mapMutations([
      'SetSlidePopupName', 'SetSlidePopupStatus', 'SellerShopsSetPopupEditMode', 'SetReloadFlag',
      'SellerShopsSetUUID','SetWarehouseName','SetPopupName','SetPopupStatus'
    ]),
    ...mapActions(['GetCall', 'PutCall', 'DeleteCall']),
    GetSellerShopData() {
      this.GetCall({
        endpoint: '/api/shops/' + this.$route.query.uuid,
        callback: (data) => {
          // this.shopData_0 = { ...data.data };
          this.shopData.uuid = data.data.uuid;
          this.shopData.address = data.data.address;
          this.shopData.city = data.data.city;
          this.shopData.country = data.data.country;
          this.shopData.map_url = data.data.map_url;
          this.shopData.name = data.data.title;
          // this.shopData.img = data.data.img;
          this.shopData.name = data.data.title;
          this.shopData.managers = data.data.managers;
          this.shopData.storehouses = data.data.storehouses;
          this.shopData.worktime = data.data.worktime;
        },
      })
    },
    AddStorehouse() {
      this.SetSlidePopupName('add_shop_storehouse');
      this.SetSlidePopupStatus(true);
    },
    AddManager() {
      let editObj = {
        edit: false,
        data: {},
      }
      this.SellerShopsSetPopupEditMode(editObj);
      this.SetSlidePopupName('add_shop_manager');
      this.SetSlidePopupStatus(true);
    },
    OpenEditShop() {
      this.dd_edit_shop = !this.dd_edit_shop;
    },
    EditShop() {
      let editObj = {
        edit: true,
        data: { ...this.shopData },
      }
      editObj.worktime = [];
      this.shopData.worktime.forEach(e=>{
        editObj.worktime.push(Object.assign({}, e));
      });
      this.SellerShopsSetPopupEditMode(editObj);
      this.SetSlidePopupName('add_shop');
      this.SetSlidePopupStatus(true);
    },
    DeleteShop() {
      this.SellerShopsSetUUID(this.shopData.uuid);
      this.SetWarehouseName(this.shopData.name);
      this.SetPopupName('sellershop_remove');
      this.SetPopupStatus(true);
    },
    ManagerEdit(value) {
      let editObj = {
        edit: true,
        data: Object.assign({}, value),
      }
      editObj.phones = [];
      value.phones.forEach(e=>{
        editObj.phones.push(Object.assign({}, e));
      });
      this.SellerShopsSetPopupEditMode(editObj);
      this.SetSlidePopupName('add_shop_manager');
      this.SetSlidePopupStatus(true);
    },
    ManagerDelete(value) {
      this.DeleteCall({
        endpoint: '/api/shops/' + this.$route.query.uuid + '/managers/' + value.uuid,
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    }
  },
  watch: {
    reload_flag() {
      this.SetReloadFlag(false);
      window.location.reload(true);
    },
  },
}
</script>

<style scoped>

</style>