<template>
  <section class="content">
    <div class="breadcrumbs"><a href="#" class="breadcrumbs-home"></a><i></i><a href="#">Загрузка прайса на склад  “Название склада”</a></div>
    <h1>Загрузка прайса на склад “Название склада”</h1>
    <div class="block">
      <ul class="i-box-row">
        <li>
          <div class="i-box i-box-question"><strong>Узнайте, как правильно загрузить</strong> склад, исправить ошибки и настроить работу фильтров <a href="#">узнать</a></div>
        </li>
        <li>
          <div class="i-box i-box-star">Загружаемый прайс не должен содержать <strong>более 10 млн позиций</strong></div>
        </li>
        <li>
          <div class="i-box i-box-time">Загрузка прайса займёт некоторое время. Вы можете <strong>загружать запакованные файлы</strong> (ZIP, RAR), это ускорит загрузку прайса</div>
        </li>
      </ul>
    </div>
    <div class="block">
      <p>Под каждым названием поля необходимо указать порядковый номер столбца в том прайсе, в котором содержится соответствующая информация. В таблицах Excel столбцы указаны буквами, например A или B, а в файлах с разделителями это соответствует 1 и 2 столбцу. Мы принимаем все виды файлов. Поля, отмеченные красным, должны обязательно присутствовать в прайсе, при отсутствии данных в необязательных полях - ставьте "Нет".</p>
    </div>
    <div class="block">
      <div class="block-small">
        <table class="table table-mobile-simple">
          <thead>
          <tr>
            <th>
              <div class="table-th-tip">
                <span>производитель</span>
                <i class="tip"></i>
              </div>
            </th>
            <th>
              <div class="table-th-tip">
                <span>Код</span>
                <i class="tip"></i>
              </div>
            </th>
            <th>
              <div class="table-th-tip">
                <span>описание</span>
                <i class="tip"></i>
              </div>
            </th>
            <th>
              <div class="table-th-tip">
                <span>Цена</span>
                <i class="tip"></i>
              </div>
            </th>
            <th>
              <div class="table-th-tip">
                <span>б/у</span>
                <i class="tip"></i>
              </div>
            </th>
            <th>
              <div class="table-th-tip">
                <span>Кол-во</span>
                <i class="tip"></i>
              </div>
            </th>
            <th>
              <div class="table-th-tip">
                <span>Ориг. производители</span>
                <i class="tip"></i>
              </div>
            </th>
            <th>
              <div class="table-th-tip">
                <span>Ориг. код</span>
                <i class="tip"></i>
              </div>
            </th>
            <th>
              <div class="table-th-tip">
                <span>фото</span>
                <i class="tip"></i>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="table-no-padding">
            <td data-label="Производитель">
              <select class="select" v-model="columns.manufacturer">
                <option value=-1>нет</option>
                <option value=0>1(А)</option>
                <option value=1>2(B)</option>
                <option value=3>3(C)</option>
                <option value=4>4(D)</option>
                <option value=5>5(E)</option>
                <option value=6>6(F)</option>
                <option value=7>7(G)</option>
                <option value=8>8(H)</option>
                <option value=9>9(I)</option>
                <option value=10>10(J)</option>
              </select>
            </td>
            <td data-label="Код">
              <select class="select" v-model="columns.code">
                <option value=-1>нет</option>
                <option value=0>1(А)</option>
                <option value=1>2(B)</option>
                <option value=3>3(C)</option>
                <option value=4>4(D)</option>
                <option value=5>5(E)</option>
                <option value=6>6(F)</option>
                <option value=7>7(G)</option>
                <option value=8>8(H)</option>
                <option value=9>9(I)</option>
                <option value=10>10(J)</option>
              </select>
            </td>
            <td data-label="Описание">
              <select class="select" v-model="columns.description">
                <option value=-1>нет</option>
                <option value=0>1(А)</option>
                <option value=1>2(B)</option>
                <option value=3>3(C)</option>
                <option value=4>4(D)</option>
                <option value=5>5(E)</option>
                <option value=6>6(F)</option>
                <option value=7>7(G)</option>
                <option value=8>8(H)</option>
                <option value=9>9(I)</option>
                <option value=10>10(J)</option>
              </select>
            </td>
            <td data-label="Цена">
              <select class="select" v-model="columns.price">
                <option value=-1>нет</option>
                <option value=0>1(А)</option>
                <option value=1>2(B)</option>
                <option value=3>3(C)</option>
                <option value=4>4(D)</option>
                <option value=5>5(E)</option>
                <option value=6>6(F)</option>
                <option value=7>7(G)</option>
                <option value=8>8(H)</option>
                <option value=9>9(I)</option>
                <option value=10>10(J)</option>
              </select>
            </td>
            <td data-label="Б/у">
              <select class="select" v-model="columns.used">
                <option value=-1>нет</option>
                <option value=0>1(А)</option>
                <option value=1>2(B)</option>
                <option value=3>3(C)</option>
                <option value=4>4(D)</option>
                <option value=5>5(E)</option>
                <option value=6>6(F)</option>
                <option value=7>7(G)</option>
                <option value=8>8(H)</option>
                <option value=9>9(I)</option>
                <option value=10>10(J)</option>
              </select>
            </td>
            <td data-label="Кол-во">
              <select class="select" v-model="columns.number">
                <option value=-1>нет</option>
                <option value=0>1(А)</option>
                <option value=1>2(B)</option>
                <option value=3>3(C)</option>
                <option value=4>4(D)</option>
                <option value=5>5(E)</option>
                <option value=6>6(F)</option>
                <option value=7>7(G)</option>
                <option value=8>8(H)</option>
                <option value=9>9(I)</option>
                <option value=10>10(J)</option>
              </select>
            </td>
            <td data-label="Ориг. производители">
              <select class="select" v-model="columns.manufacturer_original">
                <option value=-1>нет</option>
                <option value=0>1(А)</option>
                <option value=1>2(B)</option>
                <option value=3>3(C)</option>
                <option value=4>4(D)</option>
                <option value=5>5(E)</option>
                <option value=6>6(F)</option>
                <option value=7>7(G)</option>
                <option value=8>8(H)</option>
                <option value=9>9(I)</option>
                <option value=10>10(J)</option>
              </select>
            </td>
            <td data-label="Ориг. код">
              <select class="select" v-model="columns.original_code">
                <option value=-1>нет</option>
                <option value=0>1(А)</option>
                <option value=1>2(B)</option>
                <option value=3>3(C)</option>
                <option value=4>4(D)</option>
                <option value=5>5(E)</option>
                <option value=6>6(F)</option>
                <option value=7>7(G)</option>
                <option value=8>8(H)</option>
                <option value=9>9(I)</option>
                <option value=10>10(J)</option>
              </select>
            </td>
            <td data-label="Фото">
              <select class="select">
                <option value="-1">нет</option>
              </select>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="block-small">
        <div class="upload-file">
          <span v-if="xls === null">Выберите прайс или перетащите файл сюда (xls, csv, zip, rar)</span>
          <span v-else class="file-selected">{{ xls.name }}</span>
          <input type="file" class="file-input" @change="FileAdded" accept=".csv,.xls,.xlsx,application/vnd.ms-excel">
        </div>
        <button v-if="xls !== null" type="submit" class="button" @click="Send">Загрузить прайс</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import router from "@/router/router";

export default {
  name: "UploadPrice",
  data: function () {
    return {
      warehouse_uuid:'',
      dd_edit_warehouse: false,
      edit_rules: false,
      xls: null,
      columns:{
        manufacturer: 4,
        code: 1,
        description: 3,
        price: 5,
        used: -1,
        number: 8,
        manufacturer_original: -1,
        original_code: 0,
      }
    }
  },
  computed: {
    ...mapState({
      // warehouse_uuid: state => state.Popups.warehouse_uuid,
    }),
  },
  mounted() {
    this.warehouse_uuid = this.$route.query.uuid;
  },
  methods: {
    ...mapMutations(['SetSlidePopupName', 'SetSlidePopupStatus',]),
    ...mapActions(['PostCall',]),
    FileAdded(e){
      let files = e.target.files || e.dataTransfer.files;
      if (files.length > 0){
        this.xls = files[0];
        console.log(this.xls);
      }
    },
    Send(e) {
      e.preventDefault();

      let _file = this.xls;

      const formData = new FormData();
      formData.append("file", _file);
      formData.append("manufacturer", this.columns.manufacturer);
      formData.append("code", this.columns.code);
      formData.append("description", this.columns.description);
      formData.append("price", this.columns.price);
      formData.append("used", this.columns.used);
      formData.append("number", this.columns.number);
      formData.append("manufacturer_original", this.columns.manufacturer_original);
      formData.append("original_code", this.columns.original_code);

      this.PostCall({
        endpoint: '/api/storehouse/'+this.warehouse_uuid+'/upload',
        param: formData,
        callback: (data) => {
          //router.push({ path: '/warehouse?uuid=' + this.warehouse_uuid });
          router.push({ path: '/warehouse', query: { uuid: this.warehouse_uuid } });
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if(error_status === 422){
            let errors = Object.keys(error.response.data.errors);
            console.log('errors',errors);
            for (let i = 0; i < errors.length; i++) {
              if(this.errored[errors[i]] !== undefined){
                this.errored[errors[i]] = true;
              }
            }
          }
        },
      })
    }
  },
}
</script>

<style scoped>

</style>