<template>
  <div class="popup"
       :class="is_open ? 'is-opened' : ''"
       data-popup="listing">
    <div class="popup-nav" style="max-width: 1290px;">
<!--      <button type="button" class="popup-prev"></button>-->
<!--      <button type="button" class="popup-next"></button>-->
      <div class="popup-box listing-item" style="max-width: 1180px;">
        <button type="button" class="popup-close" @click="hide"></button>
        <div class="listing-item-main">
          <div class="listing-item-header">{{ prod_info.name }}</div>
          <div class="block-small">
            <div class="listing-item-top">
              <div class="listing-item-top-image">
                <img src="pic/listing-item.jpg" srcset="pic/listing-item.jpg 1x, pic/listing-item@2x.jpg 2x" alt="">
                <a href="#" class="button button-middle button-block button-go">Аналоги у продавца</a>
              </div>
              <div class="listing-item-top-main">
                <ul class="listing-item-top-info">
                  <li>
                    <div class="listing-item-top-info-title">Код запчасти</div>
                    <div class="listing-item-top-info-text">{{ prod_info.code }}</div>
                  </li>
                  <li>
                    <div class="listing-item-top-info-title">Бренд</div>
                    <div class="listing-item-top-info-text">{{ prod_info.manufacturer }}</div>
                  </li>
                  <li>
                    <div class="listing-item-top-info-title">Дата обновления</div>
                    <div class="listing-item-top-info-text">11 сентября 2020 г.</div>
                  </li>
                  <li>
                    <div class="listing-item-top-info-title">Доступно к заказу</div>
                    <div class="listing-item-top-info-text">{{ prod_info.num }}</div>
                  </li>
                  <li>
                    <div class="listing-item-top-info-title">Описание</div>
                    <div class="listing-item-top-info-text">{{ prod_info.description }}</div>
                  </li>
                  <li>
                    <div class="listing-item-top-info-title">Доставка</div>
                    <div class="listing-item-top-info-text">Завтра из г. Киев<span>Самовывоз, курьерская доставка, Новая Почта по Киеву и Украине</span></div>
                  </li>
                </ul>
                <div class="listing-item-top-cart">
                  <div class="listing-item-top-availability is-available">В наличии</div>
                  <div class="listing-item-top-price">{{ prod_info.price }} UAH</div>
                  <div class="listing-item-top-currency"><span>В валюте склада</span>111.05 EUR (1 EUR = 32.99 UAH)</div>
                  <div class="listing-item-top-count">
                    <div class="listing-item-top-count-box">
                      <div class="count">
                        <input type="text" class="textfield numbers-only" value="1">
                        <button type="button" class="count-minus"></button>
                        <button type="button" class="count-plus"></button>
                      </div>
                    </div>
                    <div class="listing-item-top-count-box">
                      <button type="button" class="button button-middle button-cart">В корзину</button>
                    </div>
                  </div>
                  <!--
                  <div class="list-item-top-create-document">
                    <a href="#" class="button button-middle button-block button-green">Создать накладную</a>
                  </div>
                  -->
                </div>
              </div>
            </div>
          </div>
          <div class="block-small">
            <div class="open-box open-box-special">
              <div class="open-box-header">Характеристики</div>
              <div class="open-box-content">
                <ul class="listing-item-features">
                  <li>
                    <div class="listing-item-features-title">Длина упаковки, мм</div>
                    <div class="listing-item-features-text">170</div>
                  </li>
                  <li>
                    <div class="listing-item-features-title">Толщина упаковки, мм</div>
                    <div class="listing-item-features-text">90</div>
                  </li>
                  <li>
                    <div class="listing-item-features-title">Толщина упаковки, мм</div>
                    <div class="listing-item-features-text">92</div>
                  </li>
                  <li>
                    <div class="listing-item-features-title">Вес, г</div>
                    <div class="listing-item-features-text">2270</div>
                  </li>
                  <li>
                    <div class="listing-item-features-title">Длина упаковки, мм</div>
                    <div class="listing-item-features-text">170</div>
                  </li>
                  <li>
                    <div class="listing-item-features-title">Толщина упаковки, мм</div>
                    <div class="listing-item-features-text">90</div>
                  </li>
                  <li>
                    <div class="listing-item-features-title">Ширина упаковки, мм</div>
                    <div class="listing-item-features-text">92</div>
                  </li>
                  <li>
                    <div class="listing-item-features-title">Вес, г</div>
                    <div class="listing-item-features-text">2270</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="block-small">
            <div class="listing-item-store">
              <div class="listing-item-store-about">
                <div class="listing-item-store-about-name">
                  <a href="#">{{ prod_info.seller.name }}</a>
                </div>
                <div class="listing-item-store-about-rating">{{ prod_info.seller.rate }} <span>(23 отзыва)</span></div>
                <div class="listing-item-store-about-orders">{{ prod_info.seller.orders_num }} заказа(ов)</div>
              </div>
              <div class="listing-item-store-contact">
                <div class="listing-item-store-contact-box">
                  <ul>
                    <li>
                      <div class="listing-item-store-contact-phone">+380675710701</div>
                    </li>
                    <li>
                      <div class="listing-item-store-contact-phone">+380675710701</div>
                    </li>
                    <li>
                      <div class="listing-item-store-contact-mail">myemail@gmail.com</div>
                    </li>
                  </ul>
                </div>
                <div class="listing-item-store-contact-box">
                  <ul class="listing-item-store-contact-date">
                    <li>Пн-Пт: 10:00–18:00</li>
                    <li>Сб: 09:00–14:00</li>
                    <li>Вс: выходной</li>
                  </ul>
                </div>
                <div class="listing-item-store-contact-box">
                  <ul>
                    <li>
                      <div class="listing-item-store-contact-status is-opened">Магазин открыт</div>
                    </li>
                    <li>
                      <div class="listing-item-store-contact-location">Украина, Харьков, ул. Станционная 3</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="block-small">
            <div class="button-row">
              <div class="button-row-box">
                <button type="button" class="button button-middle">Задать вопрос</button>
              </div>
              <div class="button-row-box">
                <button type="button" class="button button-middle button-transparent">Жалоба</button>
              </div>
            </div>
            <form class="listing-item-ask">
              <span>Задайте вопрос продавцу и получите квалифицированную помощь</span>
              <textarea class="textfield autosize" rows="1" placeholder="Ваш текст..."></textarea>
              <button type="button" class="listing-item-ask-go"></button>
            </form>
          </div>
        </div>
        <div class="listing-item-bottom"><strong>Будьте внимательны!</strong><br>Avtoprice не может нести ответственность за действия продавца и достоверность предоставляемой им информации. Вносите предоплату только если вы на 100% уверены в продавце. Ознакомьтесь с Условиями использования сайта. Также рекомендуем вам ознакомиться с общими правилами безопасности сделок в интернете.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PopupListing",
  data: function () {
    return {
      is_open: false,
      prod_info:{
        uuid:'....',
        name: 'тормозные колодки',
        manufacturer: 'VAG',
        code: '3c0807837a',
        date_update: '...',
        num: 5,
        description: 'колодки тормозные',
        post_time: 'сегодня', //срок поставки,
        post_city: 'г. Харьков',
        post_variants: ['Самовывоз', 'курьерская доставка', 'Новая Почта'],
        price: 100500,
        price_currency: 10500,
        currency: 'EUR',
        bargain: true, //на товарили весь склад есть торг
        details: [
          {name: 'длинна',data: '100500мм',},
          {name: 'длинна',data: '100500мм',},
          {name: 'длинна',data: '100500мм',},
        ],
        seller:{
          name: 'ООО Продаван',
          uuid: '...',
          selection: false,
          rate: '4.5',
          reviews_num: 23,
          orders_num: 100500,
          phones: ['+380999999999','+380999999999','+380999999999'],
          mail: 'myemail@gmail.com',
          working_hours: {
            mn_fr: '10:00-18:00',
            st: '09:00-12:00',
            sn: 'выходной'
          },
          is_open_now: true,
          address: 'Украина, Харьков, ул. Станционная 3'
        }
      }
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      product_uuid: state => state.Popups.product_uuid,
    }),
  },
  mounted() {
    this.GetProductData(this.product_uuid);
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd',]),
    ...mapActions(['GetCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
    GetProductData(uuid) {
      this.GetCall({
        endpoint: '/api/product/'+uuid,
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          this.prod_info = data.data;
        },
      })
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },

}
</script>

<style scoped>

</style>