<template>
  <div
      class="popup"
      :class="is_open ? 'is-opened' : ''"
      data-popup="confirm-mail">
    <div class="popup-box" style="max-width: 514px;">
      <button
          type="button"
          class="popup-close"
          data-popup-close
          @click="hide"
      ></button>
      <div class="popup-header">Мы выслали письмо<br>с подтверждением на указанный E-mail.</div>
      <div class="button-row">
        <div class="button-row-box">
          <button
              type="button"
              class="button button-middle"
              data-popup-close
              @click="hide"
          >Всё понятно</button>
        </div>
        <div class="button-row-box">
          <a href="#">Выслать еще раз<br>(осталось 56 сек)</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PopupConfirmMail",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd',]),
    ...mapActions(['PostCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>