<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''"
      data-popup="add-warehouse"
  >
    <div class="sliding-door-header">
      <span>Добавление склада</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <p class="center">Для начала трансляции товаров в поисковой выдаче, создайте магазин и свяжите его со складом</p>
        <form class="form">
          <fieldset>
            <div class="form-field"
                 :class="[form.type.length > 0 ? 'is-filled':'', errored.type ? 'errored':'']">
              <select class="select" id="add-warehouse-type" v-model="form.type">
                <option value="retail" selected>Розница</option>
                <option value="wholesail">Опт</option>
              </select>
              <label for="add-warehouse-type">Тип склада</label>
            </div>
          </fieldset>
          <fieldset>
            <div class="form-field"
                 :class="[form.title.length > 0 ? 'is-filled':'', errored.title ? 'errored':'']">
              <input type="text" class="textfield" v-model="form.title">
              <label>Название склада</label>
            </div>
          </fieldset>
          <div class="form-row">
            <fieldset>
              <div class="form-field"
                   :class="[form.currency.length > 0 ? 'is-filled':'', errored.currency ? 'errored':'']">
                <select class="select" id="add-warehouse-currency" v-model="form.currency">
                  <option value="USD" selected>USD</option>
                  <option value="UAH">UAH</option>
                </select>
                <label>Валюта</label>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-field"
                   :class="[form.days_delivery > 0 ? 'is-filled':'', errored.days_delivery ? 'errored':'']">
                <input type="number" class="textfield" id="add-warehouse-days" v-model="form.days_delivery">
                <label>Дней</label>
              </div>
            </fieldset>
          </div>
          <fieldset>
            <div class="form-field"
                 :class="[form.text.length > 0 ? 'is-filled':'', errored.text ? 'errored':'']">
              <textarea class="textfield textarea-middle" v-model="form.text"></textarea>
              <label>Условия отправки...</label>
            </div>
          </fieldset>
          <div class="form-bottom">
            <div class="form-bottom-box">
              <input type="checkbox" class="toggle-visibility" id="add-warehouse-visibility" checked v-model="form.active">
              <label for="add-warehouse-visibility">
                <button type="button" class="show-button">
                  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                        stroke-width="1.5"/>
                    <path
                        d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                        stroke-width="1.5"/>
                    <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
                  </svg>
                </button>
                <span>Склад скрыт</span>
                <span>Склад видно всем</span>
              </label>
            </div>
            <div class="form-bottom-box">
              <button type="submit" class="button" @click="AddWarehouse">Сохранить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SPAddWarehouse",
  data: function () {
    return {
      is_open: false,
      form: {
        type: 'retail',
        title: '',
        currency: 'USD',
        days_delivery: '',
        text: '',
        active: true,
      },
      errored: {
        type: false,
        title: false,
        currency: false,
        days_delivery: false,
        text: false,
        active: false,
      },
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus', 'SetSlidePopupName', 'SetLoginStatusTriggerEnd','SetReloadFlag']),
    ...mapActions(['PostCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },
    AddWarehouse(e) {
      e.preventDefault();

      this.PostCall({
        endpoint: '/api/storehouse',
        param: this.form,
        callback: (data) => {
          this.SetReloadFlag(true);
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if(error_status === 422){
            let errors = Object.keys(error.response.data.errors);
            console.log('errors',errors);
            for (let i = 0; i < errors.length; i++) {
              if(this.errored[errors[i]] !== undefined){
                this.errored[errors[i]] = true;
              }
            }
          }
        },
      })
    }
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>