<template>
  <section class="content">
    <section class="block">
      <Slider></Slider>
    </section>
    <section class="block">
      <MainList :name="customer_list.name" :list="customer_list.list"/>
    </section>
    <section class="block">
      <MainList :name="seller_list.name" :list="seller_list.list"/>
    </section>
    <section class="block">
      <KnowMore
          :h2="know_more.h2"
          :text="know_more.text"
          :link="know_more.link"
      ></KnowMore>
    </section>
    <section class="block">
      <h2>О сервисе</h2>
      <p>Есть много вариантов  Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные генераторы Lorem Ipsum используют один и тот же текст.</p>
      <p>Есть много вариантов  Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь. Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие известные.</p>
    </section>
  </section>
</template>

<script>
import Slider from "@/components/common/Slider";
import KnowMore from "@/components/common/KnowMore";
import MainList from "@/components/common/MainList";

export default {
  name: "Main",
  components: {
    MainList,
    Slider,
    KnowMore,
  },
  data:function () {
    return {
      know_more:{
        h2:"Страховой полис",
        text:"Avtoprice, как официальный партнер, предлагает лучший выбор среди ведущих страховых компаний Украины.",
        link:"#",
      },
      customer_list:{
        name:'Покупатели',
        list:[
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 1'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 2'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 3'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 4'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 5'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 6'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 7'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 8'},
        ]
      },
      seller_list:{
        name:'Продавцы',
        list:[
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 1'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 2'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 3'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 4'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 5'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 6'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 7'},
          {url:'#',img:'img/car.svg',text:'Простая торговля одной запчастью и миллионным ассортиментом 8'},
        ]
      },
    }
  },
  methods:{
    GetKnowMore(){

    },
    GetCustomerList(){

    },
    GetSellerList(){

    }
  }
}
</script>

<style scoped>

</style>