<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''"
      data-popup="add-crosses">
    <div class="sliding-door-header">
      <span>Добавить кроссы</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <form class="form">
          <div class="block-small">
            <fieldset>
              <div class="form-field"
                   :class="[form.type.length > 0 ? 'is-filled':'', errored.type ? 'errored':'']">
                <select class="select" id="add-crosses-type" v-model="form.type">
                  <option selected>Запрос в отдел экспертизы</option>
                  <option>Положительный</option>
                  <option>Отрицательный</option>
                </select>
                <label>Тип сообщения</label>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-field"
                   :class="[form.rubric.length > 0 ? 'is-filled':'', errored.rubric ? 'errored':'']">
              <select class="select" id="add-crosses-category" v-model="form.rubric">
                  <option selected>Добавление кроссов</option>
                  <option>Положительный</option>
                  <option>Отрицательный</option>
                </select>
                <label>Рубрика</label>
              </div>
            </fieldset>
          </div>
          <div class="block-small small-text">
            <p>Загрузите файл Excel у которого присутствует 4 первых столбца в следующем порядке: Код аналог, Производитель аналог, Код оригинал, Производитель оригинал.</p>
            <p>Если в кроссе присутствуют бренды, которые отсутствуют на нашем сайте, укажите их и напишите к ним описание в сообщении.</p>
            <p>Добавление кроссов выполняется в порядке общей очереди.</p>
            <p>Срок обработки обращения - до 5 рабочих дней.</p>
          </div>
          <div class="block-small">
            <fieldset>
              <div class="upload-file">
                <span v-if="cross === null">Прикрепить файлы (xlsx, xls, csv)</span>
                <span v-else class="file-selected">{{ cross.name }}</span>
                <input type="file" class="file-input" @change="FileAdded" accept=".csv,.xls,application/vnd.ms-excel">
              </div>
            </fieldset>
            <fieldset>
              <div class="form-field"
                   :class="[form.message.length > 0 ? 'is-filled':'', errored.message ? 'errored':'']">
                <textarea class="textfield" v-model="form.message"></textarea>
                <label>Ваше сообщение...</label>
              </div>
            </fieldset>
            <div class="button-center">
              <button type="submit" @click="Send" class="button button-middle">Сохранить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SPAddCrosses",
  data: function () {
    return {
      is_open: false,
      cross:null,
      form: {
        type: 'Запрос в отдел экспертизы',
        rubric: 'Добавление кроссов',
        message: ''
      },
      errored: {
        type: false,
        rubric: false,
        file: false,
        message: false
      }
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus','SetPopupStatus','SetPopupName', 'SetLoginStatusTriggerEnd']),
    ...mapActions(['PostCall']),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },
    FileAdded(e){
      let files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        this.cross = files[0];
      }
    },
    Send(e) {
      e.preventDefault();

      let _file = this.cross;

      const formData = new FormData();
      formData.append("file", _file);
      formData.append("type", this.form.type);
      formData.append("rubric", this.form.rubric);
      formData.append("message", this.form.message);

      this.PostCall({
        endpoint: '/api/add_cross',
        param: this.form,
        callback: (data) => {
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if(error_status === 422){
            let errors = Object.keys(error.response.data.errors);
            console.log('errors',errors);
            for (let i = 0; i < errors.length; i++) {
              if(this.errored[errors[i]] !== undefined){
                this.errored[errors[i]] = true;
              }
            }
          }
        },
      })
    }
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },

}
</script>

<style scoped>

</style>