<template>
  <section class="content">
    <div class="breadcrumbs"><a href="/" class="breadcrumbs-home"></a><i></i><a href="#">Склады</a></div>
    <h1>Склады</h1>
    <div class="block-small">
      <ul class="warehouse-info">
        <li>
          <div class="warehouse-info-box warehouse-info-products">
            <p>Товаров</p>
            <span>{{ total_products }}</span>
          </div>
        </li>
        <li>
          <div class="warehouse-info-box warehouse-info-photo">
            <p>С фото</p>
            <span>{{ total_products_photos }}</span>
          </div>
        </li>
        <li>
          <div class="warehouse-info-box warehouse-info-description">
            <p>С описанием</p>
            <span>{{ total_products_description }}</span>
          </div>
        </li>
        <li>
          <div class="warehouse-info-box warehouse-info-cross">
            <p>В кроссах</p>
            <span>{{ total_products_cross }} %</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="block-small">
      <form class="search">
        <div class="search-main">
          <input type="text" class="textfield" placeholder="Поиск по складам...">
          <button type="submit" class="search-go"></button>
        </div>
        <div class="search-fixed">
          <ul class="toolbox">
            <li>
              <button type="button" class="toolbox-link">Связать склады</button>
            </li>
            <li>
              <button type="button" class="toolbox-show" @click="ShowAll">Показать</button>
            </li>
            <li>
              <button type="button" class="toolbox-hide" @click="HideAll">Скрыть</button>
            </li>
            <li>
              <button type="button" class="toolbox-remove" @click="DeleteAll">Удалить</button>
            </li>
          </ul>
        </div>
        <div class="search-fixed">
          <button type="button" class="button popup-open" @click="AddWarehouse">Добавить склад</button>
        </div>
      </form>
    </div>
    <div class="block-small">
      <div class="table-wrapper">
        <table class="table warehouse-table check-group">
          <!--          header       -->
          <thead>
          <tr>
            <th class="table-sortable">Название</th>
            <th class="table-sortable">Товары, шт</th>
            <th class="table-sortable">Валюта</th>
            <th class="table-sortable">Отправка, дн</th>
            <th class="table-sortable">В кроссах</th>
            <th class="table-sortable">обновлен</th>
            <th class="table-sortable">Видимость</th>
            <th align="right">
              <input
                  type="checkbox"
                  class="checkbox checkbox-empty check-all"
                  @click="SelectAll"
                  ref="select_all"
                  id="item-all">
              <label for="item-all"></label>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="table-divider">
            <td colspan="8"></td>
          </tr>
          </tbody>
          <!--          header       -->

          <!--          linked       -->
          <template v-if="linked.length > 0">

          </template>
          <!--          linked       -->

          <!--          unlinked       -->
          <template v-if="new_warehouses.length > 0">
            <warehouses-block type="new" :list="new_warehouses"></warehouses-block>
          </template>
          <template v-if="old_warehouses.length > 0">
            <warehouses-block type="old" :list="old_warehouses"></warehouses-block>
          </template>
          <template v-if="warehouses.length > 0">
            <warehouses-block type="other" :list="warehouses"></warehouses-block>
          </template>
          <!--          unlinked       -->

          <!--
                    <tbody>
                      <tr>
                        <td align="center">Basteon nikolaev</td>
                        <td align="center">39030</td>
                        <td align="center" class="warehouse-table-currency">EUR<span>Собственный курс</span></td>
                        <td>Сегодня</td>
                        <td align="center" class="warehouse-table-cross">
                          <span>100%</span>
                        </td>
                        <td align="center" class="warehouse-table-update-today">Сегодня в 22:10</td>
                        <td align="center">
                          <button type="button" class="table-button table-button-visibility is-visible">
                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                                  stroke-width="1.5"/>
                              <path
                                  d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                                  stroke-width="1.5"/>
                              <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
                            </svg>
                          </button>
                        </td>
                        <td>
                          <ul class="table-buttons-list align-right">
                            <li>
                              <button type="button" class="table-button table-button-remove" data-popup-open="warehouse-remove"></button>
                            </li>
                            <li>
                              <input type="checkbox" class="checkbox checkbox-empty" id="item-0">
                              <label for="item-0"></label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td align="center">Basteon nikolaev</td>
                        <td align="center">39030</td>
                        <td align="center" class="warehouse-table-currency">EUR<span>Собственный курс</span></td>
                        <td>Сегодня</td>
                        <td align="center" class="warehouse-table-cross">
                          <span>100%</span>
                        </td>
                        <td align="center" class="warehouse-table-update-today">Сегодня в 22:10</td>
                        <td align="center">
                          <button type="button" class="table-button table-button-visibility is-visible">
                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                                  stroke-width="1.5"/>
                              <path
                                  d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                                  stroke-width="1.5"/>
                              <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
                            </svg>
                          </button>
                        </td>
                        <td>
                          <ul class="table-buttons-list align-right">
                            <li>
                              <button type="button" class="table-button table-button-remove" data-popup-open="warehouse-remove"></button>
                            </li>
                            <li>
                              <input type="checkbox" class="checkbox checkbox-empty" id="item-1">
                              <label for="item-1"></label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td align="center">Basteon nikolaev</td>
                        <td align="center">39030</td>
                        <td align="center" class="warehouse-table-currency">EUR<span>Собственный курс</span></td>
                        <td>Сегодня</td>
                        <td align="center" class="warehouse-table-cross">
                          <span>100%</span>
                        </td>
                        <td align="center" class="warehouse-table-update-today">Сегодня в 22:10</td>
                        <td align="center">
                          <button type="button" class="table-button table-button-visibility is-visible">
                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                                  stroke-width="1.5"/>
                              <path
                                  d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                                  stroke-width="1.5"/>
                              <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
                            </svg>
                          </button>
                        </td>
                        <td>
                          <ul class="table-buttons-list align-right">
                            <li>
                              <button type="button" class="table-button table-button-remove" data-popup-open="warehouse-remove"></button>
                            </li>
                            <li>
                              <input type="checkbox" class="checkbox checkbox-empty" id="item-2">
                              <label for="item-2"></label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr class="table-divider">
                        <td colspan="8"></td>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr>
                        <td align="center">Basteon nikolaev</td>
                        <td align="center">39030</td>
                        <td align="center" class="warehouse-table-currency">EUR<span>Собственный курс</span></td>
                        <td>Сегодня</td>
                        <td align="center" class="warehouse-table-cross">97%</td>
                        <td align="center" class="warehouse-table-update-long">более месяца назад</td>
                        <td align="center">
                          <button type="button" class="table-button table-button-visibility">
                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                                  stroke-width="1.5"/>
                              <path
                                  d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                                  stroke-width="1.5"/>
                              <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
                            </svg>
                          </button>
                        </td>
                        <td>
                          <ul class="table-buttons-list align-right">
                            <li>
                              <button type="button" class="table-button table-button-remove" data-popup-open="warehouse-remove"></button>
                            </li>
                            <li>
                              <input type="checkbox" class="checkbox checkbox-empty" id="item-3">
                              <label for="item-3"></label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td align="center">Basteon nikolaev</td>
                        <td align="center">39030</td>
                        <td align="center" class="warehouse-table-currency">EUR<span>Собственный курс</span></td>
                        <td>Сегодня</td>
                        <td align="center" class="warehouse-table-cross">97%</td>
                        <td align="center" class="warehouse-table-update-long">более месяца назад</td>
                        <td align="center">
                          <button type="button" class="table-button table-button-visibility">
                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                                  stroke-width="1.5"/>
                              <path
                                  d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                                  stroke-width="1.5"/>
                              <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
                            </svg>
                          </button>
                        </td>
                        <td>
                          <ul class="table-buttons-list align-right">
                            <li>
                              <button type="button" class="table-button table-button-remove" data-popup-open="warehouse-remove"></button>
                            </li>
                            <li>
                              <input type="checkbox" class="checkbox checkbox-empty" id="item-4">
                              <label for="item-4"></label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td align="center">Basteon nikolaev</td>
                        <td align="center">39030</td>
                        <td align="center" class="warehouse-table-currency">EUR<span>Собственный курс</span></td>
                        <td>Сегодня</td>
                        <td align="center" class="warehouse-table-cross">97%</td>
                        <td align="center" class="warehouse-table-update-long">более месяца назад</td>
                        <td align="center">
                          <button type="button" class="table-button table-button-visibility">
                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
                                  stroke-width="1.5"/>
                              <path
                                  d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
                                  stroke-width="1.5"/>
                              <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
                            </svg>
                          </button>
                        </td>
                        <td>
                          <ul class="table-buttons-list align-right">
                            <li>
                              <button type="button" class="table-button table-button-remove" data-popup-open="warehouse-remove"></button>
                            </li>
                            <li>
                              <input type="checkbox" class="checkbox checkbox-empty" id="item-5">
                              <label for="item-5"></label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr class="table-divider">
                        <td colspan="8"></td>
                      </tr>
                    </tbody>

                    -->
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import WarehousesBlock from "@/components/common/Warehouses/WarehousesBlock";
// import router from "@/router/router";

export default {
  name: "Warehouses",
  components: { WarehousesBlock },
  data: function () {
    return {
      select_block: false,
      total_products: 0,
      total_products_photos: 0,
      total_products_description: 0,
      total_products_cross: 0,
      list: [
        {
          "uuid": "14a6b990-0e53-44c9-a4eb-beafc47b4852",
          "title": "склад 1",
          "currency": "USD",
          "days_delivery": 1,
          "active": true,
          "text": "фыаыва ываы а",
          "seller": {
            "id": 1,
            "user_id": 13,
            "first_name": "вася",
            "last_name": "вася",
            "mid_name": "вася",
            "company": "Васяко",
            "created_at": "2021-08-17T09:47:48.000000Z",
            "updated_at": "2021-08-17T09:47:48.000000Z"
          }
        }
      ],
      linked: [],
      new_warehouses: [],
      old_warehouses: [],
      warehouses: [],
    }
  },
  computed: {
    ...mapState({
      reload_flag: state => state.Main.reload_flag,
      select_top: state => state.Warehouses.select_top,
      select_all_flag: state => state.Warehouses.select_all_flag,
      selected: state => state.Warehouses.selected,
    }),
  },
  mounted() {
    this.SelectTopToggle(false);
    this.SelectAllToggleFlag(false);
    this.UnselectLineFlag(false);
    this.ClearSelectedWarehouses();

    this.GetWarehousesGeneralData();
    //this.GetWarehousesListData();
  },
  methods: {
    ...mapMutations([
      'SetSlidePopupName', 'SetSlidePopupStatus', 'SetPopupName', 'SetPopupStatus', 'SetWarehouseUUID',
      'SetWarehouseName', 'SelectTopToggle', 'SelectAllToggleFlag', 'SetReloadFlag', 'UnselectLineFlag',
      'ClearSelectedWarehouses',
    ]),
    ...mapActions(['GetCall', 'PutCall']),
    AddWarehouse() {
      this.SetSlidePopupName('add_warehouse');
      this.SetSlidePopupStatus(true);
    },
    GetWarehousesGeneralData() {
      this.GetCall({
        endpoint: '/api/storehouse',
        callback: (data) => {
          this.total_products = data.data.totalProducts;
          this.total_products_photos = data.data.totalProductsPhotos;
          this.total_products_description = data.data.totalProductsDescription;
          this.total_products_cross = data.data.totalProductsCross;
          this.new_warehouses = Array.from(data.data.unlinked.new);
          this.old_warehouses = Array.from(data.data.unlinked.old);
          this.warehouses = Array.from(data.data.unlinked.other);
        },
      })
    },
    SelectAll() {
      this.select_block = true;
      this.SelectTopToggle(!this.select_top);
      this.SelectAllToggleFlag(this.select_top);
      this.UnselectLineFlag(false);
      this.select_block = false;
    },
    LinkAll() {

    },
    DeleteAll() {
      this.PutCall({
        endpoint: '/api/storehouse',
        param: {
          delete: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
    HideAll() {
      this.PutCall({
        endpoint: '/api/storehouse',
        param: {
          hide: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
    ShowAll() {
      this.PutCall({
        endpoint: '/api/storehouse',
        param: {
          show: this.selected,
        },
        callback: (data) => {
          this.SetReloadFlag(true);
        },
      })
    },
  },
  watch: {
    select_top(newVal) {
      if (!this.select_block) {
        this.$refs.select_all.checked = newVal;
      }
    },
    reload_flag() {
      this.SetReloadFlag(false);
      window.location.reload(true);
    },
  },
}
</script>

<style scoped>

</style>