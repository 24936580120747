<template>
  <tbody>
    <template v-for="(value, index) in list" v-bind:key="index">
      <warehouses_line :line_info="value" :type="type"></warehouses_line>
    </template>
  </tbody>
  <tbody>
    <tr class="table-divider">
      <td colspan="8"></td>
    </tr>
  </tbody>
</template>

<script>
import Warehouses_line from "@/components/common/Warehouses/WarehousesLine";

export default {
  name: "WarehousesBlock",
  components: { Warehouses_line },
  props: {
    linked: {
      type: Boolean,
      default: false,
    },
    type: String,
    list: Array,
  }
}
</script>

<style scoped>

</style>