<template>
  <div class="cart">
    <div class="cart-store">
      <div class="cart-store-side">
        <div class="cart-store-name is-fav">{{ order.seller.name }}</div>
        <div class="cart-store-rating">{{ order.seller.rate }} <span>({{ order.seller.reviews_num }} отзыва(ов))</span></div>
        <div class="cart-store-orders">{{ order.seller.orders_num }} заказа(ов)</div>
      </div>
    </div>
    <template v-for="(value, index) in order.products" v-bind:key="index">
      <CartLine :line="value" :order="order"></CartLine>
    </template>
    <div class="cart-go">
      <a @click="AcceptOrder" class="button button-middle">Перейти к оформлению</a>
      <div class="cart-item-row">
        <div class="cart-item-box">
          <span>Общая сумма</span>
          <p>{{ order.total }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartLine from "@/components/common/Cart/CartLine";
import router from "@/router/router";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "CartOrder",
  components: {CartLine},
  props:{
    order:Object,
  },
  mounted() {
    if(this.order.products.length === 0){
      this.RemoveOrder();
    }
  },
  methods:{
    ...mapMutations(['SetCartReloadFlag',]),
    ...mapActions(['DeleteCall']),
    AcceptOrder(){
      router.push({ path: '/Order', query: { uuid: this.order.uuid } });
    },
    RemoveOrder() {
      this.DeleteCall({
        endpoint: '/api/cart/' + this.order.uuid,
        param: '',
        callback: (data) => {
          this.SetCartReloadFlag(true);
        },
      })
    }
  },
  watch:{
    order(){
      this.SetCartReloadFlag(false);
      //window.location.reload(true);
      //this.GetCartData();
    },
  },
}
</script>

<style scoped>

</style>