<template>
  <tr>
    <td align="center" @click="WarehouseView(line_info.uuid)">{{ line_info.title }}</td>
    <td align="center">{{ line_info.part_num }}</td>
    <td align="center" class="warehouse-table-currency">{{ line_info.currency }}<span>Собственный курс</span></td>
    <td>Сегодня</td>
    <td align="center" class="warehouse-table-cross">97%</td>
    <td align="center" v-if="type === 'new'" class="warehouse-table-update-today">Сегодня</td>
    <td align="center" v-if="type === 'old'" class="warehouse-table-update-long">Более месяца назад</td>
    <td align="center" v-if="type === 'other'">Не сегодня</td>
    <td align="center">
      <button
          type="button"
          class="table-button table-button-visibility"
          :class="visible ? 'is-visible':''"
          @click="WarehouseSetVisible(line_info.uuid)"
      >
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M15.8338 9.0479C15.8429 9.02655 15.8476 9.00358 15.8476 8.98037C15.8476 8.95716 15.8429 8.9342 15.8338 8.91285C15.5096 8.30173 12.9639 3.66937 8.47669 3.55795C3.98952 3.44654 1.38636 8.20719 1.02171 8.89596C1.00749 8.9206 1 8.94855 1 8.977C1 9.00544 1.00749 9.03339 1.02171 9.05803C1.42012 9.70966 4.20561 14.0955 7.69338 14.4163C11.539 14.7539 14.6588 12.0529 15.8338 9.0479Z"
              stroke-width="1.5"/>
          <path
              d="M8.37603 11.2662C9.66454 11.2662 10.7091 10.2217 10.7091 8.93315C10.7091 7.64464 9.66454 6.6001 8.37603 6.6001C7.08751 6.6001 6.04297 7.64464 6.04297 8.93315C6.04297 10.2217 7.08751 11.2662 8.37603 11.2662Z"
              stroke-width="1.5"/>
          <path id="line" d="M1.46875 15.4697L15.4688 1.46967" stroke-width="1.5"/>
        </svg>
      </button>
    </td>
    <td>
      <ul class="table-buttons-list align-right">
        <li>
          <button
              type="button"
              class="table-button table-button-remove"
              data-popup-open="warehouse-remove"
              @click="WarehouseDelete(line_info.uuid, line_info.title)"
          ></button>
        </li>
        <li>
          <input
              type="checkbox"
              class="checkbox checkbox-empty"
              @click="WarehouseSelect(line_info.uuid)"
              :id="'item-'+line_info.uuid"
              ref="select_btn">
          <label :for="'item-'+line_info.uuid"></label>
        </li>
      </ul>
    </td>
  </tr>
</template>

<script>
import router from "@/router/router";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "warehouses_line",
  data: function () {
    return {
      visible: true,
      selected: false,
    }
  },
  props: {
    line_info: Object,
    type: String,
  },
  computed: {
    ...mapState({
      select_top: state => state.Warehouses.select_top,
      select_all_flag: state => state.Warehouses.select_all_flag,
      unselect_line: state => state.Warehouses.unselect_line,
    }),
  },
  mounted() {
    this.visible = this.line_info.active;
  },
  methods: {
    ...mapMutations([
      'SetPopupName', 'SetPopupStatus', 'SetWarehouseUUID',
      'SetWarehouseName', 'WarehouseUUID', 'SelectAllToggleFlag', 'SelectTopToggle',
      'UnselectLineFlag'
    ]),
    ...mapActions(['PutCall']),
    WarehouseDelete(uuid, name) {
      this.SetWarehouseUUID(uuid);
      this.SetWarehouseName(name);
      this.SetPopupName('warehouse_remove');
      this.SetPopupStatus(true);
    },
    WarehouseSetVisible(uuid) {
      this.visible = !this.visible;

      let form = {
        active: this.visible,
        title: this.line_info.title,
        currency: this.line_info.currency,
        days_delivery: this.line_info.days_delivery,
      }

      this.PutCall({
        endpoint: '/api/storehouse/' + uuid,
        param: form,
        callback: (data) => {

        },
        on_error: (error) => {
          let error_status = error.response.status;

          if (error_status === 422) {
            let errors = Object.keys(error.response.data.errors);
            console.log('errors', errors);
          }
        },
      })
    },
    WarehouseView(uuid) {
      router.push({ path: '/warehouse', query: { uuid: uuid } });
    },
    WarehouseSelect(uuid) {
      this.UnselectLineFlag(true);
      this.selected = !this.selected;
      this.SelectTopToggle(false);
      this.SelectAllToggleFlag(false);
      this.WarehouseUUID(uuid);
      setTimeout(()=>{this.UnselectLineFlag(false);},20)
    }
  },
  watch: {
    select_all_flag(newVal) {
      console.log(this.unselect_line);
      if(!this.unselect_line) {
        if ((newVal && !this.selected) || (!newVal && this.selected)) {
          this.$refs.select_btn.checked = this.selected = newVal;
          this.WarehouseUUID(this.line_info.uuid);
        }
      }
    }
  },
}
</script>

<style scoped>

</style>