export default {
  state: {
    flying_sb_fix: false,
    flying_sb_abs: false,
    flying_e_fix: false,
    flying_e_abs: false,
    api_server: 'https://api2.inmashin.mir.digital:8922',
    // api_server: 'https://api.inmashin.mir.digital',
    // api_server: 'http://avto.mir.digital',
    reload_flag: false,
  },
  mutations: {
    FlyingSbFix(state) {
      state.flying_sb_fix = true;
      state.flying_sb_abs = false;
    },
    FlyingSbAbs(state) {
      state.flying_sb_fix = false;
      state.flying_sb_abs = true;
    },
    FlyingSbRelease(state) {
      state.flying_sb_fix = false;
      state.flying_sb_abs = false;
    },
    FlyingEFix(state) {
      state.flying_e_fix = true;
      state.flying_e_abs = false;
    },
    FlyingEAbs(state) {
      state.flying_e_fix = false;
      state.flying_e_abs = true;
    },
    FlyingERelease(state) {
      state.flying_e_fix = false;
      state.flying_e_abs = false;
    },
    SetReloadFlag(state,reload){
      state.reload_flag = reload;
    }
  },
  actions: {},
  getters: {}
}
