<template>
  <div class="popup" data-popup="add-comment-success">
    <div class="popup-box" style="max-width: 422px;">
      <button type="button" class="popup-close" data-popup-close></button>
      <div class="popup-success">
        <div class="popup-header">Спасибо!<br>Ваш отзыв будет опубликован после модерации</div>
        <button type="button" class="button button-middle" data-popup-close>Всё понятно</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PopupAddCommentSuccess",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd',]),
    ...mapActions(['PostCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>