<template>
  <section class="content">
    <div class="breadcrumbs"><a href="#" class="breadcrumbs-home"></a><i></i><a href="#">Заказы</a></div>
    <section class="block">
      <h1>Заказы</h1>
      <div class="block-small">
        <form class="search">
          <div class="search-main">
            <input type="text" class="textfield" placeholder="Поиск по заказам...">
            <button type="submit" class="search-go"></button>
          </div>

<!--
          <div class="search-fixed">
            <ul class="search-row">
              <li>
                <input type="checkbox" class="checkbox" id="search-checkbox-1">
                <label for="search-checkbox-1">Все</label>
              </li>
              <li>
                <input type="checkbox" class="checkbox" id="search-checkbox-2">
                <label for="search-checkbox-2">Покупка</label>
              </li>
              <li>
                <input type="checkbox" class="checkbox" id="search-checkbox-3">
                <label for="search-checkbox-3">Продажа</label>
              </li>
            </ul>
          </div>
-->

        </form>
      </div>
      <div class="block-small">
        <div class="orders">
          <div class="orders-stats">
            <div class="flying">
              <div class="flying-box">
                <ul class="orders-stats-tabs">
                  <li class="orders-stats-all">
                    <span>{{ data.orders.length }}</span>
                    <p>Все</p>
                  </li>
                  <li class="orders-stats-completed">
                    <span>{{ data.orders_success }}</span>
                    <p>Завершенные</p>
                  </li>
                  <li class="orders-stats-in-progress">
                    <span>{{ data.orders_process }}</span>
                    <p>Выполняется</p>
                  </li>
                  <li class="orders-stats-rejected">
                    <span>{{ data.orders_fail }}</span>
                    <p>Отклоненные</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="orders-table">
            <div class="fake-desktop">
              <div class="table-wrapper">
                <table class="table">
                  <thead>
                  <tr>
                    <th class="table-sortable">номер</th>
                    <th class="table-sortable">дата заказа</th>
                    <th class="table-sortable">контакты</th>
                    <th class="table-sortable">сумма</th>
                    <th class="table-sortable">статус</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="table-divider">
                    <td colspan="10"></td>
                  </tr>
                  </tbody>

                  <template v-if="type==='sell'" v-for="(value, index) in data.orders" v-bind:key="index">
                    <template v-if="value.status !== 'cart'">
                      <OrderSellLine :value="value" :type="type"></OrderSellLine>
                      <tbody>
                        <tr class="table-divider">
                          <td colspan="10"></td>
                        </tr>
                      </tbody>
                    </template>
                  </template>

                  <template v-if="type==='buy'" v-for="(value, index) in data.orders" v-bind:key="index">
                    <template v-if="value.status !== 'cart'">
                      <OrderBuyLine :value="value" :type="type"></OrderBuyLine>
                      <tbody>
                        <tr class="table-divider">
                        <td colspan="10"></td>
                      </tr>
                      </tbody>
                    </template>
                  </template>

                </table>
              </div>
            </div>
            <div class="fake-mobile">
              <table class="table">
                <thead>
                <tr>
                  <th class="table-sortable">номер</th>
                  <th class="table-sortable">дата заказа</th>
                  <th class="table-sortable">сумма</th>
                </tr>
                </thead>
                <tbody>
                <tr class="table-divider">
                  <td colspan="10"></td>
                </tr>
                </tbody>
                <tbody class="table-group">
                <tr>
                  <td>
                    <div class="orders-table-number orders-table-sell">26924780<span>Продажа</span></div>
                  </td>
                  <td align="center">05.09.2020<br>в 09:52</td>
                  <td align="center">
                    <div class="table-group-open">
                      <button type="button" class="table-group-open-button"></button>
                      <span>92 934 UAH</span>
                      <div class="orders-table-status-mobile orders-table-completed">завершено</div>
                    </div>
                  </td>
                </tr>
                <tr class="table-hidden-row">
                  <td colspan="3">
                    <div class="order-info-title">Товары</div>
                    <div class="order-info-mobile">
                      <div class="order-info-mobile-main">
                        <div class="order-info-mobile-image">
                          <img src="pic/order-info-table-1.png" srcset="pic/order-info-table-1.png 1x, pic/order-info-table-1@2x.png 2x" alt="">
                        </div>
                        <div class="order-info-mobile-name">SL5570 ABS задний тормозной шланг</div>
                      </div>
                      <ul class="order-info-mobile-about">
                        <li><span>Кол-во</span>2</li>
                        <li><span>Цена</span>22096 UAH</li>
                        <li><span>Сумма</span>44186 UAH</li>
                      </ul>
                    </div>
                    <div class="order-info-mobile">
                      <div class="order-info-mobile-main">
                        <div class="order-info-mobile-image">
                          <img src="pic/order-info-table-2.png" srcset="pic/order-info-table-2.png 1x, pic/order-info-table-2@2x.png 2x" alt="">
                        </div>
                        <div class="order-info-mobile-name">SL5570 ABS задний тормозной шланг</div>
                      </div>
                      <ul class="order-info-mobile-about">
                        <li><span>Кол-во</span>2</li>
                        <li><span>Цена</span>22096 UAH</li>
                        <li><span>Сумма</span>44186 UAH</li>
                      </ul>
                    </div>
                    <a href="#" class="button button-block button-middle button-transparent">Полная информация</a>
                  </td>
                </tr>
                </tbody>
                <tbody>
                <tr class="table-divider">
                  <td colspan="10"></td>
                </tr>
                </tbody>
                <tbody class="table-group">
                <tr>
                  <td>
                    <div class="orders-table-number orders-table-sell">26924780<span>Продажа</span></div>
                  </td>
                  <td align="center">05.09.2020<br>в 09:52</td>
                  <td align="center">
                    <div class="table-group-open">
                      <button type="button" class="table-group-open-button"></button>
                      <span>92 934 UAH</span>
                      <div class="orders-table-status-mobile orders-table-completed">завершено</div>
                    </div>
                  </td>
                </tr>
                <tr class="table-hidden-row">
                  <td colspan="3">
                    <div class="order-info-title">Товары</div>
                    <div class="order-info-mobile">
                      <div class="order-info-mobile-main">
                        <div class="order-info-mobile-image">
                          <img src="pic/order-info-table-1.png" srcset="pic/order-info-table-1.png 1x, pic/order-info-table-1@2x.png 2x" alt="">
                        </div>
                        <div class="order-info-mobile-name">SL5570 ABS задний тормозной шланг</div>
                      </div>
                      <ul class="order-info-mobile-about">
                        <li><span>Кол-во</span>2</li>
                        <li><span>Цена</span>22096 UAH</li>
                        <li><span>Сумма</span>44186 UAH</li>
                      </ul>
                    </div>
                    <div class="order-info-mobile">
                      <div class="order-info-mobile-main">
                        <div class="order-info-mobile-image">
                          <img src="pic/order-info-table-2.png" srcset="pic/order-info-table-2.png 1x, pic/order-info-table-2@2x.png 2x" alt="">
                        </div>
                        <div class="order-info-mobile-name">SL5570 ABS задний тормозной шланг</div>
                      </div>
                      <ul class="order-info-mobile-about">
                        <li><span>Кол-во</span>2</li>
                        <li><span>Цена</span>22096 UAH</li>
                        <li><span>Сумма</span>44186 UAH</li>
                      </ul>
                    </div>
                    <a href="#" class="button button-block button-middle button-transparent">Полная информация</a>
                  </td>
                </tr>
                </tbody>
                <tbody>
                <tr class="table-divider">
                  <td colspan="10"></td>
                </tr>
                </tbody>
                <tbody class="table-group">
                <tr>
                  <td>
                    <div class="orders-table-number orders-table-sell">26924780<span>Продажа</span></div>
                  </td>
                  <td align="center">05.09.2020<br>в 09:52</td>
                  <td align="center">
                    <div class="table-group-open">
                      <button type="button" class="table-group-open-button"></button>
                      <span>92 934 UAH</span>
                      <div class="orders-table-status-mobile orders-table-completed">завершено</div>
                    </div>
                  </td>
                </tr>
                <tr class="table-hidden-row">
                  <td colspan="3">
                    <div class="order-info-title">Товары</div>
                    <div class="order-info-mobile">
                      <div class="order-info-mobile-main">
                        <div class="order-info-mobile-image">
                          <img src="pic/order-info-table-1.png" srcset="pic/order-info-table-1.png 1x, pic/order-info-table-1@2x.png 2x" alt="">
                        </div>
                        <div class="order-info-mobile-name">SL5570 ABS задний тормозной шланг</div>
                      </div>
                      <ul class="order-info-mobile-about">
                        <li><span>Кол-во</span>2</li>
                        <li><span>Цена</span>22096 UAH</li>
                        <li><span>Сумма</span>44186 UAH</li>
                      </ul>
                    </div>
                    <div class="order-info-mobile">
                      <div class="order-info-mobile-main">
                        <div class="order-info-mobile-image">
                          <img src="pic/order-info-table-2.png" srcset="pic/order-info-table-2.png 1x, pic/order-info-table-2@2x.png 2x" alt="">
                        </div>
                        <div class="order-info-mobile-name">SL5570 ABS задний тормозной шланг</div>
                      </div>
                      <ul class="order-info-mobile-about">
                        <li><span>Кол-во</span>2</li>
                        <li><span>Цена</span>22096 UAH</li>
                        <li><span>Сумма</span>44186 UAH</li>
                      </ul>
                    </div>
                    <a href="#" class="button button-block button-middle button-transparent">Полная информация</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="page-nav">
        <div class="page-nav-box">
<!--          <a href="#" class="button button-middle">Загрузить еще 20</a>-->
        </div>
      </div>
    </section>
    <section class="block">
      <div class="insurance">
        <div class="insurance-text">
          <h2>Страховой полис</h2>
          <p>Avtoprice, как официальный партнер, предлагает лучший выбор среди ведущих страховых компаний Украины.</p>
        </div>
        <a href="#" class="button button-middle button-transparent">Узнать больше</a>
      </div>
    </section>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import OrderBuyLine from "@/components/common/Orders/OrderBuyLine";
import OrderSellLine from "@/components/common/Orders/OrderSellLine";

export default {
  name: "Orders",
  components:{OrderBuyLine,OrderSellLine},
  data: function () {
    return {
      show: false,
      type: 'buy',
      data:{
        orders_success: 100500,
        orders_process: 100500,
        orders_fail: 100500,
        orders: []
      }
    }
  },
  computed: {
    ...mapState({
      order_data: state => state.Order.order_data,
      reload_flag: state => state.Main.reload_flag,
    }),
  },
  mounted() {
    this.type = this.$route.query.type;
    if(this.type==='buy') {
      this.GetOrdersData();
    }else{
      this.GetSalesData();
    }
  },
  methods: {
    ...mapMutations(['SetStage','SetReloadFlag']),
    ...mapActions(['GetCall',]),
    hide() {
      console.log('hide');
      this.SetStage(0);
    },
    GetOrdersData() {
      this.GetCall({
        endpoint: '/api/buyes',
        callback: (data) => {
          this.data = data.data[0];
        },
      })
    },
    GetSalesData() {
      this.GetCall({
        endpoint: '/api/sales',
        callback: (data) => {
          this.data = data.data[0];
        },
      })
    },

  },
  watch:{
    reload_flag(){
      this.SetReloadFlag(false);
      window.location.reload(true);
    },
  },

}
</script>

<style scoped>

</style>