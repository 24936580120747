<template>
  <h3>{{ name }}</h3>
  <ul class="i-list">
    <template v-for="(value, index) in list" v-bind:key="index">
      <li>
        <a :href="value.url" class="i-list-box">
          <div class="i-list-icon">
            <img :src="value.img">
          </div>
          <div class="i-list-text">{{ value.text }}</div>
        </a>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  name: "MainList",
  props:{
    name: String,
    list: Array,
  }
}
</script>

<style scoped>

</style>