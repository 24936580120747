export default {
  state: {
    wh_uuid: '',
  },
  mutations: {
    SetWHUuid(state,uuid){
      state.wh_uuid = uuid;
    },
  },
  actions: {},
  getters: {}
}
