<template>
  <div
      class="sliding-door"
      :class="is_open ? 'is-opened' : ''">
    <div class="sliding-door-header">
      <span>Редактирование склада</span>
      <button
          type="button"
          class="sliding-door-close"
          data-popup-close
          @click="hide"
      ></button>
    </div>
    <div class="sliding-door-main">
      <div class="sliding-door-content">
        <p class="center">{{ uuid }}</p>
        <form class="form">
          <div class="section">
            <div class="block-small">
              <fieldset>
                <div class="form-field"
                     :class="[form.title.length > 0 ? 'is-filled':'', errored.title ? 'errored':'']">
                  <input type="text" class="textfield" v-model="form.title">
                  <label>Название склада</label>
                </div>
              </fieldset>
              <div class="form-row"
                   :class="[form.currency.length > 0 ? 'is-filled':'', errored.currency ? 'errored':'']">
                <fieldset>
                  <select class="select" id="edit-warehouse-currency" v-model="form.currency">
                    <option>USD</option>
                    <option>EUR</option>
                    <option>UAH</option>
                  </select>
                </fieldset>
                <fieldset>
                  <div class="form-field"
                       :class="[form.days_delivery > 0 ? 'is-filled':'', errored.days_delivery ? 'errored':'']">
                    <input type="number" class="textfield" v-model="form.days_delivery">
                    <label>Дней</label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="block-small">
              <p>Укажите условия доставки, оплаты, возврата и время отправки, возможность самовывоза и другие условия склада, с которыми должны ознакомиться покупатели</p>
              <fieldset class="form-field"
                  :class="[form.text.length > 0 ? 'is-filled':'', errored.text ? 'errored':'']">
              <textarea class="textfield textarea-small autosize" rows="1" v-model="form.text"></textarea>
              </fieldset>
            </div>
          </div>
          <div class="section">
            <h3 class="center">Связанные магазины</h3>
            <ul class="list">
              <li>
                <input type="checkbox" class="checkbox" id="edit-warehouse-ch-1" checked>
                <label for="edit-warehouse-ch-1">Название магазина 1</label>
              </li>
              <li>
                <input type="checkbox" class="checkbox" id="edit-warehouse-ch-2">
                <label for="edit-warehouse-ch-2">Название магазина 2</label>
              </li>
            </ul>
            <div class="button-center">
              <button type="submit" class="button button-middle" @click="Send">Сохранить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="sliding-door-bottom">
      <div class="sliding-door-faq">
        <span>Есть вопросы?</span>
        <a href="#">FAQ по этой теме</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SPEditWarehouse",
  data: function () {
    return {
      is_open: false,
      uuid:'',
      form: {
        type: 'retail',
        title: '',
        currency: 'USD',
        days_delivery: '',
        text: '',
        active: true,
      },
      errored: {
        type: false,
        title: false,
        currency: false,
        days_delivery: false,
        text: false,
        active: false,
      },
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth,
      warehouse_uuid: state => state.Popups.warehouse_uuid,
    }),
  },
  mounted() {
    this.GetStorehouseData();
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetSlidePopupStatus', 'SetSlidePopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd','SetReloadFlag']),
    ...mapActions(['PutCall','GetCall']),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetSlidePopupStatus(false);
      }, 300);
    },
    GetStorehouseData() {
      this.GetCall({
        endpoint: '/api/storehouse/' + this.warehouse_uuid,
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          console.log(data);
          this.uuid = data.data.uuid;

          this.form.type = data.data.type ? data.data.type : '';
          this.form.title = data.data.title ? data.data.title : '';
          this.form.currency = data.data.currency ? data.data.currency : '';
          this.form.days_delivery = data.data.days_delivery ? data.data.days_delivery : '';
          this.form.text = data.data.text ? data.data.text : '';
          this.form.active = data.data.active ? data.data.active : false;
        },
      })
    },
    Send(e) {
      e.preventDefault();

      this.PutCall({
        endpoint: '/api/storehouse/' + this.warehouse_uuid,
        param: this.form,
        callback: (data) => {
          this.SetReloadFlag(true);
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;

          if(error_status === 422){
            let errors = Object.keys(error.response.data.errors);
            console.log('errors',errors);
            for (let i = 0; i < errors.length; i++) {
              if(this.errored[errors[i]] !== undefined){
                this.errored[errors[i]] = true;
              }
            }
          }
        },
      })
    }
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },
}
</script>

<style scoped>

</style>