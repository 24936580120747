<template>
  <Header></Header>
  <HeaderCatalog></HeaderCatalog>
  <div class="wrapper">
    <router-view/>
    <SideBar></SideBar>
    <Extra></Extra>
  </div>
  <Footer></Footer>
  <Search></Search>
  <Popups v-if="popup_opened"></Popups>
  <SlidePopups v-if="slide_popup_opened"></SlidePopups>
  <Overlay></Overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import Header from '@/components/common/Header.vue'
import HeaderCatalog from "@/components/common/HeaderCatalog.vue"
import SideBar from '@/components/common/SideBar.vue'
import Extra from "@/components/common/Extra"
import Footer from '@/components/common/Footer.vue'
import Popups from '@/components/common/Popups.vue'
import SlidePopups from '@/components/common/SlidePopups.vue'
import Overlay from '@/components/common/Overlay.vue'
import Search from "@/components/common/Search"
import Main from '@/components/pages/Main.vue'

export default {
  name: 'App',
  components: {
    Search,
    Header,
    HeaderCatalog,
    SideBar,
    Extra,
    Footer,
    Popups,
    SlidePopups,
    Main,
    Overlay,
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      popup_opened: state => state.Popups.popup_opened,
      slide_popup_opened: state => state.Popups.slide_popup_opened,
    }),
  },
  mounted() {
    /*let l_storage = window.localStorage;
    let user_type = l_storage.getItem('user_type');
    let token = l_storage.getItem('token');
    // l_storage.removeItem('token');
    let tokendate = l_storage.getItem('tokendate');
    let tokenexp = l_storage.getItem('tokenexp');

    let tokendate_int;
    let tokenexp_int;

    if (tokendate !== undefined) {
      tokendate_int = parseInt(tokendate);
    } else {
      tokendate_int = 0;
    }
    if (tokenexp !== undefined) {
      tokenexp_int = parseInt(tokenexp);
    } else {
      tokenexp_int = 0;
    }

    if (token !== null && token !== undefined) {
      if (Date.now() < tokendate_int + tokenexp_int * 1000) {
        this.SetLoginStatus(true);
        this.SetUserType(user_type);
        this.GetUserData();

        // console.log("now", Date.now());
        // console.log("remain", (tokendate_int + tokenexp_int * 1000));
        // console.log("timeout", (tokendate_int + tokenexp_int * 1000) - Date.now());

        setTimeout(() => {
          //console.log('RefreshAuth');
          this.RefreshAuth();
        }, (tokendate_int + tokenexp_int * 1000) - Date.now())
      } else {
        this.DeleteCredentials();
      }
    }*/
    // this.GetUserData();
  },
  methods: {
    ...mapMutations(['SetLoginStatus','SetUserType',]),
    ...mapActions(['GetCall','RefreshAuth','GetUserData','DeleteCredentials',]),
  },
  watch: {
    is_auth(newVal) {
      if (!newVal) {
        this.$router.push({ path: '/' })
      }
    },
    '$route' (to, from) {
      console.log('Route changed from ' + from.path + ' to ' + to.path);
    }
  }
}
</script>

<style lang="less">

</style>
