<template>
  <tbody :class="value.open ? 'is-opened':''">
    <template v-for="(value2, index2) in value.parts" v-bind:key="index2">
      <tr class="table-row-popup "
          :class="[
              index2!==0 ? 'table-hidden-row':'',
              value.seller_uuid === user_uuid ? 'listing-table-row-color-1':''
              ]">
        <td align="center">
          <div class="table-group-open">
            <button
                v-if="index2===0 && value.parts.length > 1"
                @click="value.open = !value.open"
                type="button"
                class="table-group-open-button"></button>
            <span @click="GetProductData(value2.uuid)">{{ value.manufacturer }}</span>
            <br>
            <small>{{ value2.description }}</small>
          </div>
        </td>
        <td align="center">{{ value2.time }}<br>{{ value2.city }}</td>
        <td align="center">{{ value2.price }}<small>{{ value.seller }}</small></td>
      </tr>
    </template>
  </tbody>

</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "MobileRow",
  data: function () {
    return {
      selected: [],
      open: false,
    }
  },
  props: {
    value: Object,
    index: Number,
  },
  mounted() {
    for(let i=0;i<this.value.parts.length;i++){
      this.selected.push(this.value.parts[i]);
    }
  },
  methods: {
    ...mapMutations(['SetPopupName', 'SetPopupStatus','SetProductUUID',]),
    ...mapActions(['PostCall',]),

    GetProductData(uuid) {
      this.SetProductUUID(uuid);
      this.SetPopupName('listing');
      this.SetPopupStatus(true);
    },
    AddToCart(uuid) {
      this.PostCall({
        endpoint: '/api/cart',
        param: { uuid: uuid },
        callback: (data) => {
          this.selected[obj.index] = !this.selected;
        },
      })
    },
  },
}
</script>

<style scoped>

</style>