<template>
  <tbody :class="value.open ? 'is-opened':''">
    <template v-for="(value2, index2) in value.parts" v-bind:key="index2">
      <tr class="table-row-popup "
          :class="[
              index2!==0 ? 'table-hidden-row':'',
              value.seller_uuid === user_uuid ? 'listing-table-row-color-1':''
              ]">
        <td align="center">
          <div class="table-group-open">
            <button
                v-if="index2===0 && value.parts.length > 1"
                @click="value.open = !value.open"
                type="button"
                class="table-group-open-button"
            ></button>
            <span @click="GetProductData(value2.uuid)">{{ value.manufacturer }}</span>
          </div>
        </td>
        <td align="center">{{ value.code }}</td>
        <td>{{ value2.description }}</td>
        <td align="center">{{ value2.num }}</td>
        <td align="center">{{ value2.city }}</td>
        <td align="center">{{ value2.time }}</td>
        <td align="center">{{ value2.price }}</td>
        <td align="center">
          <div>{{ value.seller }}</div>
          <button
              type="button"
              class="table-button table-button-fav go-down"
          ></button>
        </td>
        <td align="center">{{ value.rate }}</td>
        <td align="center">
          <button
              type="button"
              class="table-button table-button-cart"
              v-if="value.seller_uuid !== user_uuid"
              :class="selected[index2] ? 'is-selected':''"
              @click="AddToCart({uuid:value2.uuid,index:index2})"
          ></button>
        </td>
      </tr>
    </template>
  </tbody>

</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "DesktopRow",
  data: function () {
    return {
      selected: [],
      open: false,
    }
  },
  props: {
    value: Object,
    index: Number,
  },
  computed: {
    ...mapState({
      user_uuid: state => state.User.uuid,
    }),
  },
  mounted() {
    for(let i=0;i<this.value.parts.length;i++){
      this.selected.push(this.value.parts[i].in_cart);
    }
  },
  methods: {
    ...mapMutations(['SetPopupName', 'SetPopupStatus','SetProductUUID',]),
    ...mapActions(['PostCall','DeleteCall']),

    GetProductData(uuid) {
      this.SetProductUUID(uuid);
      this.SetPopupName('listing');
      this.SetPopupStatus(true);
    },
    AddToCart(obj) {
      if(!this.selected[obj.index]) {
        this.PostCall({
          endpoint: '/api/cart',
          param: { uuid: obj.uuid },
          callback: (data) => {
            this.selected[obj.index] = !this.selected[obj.index];
          },
        })
      }else{
        /*this.DeleteCall({
          endpoint: '/api/cart/'+obj.uuid,
          param: {},
          callback: (data) => {
            this.selected[obj.index] = !this.selected;
          },
        })*/
      }
    },
  },
}
</script>

<style scoped>

</style>