<template>
  <div class="popup" data-popup="change-mail">
    <div class="popup-box" style="max-width: 514px;">
      <button type="button" class="popup-close" data-popup-close></button>
      <div class="popup-header">Изменить E-mail</div>
      <form class="form">
        <fieldset>
          <input type="text" class="textfield">
        </fieldset>
        <fieldset>
          <a href="javascript:void(0);" data-popup-open="confirm-mail">Подтвердить E-mail</a>
        </fieldset>
        <fieldset>
          <button type="submit" class="button button-middle">Сохранить</button>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PopupChangeMail",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      trigger_is_auth: state => state.User.trigger_is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupStatus', 'SetPopupName', 'SetLoginStatus', 'SetLoginStatusTriggerEnd',]),
    ...mapActions(['PostCall',]),
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupStatus(false);
      }, 300);
    },
  },
  watch: {
    trigger_is_auth(newVal) {
      if (newVal) {
        this.hide();
        this.SetLoginStatusTriggerEnd();
      }
    },
  },

}
</script>

<style scoped>

</style>