<template>
  <footer>
    <div class="footer-info">
      <a href="#" class="footer-logo">
        <span>Avtoprice</span>
        <p>поиск автозапчастей</p>
      </a>
      <p>&copy; 2020 Все права защищены</p>
      <p>
        <a href="#">Условия использования сайта</a>
      </p>
    </div>
    <nav class="footer-nav">
      <ul>
        <li>
          <a href="#">Авто и мото запчасти</a>
        </li>
        <li>
          <a href="#">Водный транспорт</a>
        </li>
        <li>
          <a href="#">С/х техника</a>
        </li>
        <li>
          <a href="#">Производители</a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="#">О компании</a>
        </li>
        <li>
          <a href="#">Покупателю</a>
        </li>
        <li>
          <a href="#">Продавцу</a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="#">Тарифы</a>
        </li>
        <li>
          <a href="#">Статистика</a>
        </li>
        <li>
          <a href="#">Новости</a>
        </li>
        <li>
          <a href="#">Полис страхования</a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="#">FAQ</a>
        </li>
        <li>
          <a href="#">Форум</a>
        </li>
        <li>
          <a href="#">Полезные советы</a>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>