<template>
  <div v-if="show" class="search-line">
    <div class="search-line-main">
      <ul class="search-line-list">
        <li class="search-line-icon">
          <a href="#">
            <img src="img/car-2.svg" alt="">
          </a>
        </li>
        <li class="search-line-icon">
          <a href="#">
            <img src="pic/vw2.svg" alt="">
            <span>Volkswagen</span>
          </a>
        </li>
        <li>
          <a href="#">Bora/Variant/4Motion (1999-2005)</a>
        </li>
        <li>Модификация двигателя</li>
      </ul>
      <button type="button" class="search-line-reset">
        <span>Очистить</span>
      </button>
    </div>
  </div>
  <div v-if="stage===6" class="search-box">
    <div class="search-box-bg" @click="hide"></div>
    <div class="search-box-top">
      <div class="search-box-top-row-result">
        <span>Найдено:</span>
        <span>{{ search_data.length }} проверенных запчастей</span>
        <span v-if="search_data.untested !== undefined">3 непроверенных</span>
      </div>
    </div>
    <div class="search-box-main">
      <div class="search-box-content">
        <ul class="item-catalog">
          <template v-for="(value, index) in search_data" v-bind:key="index">
            <li>
              <a @click="ForceReload(value.uuid)" class="item-catalog-box">
                <div class="item-catalog-image">
                  <img src="pic/news-ad.jpg" srcset="pic/news-ad.jpg 1x, pic/news-ad@2x.jpg 2x" alt="">
                </div>
                <div class="item-catalog-main item-catalog-result">
                  <span>{{ value.name }} <i>{{ search_line }}</i></span>
                  <p>Диск тормозной передний</p>
                </div>
              </a>
            </li>
          </template>
        </ul>
        <div v-if="search_data.untested !== undefined" class="search-box-info-title">Представленные ниже результаты не проверены экспертами
          Avtoshops
        </div>
        <ul v-if="search_data.untested !== undefined" class="item-catalog">
          <li>
            <a href="#" class="item-catalog-box">
              <div class="item-catalog-main item-catalog-result">
                <span>TTT-AUTO <i>15960</i></span>
                <p>Р/к диcк.тормоза</p>
              </div>
            </a>
          </li>
          <li>
            <a href="#" class="item-catalog-box">
              <div class="item-catalog-main item-catalog-result">
                <span>TTT-AUTO <i>15960</i></span>
                <p>Р/к диcк.тормоза</p>
              </div>
            </a>
          </li>
          <li>
            <a href="#" class="item-catalog-box">
              <div class="item-catalog-main item-catalog-result">
                <span>TTT-AUTO <i>15960</i></span>
                <p>Р/к диcк.тормоза</p>
              </div>
            </a>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import router from "@/router/router";

export default {
  name: "Search",
  data: function () {
    return {
      show: false
    }
  },
  computed: {
    ...mapState({
      search_line: state => state.Search.search_line,
      stage: state => state.Search.stage,
      search_data: state => state.Search.search_data,
    }),
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['SetStage',]),
    hide() {
      console.log('hide');
      this.SetStage(0);
    },
    ForceReload(uuid){
      router.push({ path: '/search-result', query: { code:this.search_line, uuid: uuid } })
      //'/search-result?code='+search_line+'&uuid=' + value.uuid
      // console.log(router.currentRoute.value.name);
      if(router.currentRoute.value.name === 'SearchResult') {
        setTimeout(()=>{
          window.location.reload(true);
        },100);
      }
    }
  },

}
</script>

<style scoped>

</style>