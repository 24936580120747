<template>
  <section class="content">
    <div class="listing-top-info">
      <div class="listing-top-info-row">
        <div class="listing-top-info-tabs">
          <ul>
            <li>
              <span>16”</span>
            </li>
            <li>
              <a href="#">Год выпуска с: 06.2016</a>
            </li>
          </ul>
        </div>
        <div class="listing-top-info-model">
          <a href="#" class="cover" style="background-image: url('pic/listing-top-info-model.jpg');"></a>
        </div>
        <div class="listing-top-info-item">
          <a href="#" class="cover" style="background-image: url('pic/listing-top-info-item.jpg');"></a>
        </div>
        <div class="listing-top-info-features">
          <ul>
            <li>
              <div class="listing-top-info-features-title">Производитель</div>
              <div class="listing-top-info-features-text">VAG</div>
            </li>
            <li>
              <div class="listing-top-info-features-title">Описание</div>
              <div class="listing-top-info-features-text">16"</div>
            </li>
            <li>
              <div class="listing-top-info-features-title">Вес, г</div>
              <div class="listing-top-info-features-text">1922</div>
            </li>
            <li>
              <div class="listing-top-info-features-title">Страна производства</div>
              <div class="listing-top-info-features-text">Германия</div>
            </li>
            <li>
              <div class="listing-top-info-features-title">Ось установки</div>
              <div class="listing-top-info-features-text">Передняя</div>
            </li>
          </ul>
          <a href="#">показать все</a>
        </div>
      </div>
    </div>
    <div class="breadcrumbs"><a href="/" class="breadcrumbs-home"></a><i></i><a href="#">Колодки тормозные передние дисковые на Volkswagen Amarok 2H</a></div>
    <section class="block">
      <h1>Колодки тормозные передние дисковые на Volkswagen Amarok 2H</h1>
      <div class="block-small">
        <button type="button" class="button button-block button-middle button-open mobile-toggle-button">Фильтр</button>
        <div class="mobile-toggle">
          <div class="listing-filter form">
            <ul class="listing-filter-row listing-filter-row-special">
              <li>
                <div class="form-field"
                     :class="[user_form.area.length > 0 ? 'is-filled':'', errored.area ? 'errored':'']">
                  <select class="select" id="listing-region" v-model="user_form.area">
                    <option></option>
                    <option>Киевская область</option>
                    <option>Одесская область</option>
                  </select>
                  <label>Область покупателя</label>
                </div>
              </li>
              <li>
                <div class="form-field"
                     :class="[user_form.city.length > 0 ? 'is-filled':'', errored.city ? 'errored':'']">
                  <select class="select" id="listing-city" v-model="user_form.city">
                    <option></option>
                    <option>Киев</option>
                    <option>Одесса</option>
                  </select>
                  <label>Город покупателя</label>
                </div>
              </li>
              <li>
                <div class="form-field"
                     :class="[user_form.currency.length > 0 ? 'is-filled':'', errored.currency ? 'errored':'']">
                  <select class="select" id="listing-currency" v-model="user_form.currency">
                    <option></option>
                    <option>UAH</option>
                    <option>USD</option>
                  </select>
                  <label>Валюта</label>
                </div>
              </li>
            </ul>
            <ul class="listing-filter-checkboxes">
              <li>
                <input type="checkbox" class="checkbox" id="listing-checkbox-1">
                <label for="listing-checkbox-1">Новое</label>
              </li>
              <li>
                <input type="checkbox" class="checkbox" id="listing-checkbox-2">
                <label for="listing-checkbox-2">Б/у</label>
              </li>
              <li>
                <input type="checkbox" class="checkbox" id="listing-checkbox-3">
                <label for="listing-checkbox-3">Оргиналы и аналоги раздельно</label>
              </li>
              <li>
                <input type="checkbox" class="checkbox" id="listing-checkbox-4">
                <label for="listing-checkbox-4">Сначала мои предложения</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="block-small">
        <ul class="table-legend">
          <li><i style="background-color: #FFF"></i>— Оригинальная запчасть</li>
          <li><i style="background-color: #FFF"></i>— Аналог товара</li>
          <li><i style="background-color: #DFE7EB"></i>— Товар в корзине</li>
          <li><img src="img/table-open-button.svg" alt="">— Ещё предложения</li>
          <li><img src="img/star-active.svg" alt="">— Рейтинг продавца</li>
        </ul>
      </div>
      <div class="block-small">
        <div class="fake-desktop">
          <table class="table listing-table">
            <thead>
              <tr>
                <th class="table-sortable">Производитель</th>
                <th class="table-sortable">Код</th>
                <th class="table-sortable">Описание</th>
                <th class="table-sortable">кол-во</th>
                <th class="table-sortable">Город</th>
                <th class="table-sortable">Отправка</th>
                <th class="table-sortable">Цена, UAH</th>
                <th class="table-sortable">Продавец</th>
                <th class="table-sortable">
                  <img src="img/star-active.svg" alt="Рейтинг продавца">
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            <tr class="table-divider">
              <td colspan="10"></td>
            </tr>
            </tbody>

            <template v-for="(value, index) in list" v-bind:key="index">
              <DesktopRow :value="value" :index="index"></DesktopRow>
              <tbody>
                <tr class="table-divider">
                  <td colspan="10"></td>
                </tr>
              </tbody>
            </template>
<!--
            <tbody>
            <tr class="listing-table-row-color-1 table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart is-selected"></button>
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="10"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="listing-table-row-color-1 table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">
                <div class="listing-table-badge listing-table-available">в наличии</div>
              </td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart is-selected"></button>
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="10"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">
                <div class="listing-table-badge listing-table-today">сегодня</div>
              </td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav is-active go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="10"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="listing-table-row-color-2 table-row-popup" data-popup-open="listing">
              <td align="center">
                <div class="table-group-open">
                  <button type="button" class="table-group-open-button"></button>
                  <span>Zimmermann</span>
                </div>
              </td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            <tr class="listing-table-row-color-2 table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            <tr class="listing-table-row-color-2 table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="10"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="table-row-popup" data-popup-open="listing">
              <td align="center">
                <div class="table-group-open">
                  <button type="button" class="table-group-open-button"></button>
                  <span>Zimmermann</span>
                </div>
              </td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav is-active go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="10"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="listing-table-row-color-1 table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart is-selected"></button>
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="10"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="listing-table-row-color-1 table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">
                <div class="listing-table-badge listing-table-available">в наличии</div>
              </td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart is-selected"></button>
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="10"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">
                <div class="listing-table-badge listing-table-today">сегодня</div>
              </td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav is-active go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="10"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="listing-table-row-color-2 table-row-popup" data-popup-open="listing">
              <td align="center">
                <div class="table-group-open">
                  <button type="button" class="table-group-open-button"></button>
                  <span>Zimmermann</span>
                </div>
              </td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            <tr class="listing-table-row-color-2 table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            <tr class="listing-table-row-color-2 table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">Zimmermann</td>
              <td align="center">2H6698151A</td>
              <td>Заказная деталь 100% оригинальный производитель тол..</td>
              <td align="center">100</td>
              <td align="center">Харьков</td>
              <td align="center">14 дней</td>
              <td align="center">29039,44</td>
              <td align="center">
                <div>stolkacha.com.ua</div>
                <button type="button" class="table-button table-button-fav go-down"></button>
              </td>
              <td align="center">4,8</td>
              <td align="center">
                <button type="button" class="table-button table-button-cart"></button>
              </td>
            </tr>
            </tbody>
            -->
          </table>
        </div>
        <div class="fake-mobile">
          <table class="table listing-table">
            <thead>
            <tr>
              <th class="table-sortable">Бренд/код</th>
              <th class="table-sortable">отправка</th>
              <th class="table-sortable">Цена, UAH</th>
            </tr>
            </thead>
            <tbody>
            <tr class="table-divider">
              <td colspan="3"></td>
            </tr>
            </tbody>

            <template v-for="(value, index) in list" v-bind:key="index">
              <MobileRow :value="value" :index="index"></MobileRow>
              <tbody>
              <tr class="table-divider">
                <td colspan="3"></td>
              </tr>
              </tbody>
            </template>

<!--
            <tbody>
            <tr class="table-row-popup" data-popup-open="listing">
              <td align="center">
                <div class="table-group-open">
                  <button type="button" class="table-group-open-button"></button>
                  <span>Zimmermann</span>
                  <br>
                  <small>Бампер передний</small>
                </div>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">
                <span>Zimmermann</span>
                <br>
                <small>Бампер передний</small>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">
                <span>Zimmermann</span>
                <br>
                <small>Бампер передний</small>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="3"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="table-row-popup" data-popup-open="listing">
              <td align="center">
                <div class="table-group-open">
                  <button type="button" class="table-group-open-button"></button>
                  <span>Zimmermann</span>
                  <br>
                  <small>Бампер передний</small>
                </div>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">
                <span>Zimmermann</span>
                <br>
                <small>Бампер передний</small>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">
                <span>Zimmermann</span>
                <br>
                <small>Бампер передний</small>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="3"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="table-row-popup" data-popup-open="listing">
              <td align="center">
                <div class="table-group-open">
                  <button type="button" class="table-group-open-button"></button>
                  <span>Zimmermann</span>
                  <br>
                  <small>Бампер передний</small>
                </div>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">
                <span>Zimmermann</span>
                <br>
                <small>Бампер передний</small>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">
                <span>Zimmermann</span>
                <br>
                <small>Бампер передний</small>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            </tbody>
            <tbody>
            <tr class="table-divider">
              <td colspan="3"></td>
            </tr>
            </tbody>

            <tbody>
            <tr class="table-row-popup" data-popup-open="listing">
              <td align="center">
                <div class="table-group-open">
                  <button type="button" class="table-group-open-button"></button>
                  <span>Zimmermann</span>
                  <br>
                  <small>Бампер передний</small>
                </div>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">
                <span>Zimmermann</span>
                <br>
                <small>Бампер передний</small>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            <tr class="table-hidden-row table-row-popup" data-popup-open="listing">
              <td align="center">
                <span>Zimmermann</span>
                <br>
                <small>Бампер передний</small>
              </td>
              <td align="center">14 дней<br>Харьков</td>
              <td align="center">29039,44<small>Технопартс</small></td>
            </tr>
            </tbody>
            -->
          </table>
        </div>

<!--        <div class="page-nav">
          <div class="page-nav-box">
            <ul class="pager">
              <li>
                <a href="#" class="pager-prev"></a>
              </li>
              <li>
                <span>1</span>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>...</li>
              <li>
                <a href="#">89</a>
              </li>
              <li>
                <a href="#" class="pager-next"></a>
              </li>
            </ul>
          </div>
          <div class="page-nav-box">
            <a href="#" class="button button-middle">Загрузить еще 20</a>
          </div>
        </div>
        -->
      </div>
    </section>
    <section class="block">
      <div class="insurance">
        <div class="insurance-text">
          <h2>Страховой полис</h2>
          <p>Avtoprice, как официальный партнер, предлагает лучший выбор среди ведущих страховых компаний Украины.</p>
        </div>
        <a href="#" class="button button-middle button-transparent">Узнать больше</a>
      </div>
    </section>
  </section>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import DesktopRow from "@/components/common/SearchResult/DesktopRow";
import MobileRow from "@/components/common/SearchResult/MobileRow";

export default {
  name: "SearchResult",
  components: {
    DesktopRow,
    MobileRow,
  },
  data: function () {
    return {
      edit_rules: false,
      list: [],
      user_form:{
        area:'',
        city:'',
        currency:''
      },
      errored:{
        area:'',
        city:'',
        currency:''
      },
    }
  },
  mounted() {
    this.GetSearchData();
    this.SetStage(0);
  },
  computed: {
    ...mapState({
      search_line: state => state.Search.search_line,
      stage: state => state.Search.stage,
      search_data: state => state.Search.search_data,
    }),
  },
  methods: {
    ...mapMutations(['SetSlidePopupName', 'SetSlidePopupStatus', 'SetWHUuid','SetWarehouseUUID', 'SetProductUUID', 'SetStage']),
    ...mapActions(['GetCall',]),
    GetSearchData() {
      this.GetCall({
        endpoint: '/api/search/' + this.$route.query.code + '/' + this.$route.query.uuid,
        // param: '?uuid='+this.$route.query.uuid,
        param: '',
        callback: (data) => {
          this.list = Array.from(data.data[0]);
        },
      })
    },
  },
}
</script>

<style scoped>

</style>