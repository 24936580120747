export default {
  state: {
    slide_popup_name: '',
    slide_popup_opened: false,
    popup_name: '',
    popup_opened: false,

    warehouse_uuid: '',
    warehouse_name: '',

    product_uuid: '',
    product_name: '',

    overlay: false,
    overlay_target: '',
  },
  mutations: {
    SetPopupName(state,name){
      state.popup_name = name;
    },
    SetPopupStatus(state,status){
      state.popup_opened = status;
      state.overlay = status;
      state.overlay_target = 'popup';
    },
    SetSlidePopupName(state,name){
      state.slide_popup_name = name;
    },
    SetSlidePopupStatus(state,status){
      state.slide_popup_opened = status;
      state.overlay = status;
      state.overlay_target = 'slide';
    },
    SetWarehouseUUID(state,uuid){
      state.warehouse_uuid = uuid;
    },
    SetWarehouseName(state,name){
      state.warehouse_name = name;
    },
    SetProductUUID(state,uuid){
      state.product_uuid = uuid;
    },
    SetProductName(state,name){
      state.product_name = name;
    },
    OverlayClick(state){

      state.overlay = false;
      if(state.overlay_target === 'popup'){
        state.popup_opened = false;
      }
      if(state.overlay_target === 'slide'){
        state.slide_popup_opened = false;
      }
      if(state.overlay_target === 'search'){

      }

    }
  },
  actions: {},
  getters: {}
}
